/* You can add global styles to this file, and also import other style files */

@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/@ctrl/ngx-emoji-mart/picker.css";
 @import "../node_modules/bulma-tooltip/dist/css/bulma-tooltip.min.css";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/bulma-calendar/src/sass/timepicker.sass";
 @import "../node_modules/bulma-extensions/bulma-accordion/src/sass/index.sass";
@import "../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss";

// @import '../node_modules/bulma-extensions/extensions.sass';
/* ==========================================================================
App custom styles
========================================================================== */

@font-face {
  font-family: "sans-serif";
  src: url("assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("assets/fonts/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

$font-family-open-sans: "Open Sans", sans-serif;
$font-size-base-14: 14px;
$font-size-xlarge-24: 24px;
$font-size-xxlarge-36: 36px;
$font-size-large-18: 18px;
$font-size-medium-16: 16px;
$font-size-small-12: 12px;
$font-size-xsmall-10: 10px;
$font-size-h1-36: 36px;
$font-size-h2-30: 30px;
$font-size-h3-24: 24px;
$font-size-h4-18: 18px;
$font-size-h5-14: $font-size-base-14;
$font-size-h6-12: 12px;
/*  Site Variables Defined   ================================================ */



/* Generic Color Vaiables  */

$primary-color: #2071B9;
$header-bg-color-primary: #2071B9;

// $primary-color: #042f14 133aa5;
$secondary-color: #a7add4; //#98DFAF, #5FB49C
$typography-dcolor: #010101; // d stands for Dark shade
$typography-mcolor: #050b23; // m stands for Medium shade
$typography-lcolor: #bbc2ce; // l stands for light shade
//Follow up colors
$tertiary-color: #565c78; // Can be used for links, any other active state highlights (e.g: sort, filter) where secondary color can't be used. Alternates - #0072bc, #3386bc, #30bced
$subtle-dcolor: #9fa2aa; // If required can be used for border and subheading text depends on the context, d stands for Dark shade
$subtle-lcolor: #edeef2;
$generic-bgcolor: #f1f2f5; // If required can be used for border and subheading text depends on the context, l stands for light shade #DCDCDD, #C5C3C6
// $generic-bgcolor: #eceffe; // Used as a generic background for any sections, card, body, etc.
//Netural colors
$complete-white-color: #ffffff;
$black-color: #010101;
$grey-color: #909090;

/* Layout or Component Specific Color Vaiables  */
$body-bg-color: #f0f0f094;
$link-color: $tertiary-color;
$font-size-default: 100%;
$font-size-inc: 125%;
$font-size-dec: 75%;
//Primary Header - Where ever second level of header is required we can use this
$header-bg-color-primary: var(--primaryColor);
$header-border-color-primary: var(--primaryColor);
$header-text-color-primary: $complete-white-color;
//First column - Menu Panel
$menu-panel-bg: $complete-white-color;
$menu-border-color-primary: #607d8b;
$menu-panel-text-color: #607d8b;
$menu-panel-text-color-selected: var(--primaryColor);
//Second column - Listing Panel
$listing-panel-bg: $complete-white-color;
$listing-panel-pri-text-color: $typography-mcolor;
$listing-panel-sec-text-color: $typography-lcolor;
$listing-panel-link-highlight-text-color: var(--primaryColor);
$listing-panel-selected-bg: $generic-bgcolor;
$listing-panel-list-border: $generic-bgcolor;
$listing-panel-selected-highlight: var(--primaryColor);
$listing-panel-badge-bg: var(--primaryColor);
$listing-panel-badge-text-color: $complete-white-color;
//Third column - Content Panel
$content-panel-bg: $generic-bgcolor;
$content-panel-header-bg: $complete-white-color;
$content-panel-pri-text-color: $typography-mcolor;
$content-panel-sec-text-color: $typography-lcolor;
$content-panel-link-highlight-text-color: var(--primaryColor);
$content-panel-inline-comments-bg: $generic-bgcolor;
$content-panel-chat-comments-bg: $complete-white-color;
$content-panel-chat-highlight-bg: $complete-white-color;
$content-panel-chat-button-bg: var(--primaryColor);
$content-panel-chat-button-text-color: $complete-white-color;
//Fourth column - Info Panel
$info-panel-bg: $complete-white-color;
//== Typography
$input-field-border-active: #00d1b2;
$input-field-color:#6c7a87;
//
//## Font, line-height, and color for body text, headings, and more.
$font-size-base: 14px;
$font-size-xlarge: 24px;
$font-size-large: 18px;
$font-size-medium: 16px;
$font-size-small: 12px;
$font-size-h1: 36px;
$font-size-h2: 30px;
$font-size-h3: 24px;
$font-size-h4: 18px;
$font-size-h5: $font-size-base;
$font-size-h6: 12px;
/*  Site Variables Defined   ================================================ */

/* Layout or Component Specific Color Vaiables  */
$body-bg-color: $generic-bgcolor;
$link-color: $tertiary-color;
//tertiary Color for Link and other components
$tertiary-bg-color: $tertiary-color;
$tertiary-text-color: $complete-white-color;
$tertiary-link-hover: darken($tertiary-color, 25%);
//Primary Button
$btn-primary-bg-color: $secondary-color;
$btn-primary-bg-border-color: darken($secondary-color, 15%);
$btn-primary-text-color: $typography-dcolor;
$btn-primary-hover-color: lighten($secondary-color, 10%);
$btn-primary-hover-text-color: darken($typography-dcolor, 15%);
//Secondary Button
$btn-secondary-bg-color: $generic-bgcolor;
$btn-secondary-bg-border-color: darken($generic-bgcolor, 25%);
$btn-secondary-text-color: $typography-dcolor;
$btn-secondary-hover-color: darken($generic-bgcolor, 15%);
$btn-secondary-hover-text-color: darken($typography-dcolor, 15%);

/*  Resets   =============================================================== */

html,
body {
  width: 100%;
  height: 100%; //overflow-y: hidden;
  overflow-y: hidden !important;
}

// html {
//   overflow-y: auto;
// }

*,
body,
button,
input,
select,
textarea {
  font-family: "Open Sans", sans-serif;
}

body {
  height: 100%;
  font-family: "Open Sans", sans-serif !important;
  overflow-x: hidden; //overflow-y: scroll;
  overflow-y: hidden;
  // background-image: url(./assets/images/bg.png);
  // background-color: $generic-bgcolor;
  background-color: var(--bodyBgColor) !important;
  background-attachment: fixed;
  color:var(--textColor);
  padding: 0;
}

$transition-collapse:height .35s ease;

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

a {
  color: $link-color !important;
  text-decoration: none !important;
}
ul{
  padding-left:0px !important;
}
ul.reset,
ol.reset {
  margin: 0;
  padding: 0;
}

ul.reset li,
ol.reset li {
  list-style: none;
}

textarea {
  resize: vertical;
}

form input,
form select,form textarea,
.font-grey {
  color: $input-field-color !important;
  box-shadow: none !important;
}

.select select:hover, .textarea:hover, .input:hover, .select select.is-hovered, .is-hovered.textarea, .is-hovered.input{
  border-color: hsl(0, 0%, 86%) !important;
}
.select select, .textarea, .input{
  border-color: hsl(0, 0%, 80%) !important;
}
form label {
  font-size: $font-size-medium;
}

form .label{
  font-weight:600 !important;
  color: #686868 !important;
  font-size:.9rem !important;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6{
  font-weight:500 !important;
}

a.button{
  box-shadow: none !important;
}
.button{
   border-radius:5px !important;
  padding-left:1.2em !important;
  padding-right:1.2em !important;
  border-width:0px !important;
  border-color: transparent !important;
 // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}

.button.is-link.is-outlined:hover{
  .fa,.fa-solid{
    color:#fff !important;
  }
}
.form-control:focus{
  box-shadow: none !important;
  border: none !important;
}

.card{
  border-width: 0px !important;
}
.card-header{
  border-bottom: 0px !important; 
  .card-header-title{
    font-weight: normal !important;
    padding:.2rem .5rem;
  }
}

.fa-solid, .fa{
  font-size:1rem !important;
  color:#666 !important;
  cursor: pointer;
}
.navbar{
  position: relative;
  .fa,.fa-solid{
    // color:#fff !important;
  }
}

.bottom-box-shadow{
 // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
 box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 12px;
  
}
  .button{
  height:2rem !important;
  box-shadow:rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}
.page-header{
  background: #fff;
  height:60px;
}

.button.is-link.is-small{
  color:#fff !important;
}
.second-level-menu{
  a.button.is-small {
   // box-shadow:none !important;
  }
}
a.button.is-small {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}

table{
  td{
    color:var(--textColor) !important;
  }
}

.emoji-btn  .dropdown-menu  .dropdown-content{
  width:calc(100% - 5%) !important;
}
// app-page-header{
//   display: none;
// }
// @media screen and (max-width:767px) {
//   app-page-header{
//     display: block;
//   }
// }

.accordion{
  border:none !important;
}
app-single-chat{
  .contents{
    background-color: #f1f1f1 !important;
  }
  app-normal-view{
    .empty-head-icon{
      width: 35px;
      height: 35px;
      background: #fff !important;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
      font-size:20px;
      margin-top:0px;
      padding: 0px;
    }
  }
 
}

app-settings{
  .card-header-title{
    font-weight:500 !important;
  }
}

.img-options{
  .dropdown-menu{
    border-width: 0px !important;
  }
}
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.button.is-link.is-outlined {
  background-color: transparent;
  border-color: var(--primaryColor) !important;
  color: var(--primaryColor) !important;
}

.button.is-link.is-outlined:hover,
.button.is-link.is-outlined.is-hovered,
.button.is-link.is-outlined:focus,
.button.is-link.is-outlined.is-focused {
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
  color: var(--whiteColor) !important;
}

.button.is-link[disabled],
fieldset[disabled] .button.is-link {
  background-color: var(--primaryColor) !important;
  color: var(--whiteColor) !important;
}

// @include desktop {
//   .navbar-item {
//     &.is-hoverable:hover {
//       .navbar-dropdown {
//         display: block;
//       }
//     }
//   }
// }

.btnQAdisabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
  opacity: 0.5;
}
.control.has-icons-left .icon, .control.has-icons-right .icon{
  color:#6d6d6d !important;
}
/* ==========================================================================
Login Container page - styles
========================================================================== */

.signin-container {
  padding: 8% 0;
  transition: all 3s ease-in-out;

  .card {
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
      color: $typography-mcolor !important;
    .brand {
      position: relative;

      img {
        max-width: 50%;
        margin-left: -60px;
      }


      p {
        padding: 10px 0px 15px;
        font-size: 15px;
      }
    }

    .login-form {
      position: relative;

      // top: 91px;
      .input {
        height: 2.5em;
        color: $typography-dcolor !important;
      }

      .icon {
        height: 2.5em;
      }
    }

    .pri-cus-btn {
      width: 100%;
      margin-bottom: 20px;
      // padding: 7px 15px;
      padding: 10px 15px;
      background-color: var(--primaryColor) !important;

    }

    .f-sm {
      label {
        font-size: 15px;
      }

      a {
        font-size: 15px;
        color: var(--primaryColor);
      }
    }
  }

  .notification {
    // background-color: inherit;

    p {
      padding: 0px 20px;
      margin-top: 15px;
    }
  }
}

/*-------------------------------------------------------------------------------
Signup container page -styles
----------------------------------------------------------------------------------*/

.signup-container {
  padding: 7% 0;
  transition: all 3s ease-in-out;
  align-items: center;
  display: flex !important;
  // background: #ffffff;

  // -webkit-animation-duration: 0.5s;
  //     animation-duration: 0.5s;
  //     -webkit-animation-fill-mode: both;
  //     animation-fill-mode: both;
  //     animation-name: fadeInDown;
  .card {
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
      color: $typography-mcolor !important;

    .brand {
      img {
        max-width: 50%;
      }

      p {
        padding: 10px 0px 15px;
        font-size: 15px;
      }
    }

    .login-form {
      .input {
        height: 2.5em;
        color: $typography-dcolor !important;
        width: 100%;
      }

      .icon {
        height: 2.5em;
      }
    }

    .pri-cus-btn {
      width: 100%;
      margin-bottom: 20px;
      // padding: 7px 15px;
      padding: 10px 15px;
      background: var(--primaryColor);
    }

    .f-sm {
      label {
        font-size: 15px;
      }

      a {
        font-size: 15px;
        color: var(--primaryColor);
      }
    }
  }

  .notification {
    background-color: inherit;

    p {
      padding: 0px 20px;
      margin-top: 15px;
    }
  }
}

.sign-footer {
  background:$input-field-border-active;
  color: var(--whiteColor);
  padding: 7px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  display: none;
  //box-shadow: rgba(0, 0, 0, 0.7) 0 -1px 2px 0;

  footer {
    min-height: 45px;
  }

  a {
    color: var(--whiteColor);
    padding: 0 15px;

    &:hover {
      text-decoration: underline;
    }
  }
}

/* ==========================================================================
 Initial page - styles
========================================================================== */



/*=======================================================
Global Search style
========================================================*/

/**************Global Search Style Start*****************/
.app-global-search {
  margin: 0 auto;
  position: relative;
  -webkit-app-region: no-drag;
  -webkit-user-select: none;
  position: relative;
  top: 8px;
  width: 550px;

  .cs-search-outer {
    display: flex;
    align-items: center;
    position: relative;

    &>form {
      width: 100%;
      height: 3.2rem;
      min-width: 0;
    }

    .angucomplete-dropdown {
      cursor: default;
      background: 0;
      border-radius: 0;
      width: 100%;

      li {
        margin: 0;
        padding: 0;
      }
    }

    .fa.fa-search {
      padding-right: 3.5rem;
    }
  }

  .input-group {
    display: block;
    height: 2.5rem;
    width: 100%;
  }

  .cs-header-search {
    border: 0;
    border-radius: 0.3rem;
    color: var(--whiteColor);
    // margin-bottom:.1rem;
    padding: 0 3rem 0.1rem 1.2rem;
    background: #edebe9;
    height: 2.5rem;

    .ng-not-empty {
      border: 0;
      max-height: 100%;
      padding-bottom: 0.2rem;
      color: #000;
      background: var(--whiteColor);
    }
  }

  .cs-header-search:focus {
    border: 0;
    max-height: 100%;
    padding-bottom: 0.2rem;
    color: #000;
    background: var(--whiteColor);
  }

  .cs-search-outer-cancel {
    border: 0;
    height: 2.5rem;
    margin: 0;
    left: auto;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 1px;
    border-left: 0;
    border-radius: 0 0.3rem 0.3rem 0;
    background: #f3f2f1;
  }

  .autosuggest-dropdown {
    background: var(--whiteColor);
    border-radius: 0.3rem;
    width: 100%;
    z-index: 2147483647;
  }

  .field {
    .global-search-suggestion {
      position: absolute;
      width: 100%;
      text-align: left;
      margin-top: -1px;
      z-index: 989;
      cursor: default;
      -webkit-user-select: none;

      .search-suggestion {
        background: var(--whiteColor);
        box-shadow: 0 9px 8px -3px;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        padding: 0;
        border: 0;
        // border-radius: 0 0 24px 24px;
        padding-bottom: 4px;
        overflow: hidden;
        box-shadow: 0 9px 8px -3px #403c433d, 8px 0 8px -7px #403c433d,
          -8px 0 8px -7px #403c433d;
      }

      .suggestion-inner-text {
        flex: auto;
        padding-bottom: 16px;

        .suggestionList {
          display: flex;
          align-items: center;
          min-width: 0;
          max-height: none;
          padding: 0;

          .jKWzZXdEJWi__suggestions-inner-container {
            flex: auto;
            display: flex;
            align-items: center;
            margin: 0 20px 0 14px;

            .icon {
              display: flex;
              align-items: center;
              margin-right: 14px;

              .fa {
                background-size: 20px;
                min-height: 20px;
                min-width: 20px;
                height: 20px;
                width: 20px;
                color: #ccc;
              }
            }

            .sbtc {
              display: flex;
              flex: auto;
              flex-direction: column;
              min-width: 0;
              max-height: none;
              padding: 6px 0;

              .sbl1 {
                display: flex;
                font-size: 16px;
                color: #212121;
                flex: auto;
                align-items: center;
                word-break: break-word;
                padding-right: 8px;
              }

              .sbl2 {
                line-height: 12px;
                font-size: 13px;
                color: #80868b;
                margin-top: 2px;
              }
            }

            .sbab {
              display: flex;
              align-self: stretch;

              .sbdb {
                color: #80868b;
                cursor: pointer;
                font: 13px arial, sans-serif;
                align-self: center;

                &:hover {
                  color: var(--primaryColor);
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

}

.activeUserMobile {
  .mobileViewBtn {
    display: none;
  }
}

.cs-header-search {
  border: 0;
  flex: 1;
  height: inherit;
  padding: 0.6rem 3rem 0.7rem 1.2rem;
  width: 100%;
  // background: #edebe9;
  color: #252423;
}

.autosuggest-dropdown {
  box-shadow: 0 0.2rem 1.6rem 0 rgba(37, 36, 35, 0.3);
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2147483647;
}

.simple-scrollbar {
  -ms-overflow-style: none;
  overflow: hidden;
}

.autosuggest-scroll {
  max-height: 80vh;
}

.angucomplete-dropdown .angucomplete-row {
  color: #252423;
}

.angucomplete-row {
  padding: 5px;
  color: #000;
  margin-bottom: 4px;
  clear: both;
}

.autosuggest-dropdown {
  .as-more-select {
    // background: #edebe9;
    color: #252423;
  }

  ul button {
    margin: 0;
    min-height: 2.8rem;
    width: 100%;
  }

  .box-background-transparent {
    background: 0 0;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 0.3rem;
    // margin: 0 1.2rem 0 0;

    .fa.fa-search {
      line-height: 3rem;
    }
  }

  .result-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // margin-top: .5rem;
    width: calc(100% - 3.7rem);
    display: inline-block;
    height: 2.3rem;
    text-align: initial;
    line-height: 3rem;
  }

  .search-term {
    font-weight: 700;
    font-size: 1rem;
    margin-right: 1.6rem;
  }
}

.pull-left {
  float: left !important;
}

/**************Global Search Style End*****************/

.navbar-item.has-dropdown:hover .navbar-link,
.navbar-item.has-dropdown.is-active .navbar-link {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

 

.tabs {
  ul {
    font-size: 15px;

    li {
      a {
        font-weight:500;
        color: #c1c1c1;
      }
    }
  }
}

.tabs li.is-active a {
  border-bottom-color: var(--primaryColor) !important;
  color: var(--primaryColor) !important;
  font-weight:500;
}

.tabs a:hover {
  color: var(--primaryColor) !important;
  border-bottom-color: var(--primaryColor) !important;
}


.hint-relative {
  a {
    color: var(--whiteColor) !important;
  }
}

.settings-header {
  position: absolute !important;

  h3 {
    font-weight: 700;
  }
}

.setting-section {
  width: 100%;
  padding: 1.5rem 2.5rem;

  

  .tabs {
    display: flex;
    flex-direction: row;

    ul {
      li {
        a {
          padding: 1em !important;
        }
      }
    }
  }



  .tabs li.is-active a {
    border-bottom-color: var(--primaryColor) !important;
    color: var(--primaryColor) !important;
    font-weight: 700;
    font-size: 14px;
  }

  .sub-block {
    padding-top: 2.5rem;

    .sub-block-head {
      position: relative;
      padding-bottom: 1.25rem;

      .sub-block-head-content {
        .sub-block-title {
          font-size: 1.25rem;
          font-weight: 700;
        }

        .sub-block-des {
          color: #526484;
          font-size: 12px;
        }
      }
    }

    .card-bordered {
      border: 1px solid #dbdfea;

      .card-inner {
        padding: 1.5rem;

        .flex-md-nowrap {
          flex-wrap: nowrap !important;
        }

        .sub-block-text {

          h6 {
            font-size: 1rem;
            letter-spacing: -.01em;
            margin-top: 0;
            margin-bottom: .5rem;
            font-weight: 700;
            line-height: 1.1;

            .text-base {
              color: #526484 !important;
              font-size: 12px;
            }
          }

          .text-soft {
            color: #8094ae !important;
          }
        }

        .sub-block-actions {
          float: right;
          position: relative;
          top: 2px;

          &:hover {
            color: $primary-color !important;
            background-color: $complete-white-color !important;
          }
          // position: relative;
          // top: -43px;
        }
      }
    }


  }

  .is-cursor-pointer {
    cursor: pointer !important;
  }

  .transform {
    -webkit-transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
    transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
  }

  .is-duration-300 {
    transition-duration: 0.3s;
  }

  .shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .hover-translate-y:hover {
    -webkit-transform: translateX(0) translateY(-0.25rem) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1) !important;
    transform: translateX(0) translateY(-0.25rem) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1) !important;
  }

  .hover-shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .subscriptionCard {
    padding: 10px;
    box-shadow: 0 3px 6px #bbc2ce;
    background: #ffffff;
    border: none;
    border-radius: 5px;
    margin-bottom: 30px;

    a {
      color: $complete-white-color !important;

      &:hover {
        color:$complete-white-color !important;
      }
    }

    .plan-name {
      // margin: 50px 10px 20px;
      white-space: pre-line;
      font-size: 18px;
      font-weight: 700;
      background: var(--primarylightColor);
      color: var(--primaryColor);
      padding:10px 0px;
   
    }

    .rate {
      height: 130px;
      text-align: center;
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .plan-cost {
        font-size: 50px;
        font-weight: 900;

        .cost-symbol {
          font-size: 18px;
          margin-right: 3px;
          font-weight: 400;
        }
      }

      .plan-enterp-cost {
        font-size: 16px;
        font-weight: 900;
      }

      .plan-details {
        margin-bottom: 0;
        font-size: 14px;
      }

      .btn {
        margin-top: 49px;
      }

      .button {
        &:hover {
          background-color: $complete-white-color !important;
          border: 1px solid $primary-color !important;
          color: $primary-color !important;
        }
      }
    }

    .feature {
      font-size: 14px;
      padding: 0 15px;

      p {
        border-bottom: .5px solid #f1f2f5;
        margin: 20px 0;
        padding-bottom: 5px;
      }
    }
  }

  .settingsCard {
    box-shadow: 0 3px 6px #bbc2ce;
    background: #ffffff;
    border: none;
    border-radius: 5px;
    margin-bottom: 30px;



    .settingsCard-header {
      display: flex;
      padding: 14px;
      border-bottom: 1px solid #000;
      background-color: #cccccc24;

      .main-heading{
        .second{
          width: 100%;
          font-size: 12px;
          text-align: center;
          margin-top: 3px;
        }
      }

    }
    
    .settingsCard-body{
      padding: 20px;
    }
  }

  .summaryCardHeader {


    display: flex;
    padding: 10px;
    border-bottom: 1px solid #000;
    background-color: #cccccc24;

    p {
      margin-bottom: 10px;
    }

    .first {
      width: 85%;
      font-size: 15px;
      font-weight: 700;
    }

    .second {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
    }
  }

  .accordion{
    max-width:700px;
    margin: auto;
  
    .accordion-body{
      max-height:70vh;
      overflow-y: auto;
      scrollbar-width:none !important;
    
    }
  }

  .accordion{
    border-color: #fff !important;
    .accordion-header{
      display: none;
    }
  }

 

}



/* ==========================================================================
Basic three panel structure with flex
========================================================================== */

.flex-grid {
  display: flex;
  align-items: stretch;
  height: calc(100vh - 58px);
}

.flex-grid .fi-p {
  flex: 0 0 220px;
  // flex: 0 0 90px;
  padding: 0px;
}

.flex-grid .se-p {
  // flex: 0 1 280px;
  // flex: 0 1 320px;
  background: $listing-panel-bg;
  color: $listing-panel-pri-text-color;
  // min-width: 320px; // -webkit-animation-duration: 0.3s;
  // animation-duration: 0.3s;
  // -webkit-animation-fill-mode: both;
  // animation-fill-mode: both;
  // animation-name: fadeInLeft;
}

.flex-grid .fo-p {
  flex: 1 1 180px;
  min-width: 300px;
  background: $listing-panel-bg;
  color: $listing-panel-pri-text-color;
  height: calc(100vh - 57px);
  border-left: 1px solid #dcdcdc;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInRight;

  .is-centered {
    .tabsPanel {
      padding: 16px 10px 16px 10px;
      border-bottom: 1px solid #ccc;

      a {
        color: $listing-panel-pri-text-color;
      }

      span.is-small {
        padding-left: 1.5em;
        padding-right: 0.5em;
      }

      .fa {
        padding-right: 1em;
      }

      .closeIcon {
        position: relative;
        float: right;
        color: #9fa2aa;
        top: 0.25em;

        &:hover {
          color: #010101;
        }
      }
    }

    .is-active {
      display: block !important;
    }
  }
}

.flex-grid .th-p {
  display: flex;
  flex-direction: column;
  flex: 2 2 250px;

  .disabledForm {

    input,
    textarea,
    select {
      pointer-events: all;
      background-color: #faf8f8;
      cursor: not-allowed;
    }
  }
}

// .col {
//   background: salmon;
//   padding: 10px;
//   border:1px solid red;
// }
footer {
  padding: 0; // background-color: rgba(255,255,255,0.6);
  min-height: 62px;
  order: 3;
  position: relative;
  flex: none;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
}

header {
  display: flex;
  box-sizing: border-box;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  order: 1;
  position: relative;
  flex: none;
  height: 56px;
  width: 100%;
  background-color: $content-panel-header-bg;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  z-index: 5;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInRight;
}

.th-p .contents {
  order: 2;
  flex: 1 1 0;
  position: relative;
  order: 2;
  // -webkit-animation-duration: 0.5s;
  // animation-duration: 0.5s;
  // -webkit-animation-fill-mode: both;
  // animation-fill-mode: both;
  // animation-name: fadeInRight;
}

.th-p .contents>div {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto; //z-index: 100;
  box-sizing: border-box;
  // border-left: 1px solid rgba(0, 0, 0, 0.03);
  transition: background 0.3s ease-out 0.1s;
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  scrollbar-width: none;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.5) !important;
}

/* ==========================================================================
Chats Container page - styles
========================================================================== */

.chats {
  .chat-name {
    // border-bottom: 1px solid #dcdcdc;
    position: relative;

    .image-1 {
      position: absolute;
      top: 20%;
      left: 8px;
      z-index: 6;
    }

    .image-2 {
      position: absolute;
      top: 20%;
      left: 14px;
      z-index: 5;
    }

    .image-3 {
      position: absolute;
      top: 20%;
      left: 20px;
      z-index: 4;
    }

    .image-4 {
      position: absolute;
      top: 20%;
      left: 26px;
      z-index: 3;
    }

    .image-5 {
      position: absolute;
      top: 20%;
      left: 32px;
      z-index: 2;
    }

    .image-6 {
      position: absolute;
      top: 20%;
      left: 38px;
      z-index: 1;
    }

    .see-more {
      position: absolute;
      top: 34%;
      left: 80px;
    }

    img {
      margin-right: 5px;
      max-width: 36px;
      border-radius: 100%; //border: 2px solid #dcdcdc;
    }

    span.chat-title-name {
      line-height: 35px;
    }

    h3 {
      margin-top: 5px !important;
    }

    h3.chat-title-name {
      position: absolute;
      left: 3%;
    }

    .chat-title-tag {
      position: absolute;
      top: 64%;
      left: 44.5%;
    }

    .fa-angle-double-left,
    .fa-angle-double-right {
      line-height: 36px;
      padding-right: 7px;
      font-size: 18px !important;
      cursor: pointer;
      font-weight: bold;
    }
  }

  a {
    color: var(--primaryColor);
  }

  .chat-list {

    // max-height: 700px;
    // overflow-y: scroll;
    // overflow-x: hidden;
    .is-pulled-right.oposite {
      background: #fff;
    }

    .box {
      position: relative;
    }

    article.right-top:before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: auto;
      right: -23px;
      top: -1px;
      bottom: auto;
      border: 21px solid;
      border-color: #fff transparent transparent transparent;
    }

    article.right-top:after {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: auto;
      right: -20px;
      top: 0px;
      bottom: auto;
      border: 20px solid;
      border-color: #fff transparent transparent transparent;
    }

    article.left-top:before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: -21px;
      right: auto;
      top: -1px;
      bottom: auto;
      border: 20px solid;
      border-color: #dcdcdc transparent transparent transparent;
    }

    article.left-top:after {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: -19px;
      right: auto;
      top: 0px;
      bottom: auto;
      border: 20px solid;
      border-color: white transparent transparent transparent;
    }

    figure {
      margin: 0.5em 0.5em 0;

      img {
        border-radius: 100%;
      }
    }

    i.fa-ellipsis-h {
      font-size: 18px;
      color: #b9b5b5;
    }
  }

  .chat-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
  }

  .box {
    padding: 0.5rem;
    max-width: 50%;
    min-width: 225px;
    display: inline-block;

    .chat-time {
      color: $subtle-dcolor;
      font-size: 11px;
      padding-left: 0.225rem;
    }

    .size-14.normal-chat-content {
      span {
        display: flex !important;
      }
    }

    .size-14.normal-chat-content.file-links {
      span {
        display: inline-block !important;

        // white-space: pre-wrap !important;
        // overflow-wrap: break-word;
        .emoji-pipe-image {
          overflow: initial !important;
          margin-left: 4px;
        }
      }
    }

    .pinTask {
      padding-left: 1em;

      .fa {
        font-size: 15px !important;
        color: #909090;

        &:hover {
          cursor: pointer;
          color: #000;
        }
      }
    }
  }

  .chat-file-type {
    margin-bottom:15px;
    .media-content {
      overflow-x: hidden;

      strong {
        text-transform: capitalize;
      }
    }

    position: relative;

    .chat-msg-image {
      cursor: pointer;

      img {
        min-height: 150px;
        height: 150px;
        min-width: 100%;
        width: 100%;
      }

      .image-hover {
        display: none;
        padding: 10px 20px;
        position: absolute;
        background: var(--whiteColor);
        // width: 250px;
        width: 94%;
        opacity: 0.8;
        left: 8px;
        top: 141px;

        .image-download {
          position: absolute;
          right: 10px;
        }
      }

      .video-play {
        position: absolute;
        top: 48%;
        left: 45%;

        i.fa {
          font-size: 30px !important;
          position: absolute;
          top: 48%;
          left: 45%;
          color: #b3b1b1;
        }
      }
    }

    .audio-file {
      padding: 5px 10px;
     // background: #f3f3f3;
      border-radius: 5px;
      margin-bottom: 5px;

      .media {
        .media-left {
          margin-top: 5px;
        }

        .play-btn {
          margin-top: 10px !important;
          margin-left: 5px;

          img {
            cursor: pointer;
          }
        }

        padding-top: 0 !important;
        border-top: none !important;

        .content:not(:last-child) {
          margin-bottom: 0 !important;
        }
      }

      span {
        display: inline-block !important;

        i.fa {
          font-size: 14px !important;
        }
      }

      span.file-name-msg {
        font-size: 14px;
        max-width: 250px;
        min-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .audio-download {
        position: absolute;
        right: 24px;
        // bottom: 12px;
      }
    }
  }

  .chat-file-type:hover .chat-msg-image .image-hover {
    display: block;
  }

  .timing {
    position: relative;

    small {
      padding: 5px 10px;
      border-radius: 16px;
      color: $content-panel-link-highlight-text-color;
      background: #f5f4f4;
      font-size: 12px;
      font-weight: 600;
    }

    span {
      // padding: 5px;
      // border-radius: 2px;
      // background: #dadfe4;
      // padding: 7px 10px;
      // padding: 5px 10px;
      // border-radius: 16px;
      // color: #a2a2a2;
      // font-size: 12px;
      // font-weight: 600;
      // display: inline-block;
      padding: 5px 10px;
      border-radius: 5px;
      color: #909090;
      font-size: 12px;
      font-weight: 600;
      display: inline-block;
      background: #f7f7f7;
    }

    span.time-history::before {
      position: absolute;
      content: "";
      height: 1px;
      left: 1%;
      top: 26px;
      width: 44%;
      // transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
      background: #d0d0d0;
    }

    span.time-history::after {
      position: absolute;
      content: "";
      height: 1px;
      right: 0;
      top: 26px;
      width: 44%;
      // transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
      background: #d0d0d0;
    }
  }

  .text-bar {
    position: relative;
    padding: 0.75rem 2rem; // border-top: 1px solid #dcdcdc;

    .input {
      padding: 25px 73px 25px 5px;
    }

    i.fa {
      position: absolute;
      right: 23px;
      top: 12px;
      padding: 17px 25px;
      margin-right: 10px;
      background: $content-panel-chat-button-bg;
      color: $content-panel-chat-button-text-color;
      cursor: pointer;
    }
  }

  .notification-1 {
    figure {
      margin: 1em;

      img {
        border-radius: 50%;
      }
    }
  }

  .is-grouped {
    margin: 10px 24px 8px 32px;
    -webkit-transition: background-color ease 0.218s, box-shadow ease 0.218s,
      border ease 0.218s;
    transition: background-color ease 0.218s, box-shadow ease 0.218s,
      border ease 0.218s;
    background-color: var(--whiteColor);
    min-height: 45px;
    overflow-x: initial;
    overflow-y: visible;
    position: relative;
    border: 1px solid #ccc;

    .control.has-icons-left .icon.is-left,
    .control.has-icons-right .icon.is-right {
      color: #7a7a7a; // border-right: 1px solid #e6e3e3;
      cursor: pointer;
      pointer-events: auto;
    }

    &:focus-within {
      border-color: var(--primaryColor);
      box-shadow: 0 0 0 0.125em rgba(1, 59, 221, 0.25);
      min-height: 55px;
      transition: all 1s;
      -webkit-transition: all 1s;
      /* Safari */
      // -webkit-animation: animation 2s ease-in-out;
      // -moz-animation: animation 2s ease-in-out;
    }
  }
}

[placeholder]:empty::before {
  content: attr(placeholder);
  color: #ccc;
  font-size: 14px !important;
}

footer.chats {
  .is-grouped {
    .is-expanded {
      align-items: center;
      display: flex;
      flex-direction: row;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      padding: 0;
      margin: 0px;
    }

    .input {
      // padding: 1.35em 2.25em;
      height: 45px;
    }

    .input.multiple {
      // padding: 1.35em 2.25em;
      // padding: 1.35em 4.5em;
      display: block;
      height: auto;
      font-size: 1rem;
      line-height: 22px;
      cursor: text;
      min-height: 40px;
      position: relative;
      width: 100%;
      word-wrap: break-word;
      word-break: break-all;
      word-break: break-word;
      padding: 5px 47px;
      border: 0;
      box-shadow: none;
      max-height: 85px;
      overflow: auto;
    }

    .textarea {
      padding: 0.625em 2.25em;
      max-height: 100px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .control {
      .button {
        height: 2.75em;
      }
    }

    .control.has-icons-left .icon,
    .control.has-icons-right .icon {
      height: 2.75em;
    }

    .control.has-icons-left .icon.first-icon {
      // border-right: 1px solid #dcdcdc;

      // width: 48px;
      width: 35px;

      // width: 40px;
      // font-size: 16px;
      // transform: rotate(40deg);
      .fa {
        transform: rotate(40deg);
        font-size: 19px !important;
        position: relative;
        top: auto !important;
        bottom: auto;
        left: auto !important;
        margin-left: 24px !important;
      }
    }

    .control.has-icons-left .icon.second-icon {
      // left: 35px;
      left: 32px;
    }
  }
}

.new-chat {
  .columns {
    margin-bottom: 0 !important;

    .class_list {
      margin-bottom: 0px !important;
      width: 50%;
    }

    .addStuSubTitle {
      font-size: 12px !important;
    }
  }

  .card-content {
    padding: 0.8rem 1.5rem;
  }

  .tabs {
    margin: 0;

    ul {
      margin: 0;
    }
  }

  .table {
    margin: 0;

    tbody {
      td {
        position: relative;

        h4 {
          margin: 0;
        }

        img {
          border-radius: 50%;
        }

        .icon {
          position: absolute;
          left: 35%;
          bottom: 8px;
        }
      }

      tr:last-child {
        border-bottom: 1px solid #dbdbdb;
      }
    }
  }

  .userList {
    .panel {
      .image {
        border-radius: 100%;
        img{
          border-radius:50%;
        }
      }
    }

    .field {
      height: calc(100vh - 480px);
      // height: calc(100vh - 600px);
      // height: calc(100vh - 400px);
      overflow-y: auto;
      min-height: 150px !important;
      border-left: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;

      // border-radius: 6px;
      label {
        .panel {
          .chat-user-status {
            .fa {
              color: #909090;

              &:hover {
                color: var(--primaryColor);
              }
            }
          }
        }
      }

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }

      ::-webkit-scrollbar {
        width: 3px;
        background-color: #f5f5f5;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px; // background-color: #555;
        background-color: rgb(167, 166, 166);
      }

    }
  }

  .tabs.is-boxed a:hover {
    border-bottom-color: transparent;
  }
  .chat-user-status{
    img.image{
      border-radius: 50% !important;
    }
  }

}

.upload-section {
  height: 150px;
  border: 1px dashed #b7b4b4;
  text-align: center;
  cursor: pointer;

  p {
    padding-top: 60px;
  }
}

.group-option {
  .option-list .columns {
    margin-bottom: 0;
  }
}

.modal-card-body {
  .panel-overflow {
    // max-height: 600px;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .panel-block {
    img {
      border-radius: 50px;
    }
  }
}

.file .control.has-icons-left .icon.is-left {
  color: #7a7a7a;
  border-right: 1px solid #e6e3e3;
  cursor: pointer;
  pointer-events: auto;
}

.usergroup-level {
  .level-right {
    .mobileViewBtn {
      display: none;
    }
  }
}

.elibraryDocView {
  width: 100%;
  height: 50vh;
}

.elibrary-left {
  .list-wrapper.list-overflow {
    li {
      small.lesson {
        font-size: 10px;
        color: #999;
      }

      p.is-size-6 {
        max-width: 125px;
        width: 124px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-height: 20px;

        &:hover {
          // overflow: visible;
          white-space: normal;
          // -ms-word-break: break-all;
          // word-break: break-all;
          /* Non standard for webkit*/
          // word-break: break-word;

          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          hyphens: auto;

          // max-width: 400%;
          // width: 400%;
          z-index: 99999;
          /* stack above subsequent cells */
        }
      }
    }
  }

  .hideNewNotify {
    display: none;
  }

  .animated {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeinout;
  }
}

@keyframes fadeinout {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.newNotification {

  [data-tooltip]:not(.is-disabled)::before,
  [data-tooltip]:not(.is-loading)::before,
  [data-tooltip]:not([disabled])::before {
    width: 300px;
    white-space: pre-wrap;
  }
}

/******
Interactive video quizzes style
**/
.interactiveQuiz {
  .list-wrapper {
    .list-overflow {
      li {
        small.lesson {
          font-size: 10px;
          color: #999;
        }

        p.is-size-6 {
          max-width: 125px;
          width: 124px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          max-height: 20px;
        }
      }
    }
  }

  .button.is-link[disabled],
  fieldset[disabled] .button.is-link {
    background-color: #b7b9be;
    border-color: #ccc;
    box-shadow: none;
    color: #000 !important;
    font-weight: 600;
  }
}

.library_list_view {
  padding: 20px 0;

  .shareBtnOptions {
    position: relative;
    top: -21px;
  }

  .fileBtnOptions {
    position: relative;
    top: -21px;
    right: -15px;
    line-height: 1;

    // display: none;
    .dropdown {
      .dropdown-menu {
        min-width: 6rem;

        .dropdown-item {
          .fa {
            font-size: 12px !important;
            margin-right: 5px;
            margin-top: 5px;
          }
        }

        hr {
          margin: 0px;
        }
      }
    }

    a {
      color: #ccc;

      &:hover {
        color: #000;
      }
    }

    .hiddenDownloadLink {
      pointer-events: none;
    }
  }
}

.topicListView {
  table {
    font-size: 14px;

    td {
      .list-ellipsis {
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .tbody {
      tr {
        .is-info {
          color: var(--whiteColor);
          background-color: steelblue;
        }

        .feedbackDetails {
          .feedSub {
            background-color: var(--primaryColor);
            color: #fff !important;
          }
        }

        td {
          a {
            .is-info {
              text-decoration: none;
              margin-top: 4px;
              font-weight: 700;
            }
          }

          span {
            .isPublished {
              color: var(--whiteColor);
              background-color: #8896c5;

              i {
                cursor: not-allowed;
                opacity: 0.5;
              }
            }
          }
        }

        .actionBtnMobileView {
          display: none;

          .dropdown-item {
            display: none;
          }
        }
      }
    }
  }

  .accordions {
    margin-top: 20px;

    .accordion {
      .accordion-header {
        background: #fff !important;
        color: var(--primaryColor) !important;
        font-weight: 600;
      }
    }
  }

  .button {
    border-radius: 30px;
    width: 31px;
    height: 31px;
    border: none;

    /* margin-right: 10px; */
    .fa {
      font-size: 15px !important;

      &:hover {
        color: var(--whiteColor);
      }
    }

    &:hover {
      background-color: var(--primaryColor);
      color: #fff !important;

      a {
        color: #000 !important;
      }
    }
  }

  .is-successActive {
    border-radius: 6px;
    border: none;
    background-color: forestgreen;
    cursor: none;
    color: var(--whiteColor);
    justify-content: center;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    text-align: center;
    white-space: nowrap;
    font-size: 12px;
    position: relative;
    top: 4px;
  }
}

.library_tile_view {
  padding: 7px;

  .flex-container {
    .flex-item {
      .card {
        &:hover {
          cursor: pointer;
        // .card-image {
        //     background-color: #010101;
        //     opacity: 0.7;

        //     .thumb_play {
        //       display: block;

        //       .fa {
        //         color: var(--whiteColor);
        //       }
        //     }
        //   }

          .button {
            // background-color: #000;
            // border-color: #000;
            // color: #fff;
            box-shadow: none;
          }

          .shareBtnOptions {
            display: block !important;
          }

          .fileBtnOptions {
            display: block !important;
          }

          .buylesson {
            display: block !important;
          }
        }

        .card-image {
          cursor: pointer;

          .thumb_play {
            position: absolute;
            top: 48%;
            left: 45%;
            display: none;

            .fa {
              color: #dcdcdc;
              font-size: 32px !important;
            }
          }

          .tile_imgcenter {
            // height: 150px;
            // display: block;
            // margin-left: auto;
            // margin-right: auto;
            // width: 50%;
            // padding: 10px;
          }

          .videoDuration {
            font-size: 12px;
            position: absolute;
            right: 5px;
            font-weight: 600;
            background: #333;
            color: var(--whiteColor);
            bottom: 10px;
            width: 59px;
            text-align: center;
          }
        }

        .card-content,.card-body {
          cursor: default !important;
        .questionnaries{
          border-left:1px solid #acacac;
        }
          .is-divider-vertical {
            display: flex;
            flex: none;
            width: auto;
            height: auto;
            padding: 2rem;
            margin: 0;
            position: relative;
            border-top: none;
            min-height: 2rem;

            &::before {
              border-left: 3px solid #dbdbdb;
              bottom: 1rem;
              content: "";
              display: block;
              // left: 50%;
              position: absolute;
              top: 1rem;
              -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              transform: translateX(-50%);
            }
          }

          .embQuestStyle {
            text-align: left;
            font-size: 16px;
          }

          // .is-divider[data-content]::after,
          // .is-divider-vertical[data-content]::after {
          //   background: #fff;
          //   color: #b5b5b5;
          //   content: attr(data-content);
          //   display: inline-block;
          //   font-size: 0.75rem;
          //   padding: 0.4rem 0.8rem;
          //   -webkit-transform: translateY(-1.1rem);
          //   -ms-transform: translateY(-1.1rem);
          //   transform: translateY(-1.1rem);
          //   text-align: center;
          // }
          .responsiveRadioBtn {
            margin: 20px 0;
          }

          &:hover {
            // .shareBtnOptions{
            //   background-color: $secondary-color;
            //   color:$complete-white-color;
            //   width: 35px;
            //   height: 35px;
            //   border-radius: 27px;
            //   // a{
            //   //   color :#fff;
            //   // }
            // }
            // .fileBtnOptions{
            //   background-color: $secondary-color;
            //   color:$complete-white-color;
            //   width: 35px;
            //   height: 35px;
            //   border-radius: 27px;
            //   // a{
            //   //   color :#fff;
            //   // }
            // }
          }

          section.section {
            padding: 1rem 2rem 0rem 0rem;

            .removeAssQuestion {
              float: right;
              top: -32px;
              text-align: center;
              color: var(--whiteColor);
              cursor: pointer;
              position: relative;
              right: -29px;

              &:hover {
                color: #d62e2e !important;
              }
            }
          }

          .title {
            font-size: 14px !important;
            margin-bottom: 4px;
            white-space: nowrap;
            width: 121px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .description {
            font-size: 12px !important;
            color: #000 !important;
            margin-bottom: 4px;
            white-space: nowrap;
            width: 121px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .shareBtnOptions {
            position: relative;
            top: -15px;
            line-height: 1;
          }

          .fileBtnOptions {
            position: relative;
            top: -25px;
            right: -15px;
            line-height: 1;

            // display: none;
            .dropdown {
              .dropdown-menu {
                min-width: 6rem;

                .dropdown-item {
                  .fa {
                    font-size: 12px !important;
                    margin-right: 5px;
                    margin-top: 5px;
                  }
                }

                hr {
                  margin: 0px;
                }
              }

              .dropdown-trigger {
                position: relative;
                top: 8px;
                right: 5px;
              }
            }

            .hiddenDownloadLink {
              display: none;
            }

            a {
              color: #000;

              &:hover {
                color: var(--primaryColor);
              }
            }

            .hiddenDownloadLink {
              pointer-events: none;
            }
          }

          .shared {
            margin-bottom: 2px;
            font-size: 13px;
            color: #ccc;
            text-transform: capitalize;
          }

          .subtitle {
            margin-bottom: 2px;
            font-size: 13px;
            color: #ccc;
          }

          .price-text--container--Ws-fP {
            align-items: center;
            color: #3c3b37;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .price-text--price-part--Tu6MH {
              line-height: 1;
              padding: 0.4rem 0;
              margin-right: 0.8rem;
              white-space: nowrap;

              &:last-child {
                margin-right: 0;
              }
            }

            .price-text--original-price--2e-F5 {
              color: #73726c;
            }

            .udlite-sr-only {
              position: absolute !important;
              height: 1px;
              width: 1px;
              overflow: hidden;
              clip: rect(1px, 1px, 1px, 1px);
            }
          }

          .btn_buylesson {
            float: right;
            position: absolute;
            right: 10px;
            line-height: 2.2;
            bottom: 20px;

            .button {
              margin-left: 10px;
            }
          }

          .switch[type="checkbox"] {
            outline: 0;
            user-select: none;
            display: inline-block;
            position: absolute;
            opacity: 0;

            .is-rtl {
              &+label {
                padding-left: 0;
                padding-right: 3.5rem;
              }
            }

            &+label {
              position: relative;
              display: initial;
              font-size: 1rem;
              line-height: initial;
              padding-left: 3.5rem;
              padding-top: 0.2rem;
              cursor: pointer;
            }

            &:checked {
              &+label {
                &::before {
                  background: #00d1b2;
                }
              }
            }
          }

          .questionnarie {
            
            margin-bottom: 20px;

            .questionariesTab {
              &ul {
                font-size: 15px;
              }
            }

            .question-choice {
              padding: 1rem 0rem 3rem 0rem;
            }

            .questionOption {
              padding: 0rem 0rem !important;
            }

            .question-option {
             // padding: 1rem 9rem 3rem 2rem;

              .control {
                margin-bottom: 10px;

                ul {
                  .editQuizContent {
                    margin-left: 0em !important;
                  }
                }

                .removeOptions {
                  float: right;
                  top: 5px;
                  width: 30px;
                  height: 30px;
                  text-align: center;
                  color: var(--whiteColor);
                  cursor: pointer;
                  position: absolute;
                  right: -69px;

                  &:hover {
                    color: #d62e2e !important;
                  }
                }

                .is-checkradio[type="radio"],
                .is-checkradio[type="checkbox"] {
                  outline: 0;
                  user-select: none;
                  display: inline-block;
                  position: absolute;
                  opacity: 0;
                }

                .is-checkradio[type="radio"]+label:first-of-type,
                .is-checkradio[type="checkbox"]+label:first-of-type {
                  float: right;
                  position: absolute;
                  margin-left: 10px;
                }

                .is-checkradio[type="radio"]:checked+label::before,
                .is-checkradio[type="radio"]:checked+label:before,
                .is-checkradio[type="checkbox"]:checked+label::before,
                .is-checkradio[type="checkbox"]:checked+label:before {
                  animation-name: none;
                  border: 0.1rem solid #dbdbdb;
                }

                .is-checkradio[type="radio"]:checked+label::after,
                .is-checkradio[type="radio"]:checked+label:after,
                .is-checkradio[type="checkbox"]:checked+label::after,
                .is-checkradio[type="checkbox"]:checked+label:after {
                  display: inline-block;
                }
              }
            }

            .survey_question {

              .is-checkradio[type="radio"],
              .is-checkradio[type="checkbox"] {
                outline: 0;
                user-select: none;
                display: inline-block;
                position: absolute;
                opacity: 0;
              }
            }
          }

          .questionnarieAns {
            padding: 2rem 0rem;
            margin-top: -7%;
          }

          .switch[type="checkbox"] {
            outline: 0;
            user-select: none;
            display: inline-block;
            position: absolute;
            opacity: 0;
          }

          .switch[type="checkbox"].is-small+label {
            position: relative;
            display: initial;
            font-size: 1rem;
            line-height: initial;
            padding-left: 0;
            padding-right: 3.5rem;
            cursor: pointer;
            font-weight: normal;
          }

          .switch[type="checkbox"].is-success:checked+label::before,
          .switch[type="checkbox"].is-success:checked+label:before {
            background: var(--primaryColor);
          }

          .switch[type="checkbox"].is-sucess+label::before,
          .switch[type="checkbox"].is-success+label:before {
            left: auto;
            right: -0.375rem;
          }

          .switch[type="checkbox"]+label::before,
          .switch[type="checkbox"]+label:before {
            position: absolute;
            display: block;
            top: 0;
            left: auto;
            right: 0.25rem;
            width: 3rem;
            height: 1.5rem;
            border: 0.1rem solid transparent;
            border-radius: 4px;
            background: #b5b5b5;
            content: "";
          }

          // .switch[type="checkbox"].is-success+label::after, .switch[type="checkbox"].is-success+label:after {
          //   left: auto;
          //   right: .25rem;
          // }

          .switch[type="checkbox"].is-success+label::after,
          .switch[type="checkbox"].is-success+label:after {
            display: block;
            position: absolute;
            top: 0.25rem;
            left: auto;
            right: 1.425rem;
            width: 1rem;
            height: 1rem;
            transform: translate3d(0, 0, 0);
            border-radius: 4px;
            background: var(--whiteColor);
            transition: all 0.25s ease-out;
            content: "";
          }

          .switch[type="checkbox"]:checked.is-success+label::after,
          .switch[type="checkbox"]:checked.is-success+label:after {
            left: auto;
            right: 0;
          }
        }

        .card-footer {
          .lessonPublish {
            width: 100% !important;
            margin-left: 26px;

            .responsiveRadioBtn {
              margin: 20px 0;
            }

            .switch[type="checkbox"]+label {
              position: relative;
              display: initial;
              font-size: 1rem;
              line-height: initial;
              padding-left: 0;
              padding-right: 3.5rem;
              cursor: pointer;
              font-weight: bold;
            }

            .switch[type="checkbox"]+label::before {
              position: absolute;
              display: block;
              top: 0;
              left: auto;
              right: -0.375rem;
              width: 3rem;
              height: 1.5rem;
              border: 0.1rem solid transparent;
              border-radius: 4px;
              background: #b5b5b5;
              content: "";
            }

            .switch[type="checkbox"].is-success+label:after {
              display: block;
              position: absolute;
              top: 0.25rem;
              left: auto;
              right: 1.425rem;
              width: 1rem;
              height: 1rem;
              transform: translate3d(0, 0, 0);
              border-radius: 4px;
              background: var(--whiteColor);
              transition: all 0.25s ease-out;
              content: "";
            }
          }

          .lessonSubmitBtn {
            position: absolute;
            top: 5px;
            right: 0px;
          }

          .actBtnMobileView {
            display: none;
          }
        }

        .card-header {
          .card-header-title {
            font-size: 19px !important;
          }
        }

        .buylesson {
          position: absolute;
          top: 0px;
          z-index: 9;
          height: 50px;
          display: none;
          font-size: 15px;
          bottom: 0px;
          // opacity: 0.85;
        }
      }
    }
  }

  .editedFileUpload {
    .card {
      .card-content {
        overflow-y: auto;
        max-height: calc(100vh - 253px);
      }
    }
  }
}

//Interactive video quiz style
.videoQuizTileView {
  padding: 7px;

  .flex-container {
    padding: 0px;
    margin: 0;
    list-style: none;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
    flex-flow: row wrap;

    .flex-item {
      margin: 5px;
      width: 267px;

      .card {
        background-color: white;
        color: #4a4a4a;
        max-width: 100%;
        position: relative;

        .card-header {
          padding: 5px 10px 5px 15px;
        }

        .card-header-title {
          padding: 0px !important;
        }

        button {
          height: 30px;
        }

        &:hover {
          cursor: pointer;

          .card-image {
            background-color: #010101;
            opacity: 0.7;

            .thumb_play {
              display: block !important;

              .fa {
                color: var(--whiteColor);
              }
            }
          }

          .button {
            box-shadow: none;
          }

          .shareBtnOptions {
            display: block !important;
          }

          .fileBtnOptions {
            display: block !important;
          }

          .buylesson {
            display: block !important;
          }
        }

        .card-image {
          cursor: pointer;

          .fa {
            color: #dcdcdc;
            font-size: 32px !important;
          }

          .image {
            .lesson_image {
              .thumbImg_span {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                opacity: 0.7;
              }

              .thumb_play {
                position: absolute;
                top: 48%;
                left: 45%;
                display: none;
              }
            }
          }

          .videoDuration {
            font-size: 12px;
            position: absolute;
            right: 5px;
            font-weight: 600;
            background: #333;
            color: var(--whiteColor);
            bottom: 10px;
            width: 59px;
            text-align: center;
          }
        }

        .card-content,.card-body {
          cursor: default !important;

          video {
            // height: 350px !important;
            position: relative;
            display: flex;
            width: 100% !important;
            /* height: 100%; */
            overflow: hidden;
            background-color: black;
          }

          .media {
            align-items: flex-start;
            display: flex;
            text-align: left;

            .media-content {
              flex-basis: auto;
              flex-grow: 1;
              flex-shrink: 1;
              text-align: left;
            }
          }

          .title {
            font-size: 14px !important;
            margin-bottom: 4px;
            white-space: nowrap;
            width: 121px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .fileBtnOptions {
            position: relative;
            top: -21px;
            right: -15px;
            line-height: 1;
          }

          .shared {
            margin-bottom: 2px;
            font-size: 13px;
            color: #ccc;
            text-transform: capitalize;
          }

          .subtitle {
            margin-bottom: 2px;
            font-size: 13px;
            color: #ccc;
          }

          .video {
            width: 500px;
            // border: 1px solid black;
          }

          .playpause {
            background-image: url(http://png-4.findicons.com/files/icons/2315/default_icon/256/media_play_pause_resume.png);
            background-repeat: no-repeat;
            width: 50%;
            height: 50%;
            position: absolute;
            left: 0%;
            right: 0%;
            top: 0%;
            bottom: 0%;
            margin: auto;
            background-size: contain;
            background-position: center;
          }

          .vertical {
            border-left: 3px solid #acc8e5;
            height: 100px;
            position: relative;
            left: -26px;
            top: -8px;

            &::before {
              content: "";
              position: absolute;
              width: 10px;
              height: 10px;
              background: #3b70ef;
              border-radius: 50%;
              box-shadow: 0 0 0 3px #3a70ef33;
              right: -3px;
              top: 24px;
              z-index: 1;
            }
          }

          .responsiveRadioBtn {
            margin: 20px 0;

            .switch[type="checkbox"] {
              outline: 0;
              user-select: none;
              display: inline-block;
              position: absolute;
              opacity: 0;
            }

            .switch[type="checkbox"]+label {
              position: relative;
              display: initial;
              font-size: 1rem;
              line-height: initial;
              padding-left: 3.5rem;
              padding-top: 0.2rem;
              cursor: pointer;

              &::before {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 3rem;
                height: 1.5rem;
                border: 0.1rem solid transparent;
                border-radius: 4px;
                background: #b5b5b5;
                content: "";
              }

              &::after {
                display: block;
                position: absolute;
                top: 0.25rem;
                left: 0.25rem;
                width: 1rem;
                height: 1rem;
                transform: translate3d(0, 0, 0);
                border-radius: 4px;
                background: var(--whiteColor);
                transition: all 0.25s ease-out;
                content: "";
              }
            }

            .switch[type="checkbox"].is-info:checked+label::before,
            .switch[type="checkbox"].is-info:checked+label:before {
              background: #209cee;
            }

            .switch[type="checkbox"]:checked+label::after {
              left: 1.625rem;
            }
          }

          /* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

          .history-tl-container {
            width: 80%;
            margin-left: 5rem;
            // margin: auto;
            display: block;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              top: 1px;
              left: -23px;
              width: 2px;
              height: 94%;
              background-color:var(--primarylightColor);
            }
          }

          .history-tl-container ul.tl {
            // margin: 20px 0;
            padding: 0;
            // display: inline-block;
          }

          .history-tl-container ul.tl li {
            list-style: none;
            margin: auto;
            margin-left: -14px;
            min-height: 50px;
            /* background: rgba(255,255,0,0.1); */
            border-left: 1px dashed #86d6ff;
            padding: 0 0 26px 13px;
            position: relative;
          }

          .history-tl-container ul.tl li:last-child {
            border-left: 0;
          }

          .history-tl-container ul.tl li::before {
            position: absolute;
            left: -13px;
            top: -5px;
            content: " ";
            transition: all 500ms ease-in-out;
            width: 10px;
            height: 10px;
            background: var(--primaryColor);
            border-radius: 50%;
            box-shadow: 0 0 0 3px #3a70ef33;
          }

          .history-tl-container ul.tl li:hover::before {
            border-color: #258cc7;
            transition: all 1000ms ease-in-out;
          }

          ul.tl li {
            box-sizing: content-box;
          }

          ul.tl li .item-title {
            color: #9e9e9e;
            font-weight: 600;
            display: flex;
            // float: left;
          }

          ul.tl li .editQuestion {
            margin-left: 10px;

            .fa {
              font-weight: 600;
              line-height: 23px;
              color: #ccc;

              &:hover {
                color: #3b70ef;
              }
            }
          }

          ul.tl li .editQuestDisable {
            cursor: no-drop;
            pointer-events: none;
          }

          ul.tl li .item-detail {
            color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
          }

          ul.tl li .timestamp {
            color: #8d8d8d;
            display: flex;
            position: relative;
            left: -74px;
            width: 100px;
            font-size: 14px;
            line-height: 0;
          }

          ul.tl {
            li {
              .isQuestReqClass {
                font-size: 0.8rem;
                position: absolute;
                left: 11px;
              }

              .question-title {
                padding: 0px 0px 0px 12px;
                font-size: 15px;
                font-weight: normal;
              }
            }
          }

          //SeekBar container
          #video-block {
            #video-controls {
              #seek-bar-container {
                #seek-bar {
                  .current-time {
                    position: relative;
                    left: 10px;

                    .bubles {
                      position: absolute;
                      // left: -13px;
                      top: 0px;
                      content: " ";
                      transition: all 500ms ease-in-out;
                      width: 5px;
                      height: 5px;
                      background: #3b70ef;
                      box-shadow: 0 0 0 3px #3a70ef33;
                    }
                  }
                }
              }
            }
          }
        }

        .card-footer {

          .button.is-link[disabled],
          fieldset[disabled] .button.is-link {
            background-color: #8a8989c7;
            border-color: #00000000;
            box-shadow: none;
            font-weight: 600;
            color: #000000ab !important;
          }
        }
      }
    }
  }
}

.bubles {
  position: absolute;
  // left: -13px;
  top: 0px;
  content: " ";
  transition: all 500ms ease-in-out;
  width: 5px;
  height: 5px;
  background: #3b70ef;
  box-shadow: 0 0 0 3px #3a70ef33;
}

/*
* student side video interct quiz show css
*/
.activeQuizClass {
  display: block;
}

.interactQuiz {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  // overflow-y: auto;
  background-color: #000000ad;
  z-index: 999;

  // display: none;
  .intearctQuestions {
    width: 90%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    margin-bottom: 20px;
    text-align: left;
    line-height: 2;
    font-family: "Open Sans", sans-serif;

    .card {
      .card-content {
        overflow-y: auto;
        max-height: calc(100vh - 168px);
      }

      .card-footer {
        .pagination.is-small {
          font-size: 0.75rem;
        }

        .pagination {
          .hidePreviousBtn {
            display: none;
          }

          .pagination-previous {
            margin-left: 15px;
            width: 100px;
          }

          .btnQuizisRequired {
            margin-left: 15px;
            width: 200px;
            order: 2;
            font-size: 14px;
            font-weight: 700;

            .fa {
              font-size: 10px !important;
              line-height: 2.5;
              margin-right: 5px;
              color: #ab0101;
            }
          }

          .pagination-next {
            position: absolute;
            right: 9px;
            width: 100px;
          }

          .answerSubmit {
            position: absolute;
            right: 4px;
            top: 12px;
            color: #fff;
            width: 145px;
          }
        }
      }
    }

    .lessonSubmitBtn {
      position: absolute;
      top: 5px;
      right: 0px;
    }

    .button.is-link[disabled],
    fieldset[disabled] .button.is-link {
      background-color: #8a8989c7;
      border-color: #00000000;
      box-shadow: none;
      font-weight: 600;
      color: #000000ab !important;
    }
  }
}

@-moz-document url-prefix() {
  #video-block {
    #video-controls {
      #seek-bar-container {
        #seek-bar {
          .current-time {
            position: relative;
            left: 10px;
            width: 70%;

            .bubles {
              position: absolute;
              // left: -13px;
              top: -26px;
              content: " ";
              transition: all 500ms ease-in-out;
              width: 10px;
              height: 10px;
              background: #3b70ef;
              border-radius: 50%;
              box-shadow: 0 0 0 3px #3a70ef33;
            }
          }
        }
      }
    }
  }
}

/**
* Interactive question modal window css
*/
.viewModalInterQuestion {
  .modal {
    z-index: 999;

    .modal-card {
      .modal-card-body {
        .content {
          .panel {
            border-radius: 0px !important;
          }

          label {
            .control-label {
              margin-left: 18px;
            }

            .icon-check {
              height: 1.3rem !important;
              width: 1.3rem !important;
              border: 2px solid #000;
              position: absolute;
            }

            .icon-alignuncheck {
              left: 2px !important;
              right: 0px !important;
              bottom: 0px !important;
              color: var(--whiteColor) !important;
              top: 10px;

              .fa {
                font-size: 14px !important;
              }
            }

            .icon {
              height: 1.3rem !important;
              width: 1.3rem !important;
              border: 2px solid #0066ff;
            }

            .icon-align {
              left: 2px !important;
              right: 0px !important;
              bottom: 0px !important;
              color: #0066ff !important;
              top: 10px;

              .fa {
                font-size: 14px !important;
              }
            }
          }
        }
      }

      .modal-card-foot {

        .button.is-link[disabled],
        fieldset[disabled] .button.is-link {
          background-color: #8a8989c7;
          border-color: #00000000;
          box-shadow: none;
          font-weight: 600;
          color: #000000ab !important;
        }
      }
    }
  }
}

.library-dashboard-view {
  .columns {
    margin-right: 0.5rem !important;
  }

  .box {
    margin: 20.8px 0px !important;

    .media {
      .media-content {
        .content {
          .title {
            color: #bcbcbc;
            font-size: 15px !important;
            margin-bottom: 36px;
            text-transform: uppercase;
          }

          .subtitle {
            font-weight: 600;
          }
        }

        .level {
          label {
            padding-left: 10px;
            color: var(--primaryColor);
            font-size: 13px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .flex-item {
    width: 263px;

    .card {
      .card-content {
        .title {
          font-size: 14px !important;
          margin-bottom: 4px;
          white-space: nowrap;
          width: 219px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .shared {
          margin-bottom: 2px;
          font-size: 13px;
          color: #ccc;
          text-transform: capitalize;
        }

        .subtitle {
          margin-bottom: 2px;
          font-size: 13px;
          color: #ccc;
        }

        .fileBtnOptions {
          position: relative;
          top: -21px;
          right: -15px;

          // display: none;
          .dropdown {
            .dropdown-menu {
              min-width: 6rem;

              .dropdown-item {
                .fa {
                  font-size: 12px !important;
                  margin-right: 5px;
                  margin-top: 5px;
                }
              }

              hr {
                margin: 0px;
              }
            }
          }

          .hiddenDownloadLink {
            display: none;
          }

          a {

            // color: #ccc;
            &:hover {
              color: #000;
            }
          }

          .hiddenDownloadLink {
            pointer-events: none;
          }
        }

        // .fileBtnOptions
        // .dropdown {
        //   .dropdown-menu {
        //     min-width: 6rem;
        //     .dropdown-item {
        //       .fa {
        //         font-size: 12px !important;
        //         margin-right: 5px;
        //         margin-top: 5px;
        //       }
        //     }
        //     hr {
        //       margin: 0px;
        //     }
        //   }
        // }

        // a {
        //   color: #ccc;
        //   position: relative;
        //   top: -21px;
        //   right: -15px;
        //   &:hover {
        //     color: $black-color;
        //   }
        // }
      }
    }
  }

  .dashboard-tag {
    display: flex;
    padding: 15px 0px;
    margin-top: 15px;
    position: absolute;
    right: 0px;

    span {
      margin-right: 5px;
      font-weight: 600;
    }
  }

  .dashboard-card {
    position: relative;
    top: 35px;
  }
}



/**E Library List view style css*/
.elibraryListView {
  table {
    tbody {
      tr {
        .title {
          font-size: 14px !important;
          margin-bottom: 4px;
          // white-space: nowrap;
          width: 121px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.common-empty {
  height: calc(100vh - 145px);
  min-height: 300px;
 // background-color: white;
  border-radius: 0px;
 // box-shadow: 0 0px 9px 1px rgba(62, 85, 161, 0.31), 0 0 0 1px rgba(62, 85, 161, 0);
  padding: 1.4rem 1.25rem;
  margin: 15.8px 12px;

  .media {
    align-items: center;
    display: flex;
    text-align: left;
    min-height: 200px;
    .media-content {

      // margin-top: 118px;
      p {
        color: var(--primaryColor);
        padding-top: 0px;
      }
    }
  }
}

.existing_student {
  border-bottom: 1px solid var(--menuSelectColor);
}

.is_adminUser {
  display: none;
}

.elibPlaying-Media {
  .columns {
    .column {
      .card {
        .cardcontent {
          overflow-y: auto;
          max-height: calc(100vh - 235px);
        }

        .scroll-bar::-webkit-scrollbar {
          width: 10px !important;
        }

        .notes-form {
          .notesFormHeading {
            font-weight: 600;
            display: flex;

            span {
              margin-left: 10px;

              h1 {
                font-weight: 600;
              }
            }
          }
        }

        // height: calc(100vh - 145px);
        .card-content {
          .media {
            .selectFileRemove {
              position: absolute;
              right: 30px;
              top: 10px;
            }

            .content {
              width: 100%;
            }

            .delete {
              position: absolute;
              top: 7px;
              right: 22px;
            }

            .cs-mediaplayer {
              // width: 760px;
              z-index: 1000;

              // position: absolute;
              // bottom: 13%;
              // left: 4%;
              video {
                // height: 490px !important;
                // position: relative;
                display: flex;
                width: 100% !important;
                /* height: 100%; */
                overflow: hidden;
                background-color: black;
              }

              .watermark_title {
                position: absolute;
                z-index: 9;
                // background:white;
                display: block;
                // min-height:50%;
                // min-width:50%;
                padding: 10px;
                right: 18px;

                .bg-text {
                  color: #bec8ce;
                  font-size: 20px;
                  font-weight: bold;
                  // transform:rotate(300deg);
                  // -webkit-transform:rotate(300deg);
                }
              }

              .videocover {
                background-color: #666;
                border: medium none;
                color: var(--whiteColor);
                display: block;
                font-size: 18px;
                left: 0;
                margin: 0 auto;
                padding: 13px 22px;
                position: absolute;
                right: 0;
                top: 35%;
                line-height: 1px;
                cursor: pointer;
                border-radius: 10px;

                &:hover {
                  background-color: #d62e2e;
                  cursor: pointer;
                }
              }

              vg-player {
                margin-bottom: 10px;
                height: 490px;
                // #singleVideo{
                //   max-height: 500px;
                // }
              }

              vg-overlay-play {
                z-index: 16 !important;
              }

              vg-scrub-bar {
                z-index: 0 !important;
              }

              // vg-controls{
              //   z-index: 0 !important;
              // }

              .mediaDocFile {
                .imgFigure {
                  padding: 20px;
                  margin-bottom: 10px;
                }

                .tile_imgcenter {
                  width: 40%;
                  // cursor: pointer;
                }

                .lesson_image {
                  span {
                    position: absolute;
                    top: 0;
                    background-color: #000;
                    height: 100%;
                    width: 100%;
                    opacity: 0.7;
                  }
                }

                .thumb_play {
                  position: relative;
                  top: 70%;
                  left: 44%;
                  display: block;
                  font-weight: bold;
                  width: 150px;

                  .fa {
                    // color: var(--whiteColor);
                    font-size: 32px;
                  }
                }

                &:hover {
                  background-color: #010101;
                  opacity: 0.7;
                }
              }

              .pagination {
                .is-outlined {
                  background-color: transparent;
                  border-color: var(--primaryColor);
                  color: var(--primaryColor);
                  width: 100px;

                  &:hover {
                    background-color: var(--primaryColor);
                    border-color: var(--primaryColor);
                    color: var(--whiteColor);
                  }
                }

                .disabledNextBtn {
                  cursor: no-drop;
                  pointer-events: all;
                  text-decoration: none;
                  opacity: 0.6;
                }
              }

              // &:hover{
              //   .shareBtnOptions{
              //     background-color: $secondary-color;
              //     color:$complete-white-color;
              //     width: 35px;
              //     height: 35px;
              //     border-radius: 27px;
              //   }
              //   .fileBtnOptions{
              //     background-color: $secondary-color;
              //     color:$complete-white-color;
              //     width: 35px;
              //     height: 35px;
              //     border-radius: 27px;
              //     .dropdown {
              //       .dropdown-menu {
              //         min-width: 6rem;
              //         .dropdown-item {
              //           .fa {
              //             font-size: 12px !important;
              //             margin-right: 5px;
              //             margin-top: 8px;
              //           }
              //         }
              //         hr {
              //           margin: 0px;
              //         }
              //       }
              //       .dropdown-trigger{
              //         position: relative;
              //         top: 8px;
              //         right: 5px;
              //       }
              //     }
              //   }
              // }
            }

            .title {
              font-size: 14px !important;
              margin-bottom: 4px;
              white-space: nowrap;
              // width: 121px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .descHeading {
              font-size: 18px !important;
              margin-bottom: 20px;
            }

            .shared {
              margin-bottom: 2px;
              font-size: 13px;
              color: #ccc;
              text-transform: capitalize;
            }

            .subtitle {
              margin-bottom: 2px;
              font-size: 13px;
              color: #ccc;
            }
          }
        }

        .card-table {
          .content {
            .list-overflow {
              height: calc(100vh - 161px);
              overflow-y: auto;
              overflow-x: hidden;
            }

            .list-wrapper {
              ul {
                margin-left: 0em;
                list-style: none;

                .selectedActive {
                  background-color: var(--menuSelectColor);

                  .title {
                    font-weight: bold;
                  }
                }

                li {
                  // padding: 1px;
                  border-bottom: 1px solid var(--menuSelectColor);

                  .is-checkradio[type="checkbox"].is-circle+label::before,
                  .is-checkradio[type="checkbox"].is-circle+label:before {
                    border-radius: 50%;
                  }

                  .is-checkradio[type="radio"],
                  .is-checkradio[type="checkbox"] {
                    outline: 0;
                    user-select: none;
                    display: inline-block;
                    position: absolute;
                    opacity: 0;
                  }

                  .is-checkradio[type="radio"]:checked+label::after,
                  .is-checkradio[type="radio"]:checked+label:after,
                  .is-checkradio[type="checkbox"]:checked+label::after,
                  .is-checkradio[type="checkbox"]:checked+label:after {
                    display: inline-block;
                  }

                  .is-checkradio[type="radio"]:checked+label::before,
                  .is-checkradio[type="radio"]:checked+label:before,
                  .is-checkradio[type="checkbox"]:checked+label::before,
                  .is-checkradio[type="checkbox"]:checked+label:before {
                    border: 0.1rem solid #dbdbdb;
                  }

                  .is-checkradio[type="checkbox"]+label::before,
                  .is-checkradio[type="checkbox"]+label:before {
                    width: 1rem;
                    height: 1rem;
                  }

                  .is-checkradio[type="checkbox"]+label::after,
                  .is-checkradio[type="checkbox"]+label:after {
                    width: 0.275rem;
                    height: 0.5rem;
                    top: 0.205rem;
                    left: 0.4rem;
                  }

                  .is-checkradio[type="checkbox"]+label::after,
                  .is-checkradio[type="checkbox"]+label:after {
                    box-sizing: border-box;
                    transform: translateY(0rem) rotate(45deg);
                    border-width: 0.1rem;
                    border-style: solid;
                    border-color: #00d1b2;
                    border-top: 0;
                    border-left: 0;
                  }
                }
              }
            }

            .title {
              font-size: 13px !important;
              margin-bottom: 4px;
              white-space: nowrap;
              width: 198px;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: normal;
              color: var(--primaryColor);
            }

            .shared {
              margin-bottom: 2px;
              font-size: 13px;
              color: #ccc;
              text-transform: capitalize;
            }

            .subtitle {
              margin-bottom: 2px;
              font-size: 13px;
              color: #ccc;
            }
          }
        }

        .card-header {
          height: 36px;

          .card-header-title {
            font-weight: 600;
            color: var(--primaryColor);
          }
        }
      }
    }
  }
}

.upload-section {
  height: 150px;
  border: 1px dashed #b7b4b4;
  text-align: center;
  cursor: pointer;

  p {
    padding-top: 60px;
  }
}

.group-option {
  .option-list .columns {
    margin-bottom: 0;
  }
}

.modal-card-body {
  .panel-overflow {
    // max-height: 600px;
    // height: 500px;
    height: 310px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .panel-block {
    img {
      border-radius: 50px;
    }
  }
}

.file .control.has-icons-left .icon.is-left {
  color: #7a7a7a;
  border-right: 1px solid #e6e3e3;
  cursor: pointer;
  pointer-events: auto;
}



/* ==========================================================================
   Second-level-menu - styles
========================================================================== */

.second-level-menu {
  // height: 100vh;
  height: calc(100vh - 0px);
  padding-bottom:10px;
  border-right: 1px solid #fbfbfb;
  background: var(--whiteColor);

  .tabs {
    ul {
      li {
        a {
          padding: 1em !important;
        }
      }
    }
  }
}

.se-p.is-notification,
.se-p.is-chat {
  .menu-options.level {
    padding: 11px 11px 13px;
  }

  .selectCompanyOpt {
    margin-bottom: 0em !important;
    padding: 5px 5px 5px 10px !important;

    .mdb-select {
      width: 16em;
      margin-left: 4em;

      .ng-select {
        width: 100% !important;

        .selectCompanylist {
          width: 100% !important;

          .ng-select-container {
            width: 100% !important;
          }
        }
      }
    }
  }

  .menu-options.searchBox-pad {
    padding: 9px !important;

    .fa-times-circle {
      padding: 10px;
    }
  }

  .hideNewNotify {
    display: none;
  }
}

.menu-options.level {
  padding: 10px;
  border-top: 1px solid $listing-panel-list-border;
  border-bottom: 1px solid $listing-panel-list-border;
  background: $listing-panel-bg;
  cursor: pointer;

  .input-search-box {
    position: relative;

    .input {
      border: none;
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      width: 230px;
    }

    .input-bar {
      position: absolute;
      display: block;
      width: 100%;
      bottom: 0;
      left: 5%;
    }

    .input-bar:before,
    .input-bar:after {
      position: absolute;
      content: "";
      height: 1px;
      width: 0;
      bottom: 1px;
      -webkit-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
      transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
      background: #3d4051;
    }

    .input-bar:before {
      left: 50%;
    }

    .input-bar:after {
      right: 50%;
    }

    .input:focus~.input-bar:before,
    .input:focus~.input-bar:after {
      width: 50%;
    }

    .search-return {
      right: 0;
      position: absolute;
      top: 5px;
      padding: 15px;
    }
  }

  .fa {
    vertical-align: middle;

    &:hover {
      color: $tertiary-color;
    }
  }
}

.menu-options.searchBox-pad {
  padding: 5px 10px !important;
}

.list-wrapper.list-overflow {
  height: calc(100vh - 100px);
  // height: calc(100vh - 167px);
  // height: calc(100vh - 147px);
  // height: calc(100vh - 142px);
  overflow-y: auto;
  overflow-x: hidden; // overflow-y: scroll;

  .mCSB_outside+.mCSB_scrollTools {
    right: -6px !important;
  }
}

.list-wrapper.scrollUserandGroup {
  height: calc(100vh - 157px) !important;
}

.list-wrapper.list-overflow-notification {
  // height: calc(100vh - 115px); // height: calc(100vh - 142px);
  overflow-y: auto; // overflow-y: scroll;

  .mCSB_outside+.mCSB_scrollTools {
    right: -6px !important;
  }

  .badge-count {
    top: 5px;
    bottom: initial;
  }
}

.list-wrapper {
  .list-collapse {
    padding: 5px 10px;
    border-bottom: 1px solid $listing-panel-list-border;
    cursor: pointer;
  }

  li {
    // padding: 10px 10px 2px 10px;
    padding: 6px 10px 2px 10px;
    border-bottom: 1px solid $listing-panel-list-border;

    label,
    p {
      width:150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size:.9rem !important;
      // font-weight: bold;
    }

    &.active {
      background: var(--menuSelectColor);

      .list-active {
        color: var(--primaryColor);
        // font-weight: 600;
      }
    }

    &:hover {
      background: var(--menuSelectColor);
    }

    .is-one-quarter {
      position: relative;

      img {
        border-radius: 100%;
        max-width:35px;
      }

      .icon {
        position: absolute; // bottom: 5px;
        // right: 15px;
        // bottom: 8px;
        top: 30px;
        right:15px;

        .fa {
          font-size: 12px !important;
          // font-size: 14px !important;
        }
      }
    }

    .group-img {
      .img-1 {
        position: absolute;
        border: 2px solid #dcdcdc;
      }

      .img-2 {
        position: absolute;
        left: 6%;
        border: 2px solid #dcdcdc;
      }

      .img-3 {
        position: absolute;
        left: 14%;
        border: 2px solid #dcdcdc;
      }
    }

    label {
      max-width: 125px;
      width: 124px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block; // color: #c7c6c6;
      max-height: 20px;

      .icon {
        font-size: 12px;
      }
    }

    label.chat-msg-text {
      font-size: 13px;
      color: #908c8c;

      .emoji-pipe-image {
        width: 20px !important;
        height: 20px !important;
        display: inline-table !important;
      }

      i.fa {
        font-size: 14px !important;
      }

      pre {
        font-size: 14px !important;
        padding: 0px !important;
      }
    }

    .unReadMsgTxt {
      font-weight: bold;
      color: #010101 !important;
    }

    .show-less {
      height: 4rem;
      overflow: hidden;
      padding: 1rem;
    }

    .chat-close {
      i.fa {
        padding: 0px 5px;
        display: none;
        color: #b1b1b1;
        position: absolute;
        top: 22px;
        right: 7px;
        cursor: pointer !important;

        &:hover {
          color: $listing-panel-pri-text-color;
        }
      }
    }

    .group-identify {
      position: absolute;
      right: 0;
      top:18px;
    }
    .icon{
      display: none;
    }
  }

  li.cursor-pointer.active {
    cursor: default;

    // *:hover {
    //   cursor: default;
    // }
    .icon{
      cursor: pointer !important;
      display: block;
    }
  }

  .chat-list {
    .columns.is-gapless:not(:last-child) {
      margin-bottom: 0 !important;
    }

    // .new-reply {
    //   span {
    //     padding: 0px 5px;
    //     margin-right: 5px;
    //     background: $listing-panel-badge-bg;
    //     color: $listing-panel-badge-text-color;
    //     border-radius: 100%;
    //     position: absolute;
    //     bottom: 25%;
    //     right: 0;
    //   }
    //   i.fa {
    //     &:hover {
    //       color: #288ecd;
    //     }
    //   }
    // }
  }

  li:hover .chat-close i.fa {
    display: block;
  }

  li:hover .display-n {
    display: inline-block;
  }
}

.list-wrapper.list-overflow {
  li {
    padding: 0px;

    .columns {
      padding: 6px 10px 6px 10px;
    }
  }
}

.list-wrapper.list-overflow-notification {
  li {
    padding: 0px;

    .columns {
      padding: 6px 10px 2px 10px;
    }
  }
}

.list-wrapper.file-list-sec {
  li {
    padding: 6px 10px 2px 10px;
  }
}

.hideInactiveGroup {
  display: none !important;
}

/* ==========================================================================
   Empty state- styles
========================================================================== */

.empty-state {
  width: 100%; // margin-top: 10%;

  .box {
    height: 100%;
    width: 100%;
    background: #fff;
    padding: 15px;
    padding-top: 10%;
    margin: 0 auto;
    border-radius: 0;

    img {
      width: 248px;
      height: auto;
    }

    p {
      width: 80%;
      margin: 0 auto;
      padding-top: 25px;
      font-weight: 600;
      font-size: 18px;
    }
  }
}

/*=========================================================================
  Empty Staff room or Study room state -styles
===========================================================================*/

.topic-notify {
  img {
    width: 248px;
    height: auto;
  }

  p {
    width: 80%;
    margin: 0 auto;
    padding-top: 25px;
    font-weight: 600;
    font-size: 18px;
  }
}

/* ==========================================================================
   Chat details view - styles
========================================================================== */

.chat-detail-bg {
  background: var(--bodyBgColor);
  // border-left: 1px solid #dcdcdc;
}

.chats {
  .msg-list-view {
    margin: 0 20px;

    .media {
      border:1px solid #c9c9ca;
      padding: 12px 10px;
      margin: 0px;
      position: relative;
      background-color: var(--whiteColor);
     box-shadow: 0 0px 9px 1px rgba(62, 85, 161, 0.31), 0 0 0 1px rgba(62, 85, 161, 0);
      figure {
        margin: 0 1rem 0 0;

        .image {
          height: 36px;
          width: 36px;
        }
      }

      .msg-content {
        strong {
          color: var(--primaryColor);
          font-size: 14px;
        }

        small {
          font-size: 14px;
          display: flex;
        }

        span {
          font-size: 14px;
        }

        .msgShowListView {
          word-break: break-word;
          white-space: pre-wrap;

          pre {
            // background-color: whitesmoke !important;
            background-color: #dcdcdc !important;
            font-size: 13.5px !important;

            code {
              // font-family: 'Noto Sans', sans-serif;
              font-weight: inherit;
            }
          }

          .emoji-pipe-image {
            display: inline-table !important;
          }

          .msgHighlight {
            color: #010101 !important;
          }
        }
      }

      .message-update {

        // position: absolute;
        // bottom: 0;
        // right: 5px;
        .fa {
          font-size: 11px !important;
          color: #b7b4b4;
          letter-spacing: -7px;
          margin-top: 8px;
          padding-left: 6px;
        }

        .read-receipt-block {
          &:hover {
            position: relative;
          }

          &:hover::after {
            content: attr(data-title);
            padding: 2px 13px;
            position: absolute;
            left: 0;
            top: 100%;
            white-space: nowrap;
            z-index: 20px;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border-radius: 5px;
            -moz-box-shadow: 0px 0px 4px #222;
            -webkit-box-shadow: 0px 0px 4px #222;
            box-shadow: 0px 0px 4px #222;
            background-color: #252423;
            color: #d4dbf2;
            font-size: 0.75em;
          }
        }

        .read {
          color: var(--primaryColor) !important;
        }
      }

      .message-reply {
        position: absolute;
        left: 50%;

        &:hover {
          color: var(--primaryColor);

          .fa {
            &:hover {
              color: var(--primaryColor);
            }
          }
        }

        .fa.fa-angle-down {
          font-size: 23px !important;
          color: #b7b4b4;
          cursor: pointer;
          font-weight: bold;

          &:hover {
            color: var(--primaryColor);
          }
        }

        .dropdown-menu {
          position: initial;

          a.dropdown-item {
            color: #b7b4b4;

            &:hover {
              color: #2f2e2e;
            }
          }
        }
      }
    }

    .chat-file-type {
      .chat-msg-image {
        //  width: 250px;
        width: 100%;
        position: relative;

        img {
          box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
            0 0 0 2px rgba(10, 10, 10, 0.1);
        }

        .image-hover {
          width: 100%;
          left: 0;
          bottom: 6px;

          .image-download {
            bottom: 0px;
          }
        }
      }

      .audio-file {
        max-width: 300px;
        min-width: 155px;
        margin-bottom: 5px;

        .media {
          border-bottom: none;
          padding: 0px;
          margin: 0px;

          .media-content {
            overflow-x: hidden;

            strong {
              text-transform: capitalize;
            }

            .audio-download {
              bottom: 5px;
            }
          }
        }
      }
    }

    .chat-file-type {
      .chat-msg-image {
        .image-hover {
          top: 120px;
          background: #000;
          color: var(--whiteColor);
        }
      }
    }
  }

  .msgShowHide {
    font-size: 13px;
    font-weight: 700;
  }

  .show-less {
    // height: 4rem;
    overflow: hidden;
  }
}

.msg-z-view {
  position: relative;

  .popover {
    z-index: 999999;
    // display: block;
    visibility: hidden;
    background: #7a7a7a;
    border: 0;
    border-radius: 0.3rem;
    box-shadow: 0 0.2rem 1.6rem 0 rgba(37, 36, 35, 0.3);
    font-size: 1.4rem;
    padding: 0;
    position: absolute;
    top: -0.5em;
    left: auto;

    .fa {
      color: #ccc;
      cursor: pointer;
      font-size: 1rem !important;
      text-align: center;

      &:hover {
        color: var(--whiteColor);
      }

      &::before {
        // content: "\e610";
        // color:#f16822;
        text-align: center;
        display: block;
        width: 1.5em;
      }
    }

    span {
      padding: 6px 12px 6px 12px;
      position: relative;
      top: 0.5em;
    }

    .read-receipt-block {
      &:hover {
        position: relative;
      }

      &:hover::after {
        content: attr(data-title);
        padding: 2px 13px;
        position: absolute;
        left: 0;
        top: 100%;
        white-space: nowrap;
        z-index: 20px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        // -moz-box-shadow: 0px 0px 4px #222;
        // -webkit-box-shadow: 0px 0px 4px #222;
        // box-shadow: 0px 0px 4px #222;
        background-color: #252423;
        color: #d4dbf2;
        font-size: 0.45em;
      }
    }
  }

  .top-right {
    right: 7em;
  }

  .top-left {
    left: 8em;
  }

  // .is-active{
  //   visibility: visible;
  // }
  .message-actions-popover-container {
    box-shadow: 0 0.4rem 0.7rem -0.1rem rgba(0, 0, 0, 0.1);
    width: 3rem;
    height: 2.2rem;
  }

  .message-update {

    // position: absolute;
    // bottom: 0;
    // right: 5px;
    .fa {
      font-size: 10px !important;
      color: #b7b4b4;
      letter-spacing: -5px;
      margin-top: 7px;
      padding-left: 0.225rem;
    }

    .read-receipt-block {
      &:hover {
        position: relative;
      }

      &:hover::after {
        content: attr(data-title);
        padding: 2px 13px;
        position: absolute;
        left: 0;
        top: 100%;
        white-space: nowrap;
        z-index: 20px;
        // -moz-border-radius: 5px;
        // -webkit-border-radius: 5px;
        // border-radius: 5px;
        // -moz-box-shadow: 0px 0px 4px #222;
        // -webkit-box-shadow: 0px 0px 4px #222;
        // box-shadow: 0px 0px 4px #222;
        background-color: #252423;
        color: #d4dbf2;
        font-size: 0.75em;
      }
    }

    .read {
      color: var(--primaryColor) !important;
    }
  }
}

/* ==========================================================================
   User-level-menu - styles
========================================================================== */

.table {
  tbody {
    td {
      i.fa {
        font-size: 14px;
        padding: 2px 5px;
        cursor: pointer;

        &:hover {
          color: #288ecd;
        }
      }
    }
  }
}

body {
  position: relative !important;

  .intro-tour-hint-wrapper {
    transform: scale(0);
    animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    animation-fill-mode: both;
    color: #2f2e2e;
    max-width: 340px;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.42857143;
    white-space: normal;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    line-break: auto;
    background: #3a7bd5;
    background: linear-gradient(180deg,
        rgba(0, 210, 255, 1) 0%,
        rgba(58, 123, 213, 1) 100%);

    .header {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      color: var(--whiteColor);
      // background-color: var(--primaryColor);
      background: transparent;
      box-shadow: none;
      font-size: 24px;
      font-weight: bold;
      text-align: left;
      padding-bottom: 0;
      border-bottom: none;
      box-shadow: none;
    }

    .content {
      text-align: left;
      padding-top: 5px;
      margin-bottom: 0px;

      p {
        color: var(--whiteColor);
        font-weight: bold;
      }
    }

    .footer {
      // border-top: 1px solid #d0d0d0;
      background-color: transparent;

      .navigate-btn {
        display: inline-flex;
        -ms-flex-pack: start;
        color: #5b86e5;
        cursor: pointer;
        justify-content: center;
        padding-left: 0.75em;
        padding-right: 0.75em;
        text-align: center;
        white-space: nowrap;
        background-color: var(--whiteColor);
        box-shadow: none;
        border-radius: 2px;
        height: 30px;
        width: 45px;
        margin-left: 7px;
        font-size: 18px;
        line-height: 30px;
        text-decoration: none;
      }
    }
  }

  .ng-tooltip {
    position: absolute;
    max-width: 150px;
    font-size: 12px;
    text-align: center;
    color: #f8f8f2;
    padding: 5px 20px;
    background: var(--primaryColor) !important;
    border-radius: 0;
    z-index: 1000;
    opacity: 0;
  }

  .ng-tooltip:after {
    content: "";
    position: absolute;
    border-style: solid;
  }

  .ng-tooltip-top:after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: var(--primaryColor) transparent transparent transparent !important;
  }

  .ng-tooltip-bottom:after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: transparent transparent var(--primaryColor) transparent !important;
  }

  .ng-tooltip-left:after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent transparent transparent var(--primaryColor) !important;
  }

  .ng-tooltip-right:after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent var(--primaryColor) transparent transparent !important;
  }

  .ng-tooltip-show {
    opacity: 1;
  }
}

@keyframes blowUpModal {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes blowUpModalTwo {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.welcome-window {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;

  .modal-content {
    overflow-x: hidden;

    .box {

      // -webkit-animation-duration: 0.5s;
      // animation-duration: 0.5s;
      // -webkit-animation-fill-mode: both;
      // animation-fill-mode: both;
      // animation-name: fadeInDown;
      h2 {
        font-size: 26px;
        font-weight: 600;
      }

      h4 {
        font-size: 18px;
        margin: 5px;
      }

      p {
        font-size: 14px;
      }

      img {
        // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
        max-height: 300px;
      }

      a.button {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* ==========================================================================
   Notification Container page - styles
========================================================================== */

/*width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    background: #ccc;
    border-radius: 100%;*/

.notification-content-wrapper {

  .file.is-link .file-cta{
    background-color: var(--primaryColor) !important;
  }
  header {
    height: 56px;
    padding: 5px 10px 5px 5px;
    z-index: 19;

    .columns {
      width: 100%;
      margin-bottom: 0px !important;
    }

    h3 {
      span {
        font-weight: 600;
      }
    }

    figure {
      max-width: 78px;

      i {
        line-height: 54px;
        padding-left: 4px;
        padding-right: 10px;
        font-size: 14px !important;
        cursor: pointer;
      }

      img {
        width: 48px;
        border-radius: 100%;
      }
    }

    .desk-view {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      & a.desk-icon {
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      .mobileViewBtn {
        display: none;
      }
    }

    .has-text-right {
      a.desk-icon {
        @extend .display-ib;
        @extend .pad-5-10;
        @extend .pos-relative;

        i {
          vertical-align: middle;
        }

        .is-info {
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
          z-index: 1;
        }
      }

      .dropdown {
        display: none;
      }

      .userMobileView {
        .isBtnMobileView {
          display: none;
        }
      }

      .desk-view {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;

        & a.desk-icon {
          height: 30px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }

        .mobileViewBtn {
          display: none;
        }
      }
    }

    .header-elibrary {
      .actionBtnDeskView {
        border-style: initial;
      }

      .actionBtnDeskandMobView {
        border-style: initial;
        margin-right: 10px;
      }

      .actionBtnMobileView {
        display: none;
        border-style: initial;
        margin-right: 10px;
      }
    }
  }

  .studyRoomList {
    .activeMobileView {
      display: none;
    }
  }

  .studyroomListView {
    font-size: 14px;

    .button {
      border-radius: 30px;
      width: 31px;
      height: 31px;
      border: none;

      .fa {
        font-size: 15px !important;

        &:hover {
          color: var(--whiteColor);
        }
      }

      &:hover {
        background-color: var(--primaryColor);
        color: #fff !important;
      }
    }
  }

  .contents {
    .box {
      // margin: 5px 30px;
      // border-radius: 0px;
      // box-shadow: 0 0px 9px 1px rgba(10, 10, 10, .2), 0 0 0 1px rgba(10, 10, 10, .1);
      // padding: .5rem 1.25rem;
      margin: 20px;
      border-radius: 0px;
      box-shadow: 0 0px 9px 1px rgba(62, 85, 161, 0.31),
        0 0 0 1px rgba(62, 85, 161, 0);
      padding: 1.4rem 1.25rem;

      .column.text-bar {
        padding-left: 0;
      }

      .image img {
        max-width: 40px; // max-width: 48px;
        height: auto;
        margin-top: 6px;
        border-radius: 100%;
      }
    }

    .box:last-child {
      margin-bottom: 40px;
    }
  }

  .reply {
    i.fa {
      font-size: 12px !important;
      vertical-align: middle;
      color: var(--primaryColor);
    }
  }

  .notifications {
    .media {
      .media-content {
        .content {
          .post-detail {
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.15px;

            .creater-name {
              color: var(--primaryColor);
            }

            .sub-post-content {
              color: #363636;
            }
          }
        }
      }
    }

    .media .media {
      padding-top: 5px;
      padding-bottom: 7px;

      .cmt-u-name {
        font-size: 12px;
      }

      .timeago {
        font-size: 12px;
      }

      .media-content {
        .content {
          .cmt-text {
            line-height: 18px;

            .sub-cmt-link {
              padding-left: 12px;
              font-size: 12px;
            }
          }
        }
      }
    }

    .media+.media {
      margin-top: 0;
      padding-top: 5px;
    }
  }

  .post-attachment {
    .post-img {
      position: relative;
      max-width: 300px;
      height: auto;
      margin: 5px 0px;
      overflow: hidden;

      // box-shadow: 0 8px 10px 1px rgba(143, 158, 181, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(143, 158, 181, 0.2);
      img {
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
          0 0 0 1px rgba(10, 10, 10, 0.1);
      }

      i.fa-play-circle {
        @extend .pos-absolute;
        top: 0%;
        background: #000;
        width: 100%;
        opacity: 0.5;
        padding: 30%;
        color: var(--whiteColor);
        cursor: pointer;
        font-size: 24px !important;
      }

      .fa-times {
        position: absolute;
        right: 0px;
        top: 0;
        background: #000;
        color: var(--whiteColor);
        padding: 10px 15px;
        opacity: 0.5;
        cursor: pointer;
        display: none;
      }
    }

    .post-img.notify-play {
      max-width: 350px;

      &:hover {
        .fa-times {
          display: block;
        }
      }
    }
  }

  .btnDeskIcon {
    width: 240px;
  }

  .bulkuplTableView {
    font-size: 14px !important;
  }

  // .library_tile_view{
  //   .flex-item{
  //     width: 327px;
  //   }
  // }

  .topicDetailsView {
    .has-text-right {
      .desk-view {
        float: right;

        .btnDeskView {
          border-style: initial;
        }

        .btnMobileView {
          display: none;
        }
      }
    }
  }

  
  
}

.post-attach-file {
  .profile-img {
    width: 250px;
    height: 150px;
    margin: 0;
    padding-left: 10px;

    img {
      border-radius: 0;
      min-width: 250px;
      max-width: 250px;
      max-height: 160px;
    }
  }

  .profile-img.small-img {
    width: 100%;
    height: 100%;

    img {
      border-radius: 0;
      min-width: 35px;
      max-width: 35px;
      max-height: 100%;
    }
  }

  .groupUserList {
    &:hover {
      .delete-user {
        display: block;
      }
    }
  }

  .activeGroupUser {
    .delete-user {
      padding: 0px 5px;
      display: none;
      color: #b1b1b1;
      position: absolute;
      // top: 22px;
      right: 15px;
      bottom: 15px;
      cursor: pointer !important;

      &:hover {
        color: var(--primaryColor);
      }
    }
  }
}

.textbar-mobile {
  display: none;
}

.chat-user-status {
  position: relative;

  .icon {
    position: absolute;
    top: 30px;
    right: -2px;

    i.fa {
      font-size: 12px !important;
    }
  }
}

.notification-content-wrapper+.fo-p {
  max-width: 340px;
  position: relative;

  >div {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 10;
    box-sizing: border-box;
    border-left: 1px solid rgba(0, 0, 0, 0.03);
    transition: background 0.3s ease-out 0.1s;
    width: 100%;
    height: 100%;
    position: absolute;
    background: $info-panel-bg;
  }
}

.help-page {
  .help-head-name {
    img {
      margin-right: 5px;
      max-width: 36px;
      border-radius: 100%;
    }

    h3 {
      margin-top: 5px !important;
      font-size: 20px;
    }
  }

  .help-content {
    .box {
      padding: 15px 10px;
      border-radius: 3px;

      .help-list {
        border-right: 1px solid #e8e8e8;

        ul {
          min-width: 180px;

          li {
            a {
              padding: 5px;
              font-size: 15px;
              display: block;
            }

            a.active {
              color: var(--primaryColor);
              font-weight: 600;
            }
          }
        }
      }

      .help-details {
        h3 {
          border-bottom: 1px solid #e4e4e4;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.topicListView {
  .table {
    font-size: 14px;

    td {
      .list-ellipsis {
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .accordions {
        margin-top: 20px;

        .accordion {
          .accordion-header {
            background: #fff !important;
            color: var(--primaryColor) !important;
            font-weight: 600;
          }
        }
      }

      .button {
        border-radius: 30px;
        width: 31px;
        height: 31px;
        border: none;
      }
    }

    tbody {
      .is-info {
        color: var(--whiteColor);
        background-color: var(--primaryColor);
      }

      .feedbackDetails {
        .feedSub {
          background-color: var(--primaryColor);
          color: #fff !important;

          .fa {
            cursor: no-drop !important;
          }
        }
      }

      tr {
        td {
          .actionBtnDeskView {
            .dropdown-item {
              display: contents !important;
            }
          }

          .actionBtnMobileView {
            display: none;

            .dropdown-item {
              span {
                display: none;
              }
            }
          }

          a {
            .is-info {
              text-decoration: none;
              margin-top: 4px;
              font-weight: 700;
            }
          }

          .isPublished {
            color: var(--whiteColor);
            background-color: #8896c5;
            text-align: left;

            i {
              cursor: not-allowed;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.header-elibrary {

  // margin-top: 8px;
  .tabs {
    display: inline-block;
    margin: 0 !important;
    margin-right: 10px !important;
    overflow-x: hidden;
  }

  .upload {
    margin-right: 12px;

    .button {
      padding-left: 1.5em;
      padding-right: 1.5em;

      .fa {
        font-size: 14px !important;
        margin-right: 8px;
      }
    }
  }

  .hideDashboard {
    border-style: initial;
    margin-right: 13px;
  }
}

.e-library-modal {
  .footer-elibrary {
    .button {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }

    .fa {
      font-size: 14px !important;
      margin-right: 8px;
    }
  }
}

//Privacy new page css modified
.privacy-page {
  .privacy-head-name {
    img {
      margin-right: 5px;
      max-width: 36px;
      border-radius: 100%;
    }

    h3 {
      margin-top: 5px !important;
      font-size: 20px;
    }
  }

  .privacy-content {
    .box {
      padding: 15px 10px;
      border-radius: 3px;

      .privacy-list {
        border-right: 1px solid #e8e8e8;

        ul {
          min-width: 180px;

          li {
            a {
              padding: 5px;
              font-size: 15px;
              display: block;
            }

            a.active {
              color: var(--primaryColor);
              font-weight: 600;
            }
          }
        }
      }

      .privacy-details {
        h3 {
          border-bottom: 1px solid #e4e4e4;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.fo-p {
  .list-wrapper.list-overflow {
    height: calc(100vh - 150px);
  }

  .user-info-list {
    height: calc(100vh - 310px);
    overflow-y: auto;
  }

  li {
    label {
      line-height: 20px;
    }

    .is-one-quarter {
      img {
        max-width: 48px;
      }

      .icon {
        bottom: 5px;
        right:15px;

        .fa {
          // font-size: 14px !important;
          font-size: 12px !important;
        }
      }
    }

    .columns.is-gapless {
      margin-bottom: 0 !important;
    }
  }

  .group-members {
    li {
      .is-pulled-left {
        .time-ago {
          font-size: 0.6rem;
          cursor: default;
        }
      }

      .chat-close {
        .fa {
          top: 32px;
        }
      }
    }
  }

  .chat-check {
    .fa {
      padding: 0px 5px;
      color: #565c78;
      position: absolute;
      top: 25px;
      right: 7px;
      cursor: pointer !important;
    }
  }

  .fourth-panel-tab {
    margin-bottom: 0;
    padding-top: 10px;

    ul {
      li {
        a {
          padding: 0.7em 1em;

          &:hover {
            color: var(--primaryColor) !important;
            border-bottom-color: var(--primaryColor) !important;
          }
        }
      }

      li.is-active {
        a {
          color: var(--primaryColor) !important;
          border-bottom-color: var(--primaryColor) !important;
        }
      }
    }
  }

  .showAssignment {
    .modal {
      .modal-background {
        background-color: #0a0a0a85 !important;
      }

      .modal-card {
        .modal-card-body {
          .content {
            .isSelectHightlight {
              background-color: #c2dbff;
              color: #131313;
            }

            .selQtHighlight {
              background-color: #c2dbff;
            }
          }
        }
      }
    }

    .watermark_title {
      position: absolute;
      z-index: 9999;
      // background:white;
      display: block;
      min-height: 50%;
      min-width: 50%;

      // color:yellow;
      .bg-text {
        color: #bec8ce;
        font-size: 120px;
        // font-weight: bold;
        transform: rotate(300deg);
        -webkit-transform: rotate(300deg);
      }
    }

    .is-modal-active {
      visibility: visible;
      display: flex;
    }

    .is-topic-modal {
      display: flex;
      visibility: visible;
    }

    .card-header {
      background-color: var(--primaryColor);

      .card-header-title {
        color: $complete-white-color;
      }

      .delete {
        position: relative;
        top: 8px;
      }
    }

    .card {
      .assQuestTest {
        .scroll-bar::-webkit-scrollbar {
          width: 10px !important;
        }
      }
    }

    .card-content {
      overflow-y: auto;
      max-height: calc(100vh - 168px);

      .content {
        .ngx-datepicker-calendar-container {
          z-index: 9 !important;
        }

        .ngx-datepicker-input {
          padding: 10px 10px !important;
          width: 271px !important;
          border-radius: 4px;
        }

        // .newTopicAdd{
        //   [data-tooltip]:not(.is-disabled)::before,
        //   [data-tooltip]:not(.is-loading)::before,
        //   [data-tooltip]:not([disabled])::before{
        //     white-space: pre-wrap !important;
        //     left: 19% !important;
        //     width: 495px !important;
        //   }
        // }
        .question {
          ul {
            margin-left: 1rem;

            .form-section {
              // padding: 0px 0px 0px 0px;
              list-style: none !important;
              list-style-position: outside;
              margin: 0px;
              padding: 0px;
              position: relative;
              zoom: 1;
              clear: both;
            }
          }

          .form-line[data-type="control_radio"],
          .form-line[data-type="control_checkbox"] {
            display: flex;
            flex-wrap: wrap;
          }

          .form-line[data-type="control_radio"] .form-input-wide,
          .form-line[data-type="control_checkbox"] .form-input-wide {
            width: 100%;
            line-height: 2;

            .form-single-column {
              .form-radio-item {
                .field {
                  label {
                    color: #ccc;

                    &:hover {
                      color: var(--primaryColor);
                    }
                  }

                  .is-checkradio[type="radio"]:checked {
                    label {
                      color: var(--primaryColor);
                    }
                  }
                }
              }
            }
          }

          .form-line {
            // padding: 12px 36px;
            margin-top: 12px;
            margin-bottom: 12px;

            .form-label {
              .form-label-auto {
                display: block;
                float: none;
                word-break: break-word;
                text-align: left;
              }
            }
          }
        }

        .progress-wrapper {
          position: relative;
          margin: 20px auto;
          font-size: 40px;

          .currentResult {
            position: absolute;
            color: #bbb;
            font-weight: 100;
            line-height: 1;
          }
        }
      }

      .surveyquiz {
        line-height: 2.5;
      }
    }

    .hidePreviousBtn {
      display: none;
    }

    .card-footer {

      // position: relative;
      // right: -60%;
      .footer-elibrary {
        padding: 13px;
        position: absolute;
        right: 9px;
      }

      .taskSubmit {
        margin-right: 10px;
      }

      .pagination {

        // position: absolute;
        .is-outlined {
          background-color: transparent;
          border-color: var(--primaryColor);
          color: var(--primaryColor);

          &:hover {
            background-color: var(--primaryColor);
            border-color: var(--primaryColor);
            color: var(--whiteColor);
          }
        }

        .pagination-next {
          // float: right;
          position: absolute;
          right: 9px;
          width: 100px;
        }

        .pagination-previous {
          margin-left: 15px;
          width: 100px;
          // position: relative;
          // right: 119%;
        }

        .btnQuizisRequired {
          margin-left: 15px;
          width: 200px;

          .fa {
            color: #d62e2e;
          }
        }

        .hidePreviousBtn {
          display: none;
        }

        .isHiddenBtn {
          display: none;
        }

        .isShowedBtn {
          display: block !important;
        }

        .answerSubmit {
          position: absolute;
          right: 4px;
          top: 12px;
          color: $complete-white-color;
        }
      }
    }
  }

  .file-list-sec {
    ul {
      li {

        // padding: 10px;
        .file-list {

          // display: flex;
          // line-height: 14px;
          .file-type {
            i.fa {
              font-size: 14px !important;
              padding-right: 10px;
              padding-top: 5px;
            }
          }

          .file-name-list {
            font-size: 14px;
            max-width: 240px;
            width: 220px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
          }
        }

        .file-download {
          i.fa {
            font-size: 14px !important;
            position: absolute;
            right: 0;
            top: 6px;
            padding: 5px 10px;
            color: #d3d3d3;
            cursor: pointer;
          }
        }

        .file-date {
          margin-top: 5px;
          display: inline-block;
          padding-left: 30px;
        }

        .text-clr-red {
          float: right;
          color: brown;
          text-transform: capitalize;
        }

        .file-size {
          font-size: 12px !important;
          position: absolute;
          right: 0;
          bottom: 0px;
          padding: 5px 10px;
          display: none;
        }
      }

      li {
        &:hover {

          // background: transparent;
          .file-download {
            i.fa {
              color: #2f2e2e;
            }
          }

          .file-size {
            display: block;
          }
        }
      }
    }
  }
}

.notific-user {
  li {
    padding: 10px;

    .notfic-resend-btn {
      margin-left: 10px;
    }
  }
}

.group-details {
  .activeGroupUser {
    .field {
      height: calc(100vh - 480px);
      overflow-y: auto;
      min-height: 150px !important;
      border-left: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }

    .delete-user {
      padding: 0px 5px;
      display: none;
      color: #b1b1b1;
      position: absolute;
      // top: 22px;
      right: 15px;
      bottom: 15px;
      cursor: pointer !important;

      &:hover {
        color: var(--primaryColor);
      }
    }

    .groupUserList {
      &:hover {
        .delete-user {
          display: block;
        }
      }
    }
  }
}

.group-details app-new-group {
  // height: calc(100vh - 117px);
  height: calc(100vh - 122px);
}

.loader-progress {
  width: 110px;
  position: absolute;
  bottom: 5%;
  left: 1%;
  text-align: center;

  .percent-value {
    font-weight: bold;
    color: var(--whiteColor);
  }

  .progress.is-info::-webkit-progress-value {
    background-color: var(--primaryColor) !important;
  }

  .progress {
    height: 8px;
  }
}

.custom-search {
  position: relative !important;

  .control.has-icons-left .input {
    padding-left: 5em;
    padding-right: 4em;
  }

  .mic-icon {
    position: absolute;
    left: 36px;
    top: 1px;
    padding: 8px 10px 8px;
    background: var(--whiteColor);
    border-right: 1px solid #e6e3e3;
    border-radius: 1px;
  }
}

.app-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #303030;
  color: var(--whiteColor);
  font-size: 12px;
  padding: 2px 10px;
  text-align: right;
  z-index: 10;
}

.file-links {
  .linkified {
    color: var(--primaryColor) !important;
  }
}

.create-upload {
  .file-label {
    font-size: 14px;
  }

  .file-icon .fa {
    font-size: 14px !important;
  }

  .file .file-cta {
    background-color: var(--primaryColor) !important;
    color: $complete-white-color;
  }

  .dz-default {
    display: inline-block;
  }

  .pos-relative {
    .field {
      display: inline-block;

      .file {
        bottom: 6px;
        position: absolute;
      }
    }
  }
}

.badge-count {
  border-radius: 100px;
  background-color: $listing-panel-badge-bg;
  color: $listing-panel-badge-text-color;
  height: auto !important;
  padding: 1px 8px;
  font-size: 10px;
  position: absolute;
  bottom: 5px;
  right: 0;
}

.time-ago {
  position: absolute;
  right: 0;
  top: 0;
}

.opacity-bg {
  background: #8da0b3 !important;
}

.opacity-3 {
  opacity: 0.3;
}

.create-icon {
  .fa-trash {
    // position: absolute;
    // right: 0;
    // display: none;
    // padding: 5px 7px;
    // cursor: pointer;
    position: absolute;
    right: -27px;
    display: none;
    font-size: 18px !important;
    bottom: -6px;
    padding: 5px 7px;
    cursor: pointer;
    color: #c1c1c1;
  }

  img {
    width: 90px;
    height: 80px;
  }
}

.create-icon:hover .fa-trash {
  display: block;
}

.file-upload {
  position: absolute; // position: fixed;
  bottom: 60px;
  // bottom: 75px;
  right: 87px;
  width: 350px;
  background: var(--whiteColor); // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  // padding: 6px 10px;
  z-index: 10000;
  font-size: 14px;

  .accordion {
    .accordion-header {
      background: #6b6b6b;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .accordion-content {
      background: var(--whiteColor);
    }
  }

  .accordion:hover .accordion-header {
    opacity: 1;
  }

  .pos-relative {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dcdcdc;
  }

  .pos-relative:last-of-type {
    margin-bottom: 0px;
    padding-bottom: 0;
    border: none;
  }

  .file-detail {
    margin-right: 30px;
    position: absolute;
    top: 0px;
    left: 55px;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 200px;
      overflow: hidden;
    }

    .file-size {
      font-size: 12px;
    }

    .content-align {
      position: relative;

      .progress {
        height: 0.25rem;
        position: absolute;
        bottom: 10px;
        right: 5px;
        width: 68%;
      }
    }
  }

  .fa-times {
    position: absolute;
    right: -10px;
    top: 5%;
    padding: 15px;
    cursor: pointer;
  }
}

.profile-img {
  position: relative;
  width: 120px;
  margin: 5px auto;

  img {
    max-width: 100px;
    min-width: 100px; // max-width: 120px;
    border-radius: 100%; // border: 3px solid #dcdcdc;
    // padding: 3px;
    box-shadow: 0 8px 10px 1px rgba(143, 158, 181, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12),
      0 5px 5px -3px rgba(143, 158, 181, 0.2);
    cursor: pointer;
  }

  .img-options {
    position: absolute;
    bottom: 10px;
    right: 10px; // padding: 5px 10px;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 3px;
    border-radius: 100%;
    background: var(--primaryColor);
    cursor: pointer;

    .fa {
      color: var(--whiteColor) !important;
      padding: 6px;
      font-size: 12px !important;
    }

    .file-label {
      font-size: 14px;
      // padding-left: 7px;
      white-space: nowrap;
    }

    // .dropdown .dropdown-menu{
    //     width: 320px;
    // }
    // .dropdown .dropdown-menu .dropdown-content {
    //   -webkit-transition: 3s ease-out;
    //   -moz-transition: 3s ease-out;
    //   -o-transition: 3s ease-out;
    //   -ms-transition: 3s ease-out;
    //   transition: 3s ease-out; //width: 50px;
    // }
  }

  // .img-options .dropdown-menu .dropdown-content.drop-width{
  //     width: 300px;
  // }
}

.institute-logo {
  position: relative;
  width: 255px;
  margin: 5px auto;

  img {
    // max-width: 100px;
    // min-width: 100px; // max-width: 120px;
    // border-radius: 100%; // border: 3px solid #dcdcdc;
    // padding: 3px;
    box-shadow: 0 8px 10px 1px rgba(143, 158, 181, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12),
      0 5px 5px -3px rgba(143, 158, 181, 0.2);
    cursor: pointer;
    min-width: 250px;
    max-width: 250px;
    max-height: 160px;
  }

  .img-options {
    position: absolute;
    bottom: 20px;
    right: 20px; // padding: 5px 10px;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 3px;
    border-radius: 100%;
    background: var(--primaryColor);
    cursor: pointer;

    .fa {
      color: var(--whiteColor) !important;
      padding: 6px;
      font-size: 12px !important;
    }

    .file-label {
      font-size: 14px;
      // padding-left: 7px;
      white-space: nowrap;
    }

    // .dropdown .dropdown-menu{
    //     width: 320px;
    // }
    // .dropdown .dropdown-menu .dropdown-content {
    //   -webkit-transition: 3s ease-out;
    //   -moz-transition: 3s ease-out;
    //   -o-transition: 3s ease-out;
    //   -ms-transition: 3s ease-out;
    //   transition: 3s ease-out; //width: 50px;
    // }
  }

  // .img-options .dropdown-menu .dropdown-content.drop-width{
  //     width: 300px;
  // }
}

.se-p .user-status {
  position: relative;

  .fa {
    position: absolute;
    right: 10px;
    bottom: 40px;
    // bottom: 5px;
    font-size: 8px !important;
  }

  .timeAgo {
    position: absolute;
    right: 10px;
    bottom: 5px;
  }
}

.group-img-edit {
  .img-options {
    position: absolute;
    bottom: -15px;
    right: 5px;
    width: 16px;
    height: 16px;
    text-align: center;
    padding-top: 0px;
    border-radius: 100%;
    background: var(--primaryColor);
    cursor: pointer;

    .fa {
      color: var(--whiteColor) !important;
      padding: 4px;
      font-size: 8px !important;
    }

    .file-label {
      font-size: 14px;
      padding-left: 7px;
      white-space: nowrap;
    }

    .dropdown-menu {
      top: 70%;
    }
  }
}

.user-settings {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInRight;

  .columns {
    margin-bottom: 0px;
  }

  .field-body {
    flex-grow: 3;
  }

  .setting-notific {
    .table {
      margin-bottom: 10px;

      thead th,
      tbody td {
        font-size: 14px;
        border: 1px solid #e6e6e6;
      }
    }

    .reminder {
      margin-bottom: 10px;

      strong {
        font-size: 14px;
        text-transform: uppercase;
      }

      .remain-text {
        span {
          font-size: 12px;

          i.fa-info-circle {
            margin: 4px 10px;
          }
        }
      }
    }

    .desktop-notific {
      strong {
        font-size: 14px;
        text-transform: uppercase;
      }

      .notific-text {
        font-size: 12px;
      }

      .columns {
        margin-top: 0px;
      }
    }
  }

  .userProfile-scroll {
    overflow-y: auto;
    max-height: calc(100vh - 275px);
  }
}

.content-height-calc {
  height: calc(100vh - 275px);
  overflow-y: auto;
  overflow-x: hidden;
}

.content-height-calc.columns:not(:last-child) {
  margin-bottom: calc(1.75rem - 1.25rem);
}

.up-btn-fix {

  // box-shadow: 1px -2px 4px 0px rgba(0, 0, 0, 0.1);
  .column {
    background: var(--whiteColor);
    padding: 0.75rem 2.75rem;
  }
}

.up-btn-fix.columns {
  margin-left: 0.02rem;
}

.preview-layout {
  margin-bottom: 10px;

  label {
    margin-right: 25px !important;
  }

  .is-checkradio[type="radio"]+label::before {
    top: -60px;
  }

  .is-checkradio[type="radio"]+label::after {
    top: -60px;
  }
}

.modal {

  .modal-card-head,
  .modal-card-foot {
    padding: 10px 20px;
  }

  .modal-card-foot .column {
    padding: 0;
  }
}

.icon-align {
  right: 12px;
  bottom: 5px;
  position: absolute; // color: #00d1b2;
  color: var(--primaryColor);
}

.control.has-icons-right .icon.is-right {
  pointer-events: auto;
}

.form-style {
  h3 {
    padding-bottom: 10px;
    border-bottom: 1px solid #dbdbdb;
  }
}

input[type="file"] {
  display: none;
}

.hide-listwrapper {
  // transition: all ease-in-out .4s;
  z-index: 10000;
  display: none;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
}

.box-overflow {
  // height: calc(100vh - 64px);
  height: calc(100vh - 69px);
  overflow-y: auto;
}

.quickview {
  top: 52px !important;
}

.create-sub-post {
  // background: #3c3b3b;
  background: var(--whiteColor); // box-shadow: none;
  position: absolute;
  top: 57px;
  z-index: 100; // width: 60%;
  width: 0%;
  border-radius: 0;
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  margin-left: 15%;
  overflow: hidden;
  display: none; // height: 0px;
  padding: 0;
  transition: width 1s ease-in;
  -webkit-transition: width 1s ease-in;

  .textarea {
    padding: 0.4em;
  }

  // .media {
  //   width: 75% !important;
  //   max-width: 75%;
  //   margin: 0 auto;
  // }
}

.post-animate {
  display: block; // height: 150px;
  padding: 1.25rem;
  width: 60%; // animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  // transition: height 1s ease-out;
  // -webkit-transition: height .5s ease-out;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;
}

.edit-text-box {
  .input {
    height: 1.95em !important;
    width: auto;
    font-size: 14px !important;
  }

  .text-input {
    height: 2.05em !important;
    width: 70% !important;
  }

  .options {
    display: inline-block;
    vertical-align: text-bottom;

    i.fa {
      // padding: 10px 5px;
      font-size: 14px !important;
    }
  }

  .textarea {
    overflow: hidden; // height: 36px !important;
    min-width: 60%;
    padding: 5px 5px;
    width: 70% !important;
    display: inline-block;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
  }
}

.modal {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;
}

.empty-img {
 
  border-radius: 100%;
  background: var(--primarylightColor);
  color: var(--primaryColor);
  vertical-align: middle;
  text-align: center; // font-size: 28px;
  // padding-top: 2px;
  font-size: 22px;
  padding-top:4px;
}

.empty-head-icon {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: #f4f4f4;
  color: var(--primaryColor);
  vertical-align: middle;
  text-align: center;
  font-size: 22px;
  margin-right: 5px;
  padding-top: 1px;
}

.empty-profile-icon {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #f4f4f4;
  color: var(--primaryColor);
  vertical-align: middle;
  text-align: center;
  font-size: 35px;
  padding-top: 23%;
  cursor: pointer;
}

.fa.fa-font-size {
  font-size: 14px !important; // padding: 7px 2px;
}

.disable-fa-edit {
  cursor: not-allowed;

  i.fa {
    pointer-events: none !important;
  }
}

// Flex wrap container

.flex-container {
  padding: 0px;
  margin: 0;
  list-style: none;

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

.drag-drop-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;

  .flex-item {
    width: 250px;
  }
}

.drag-drop-containe:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  opacity: 0.6;
}

.cdk-drag-preview,
.cdk-drop-list-receiving {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

  .card-content,
  .videoDuration,
  .fa-eye,
  .fa-play-circle-o {
    display: none;
  }
}

.cdk-drag-placeholder {
  opacity: 0.3;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-handle {
  padding: 5px;
  cursor: move;
  position: absolute;
  z-index: 999;

  background: var(--whiteColor);
}

/* .flex-item:nth-of-type(1) { order: 3; }
.flex-item:nth-of-type(2) { order: 4; }
.flex-item:nth-of-type(3) { order: 1; }
.flex-item:nth-of-type(4) { order: 5; }
.flex-item:nth-of-type(5) { order: 2; } */

.flex-item {
  // padding: 5px;
  // width: 100px;
  // height: 100px;
  margin: 5px;
  width: 267px;
  // height: 100px;
}

.collapse-menu.first-level-menu {
  max-width: 60px;

  a {
    padding: 2rem 0.9375rem;

    .menu-badge {
      top: 20px;
    }

    img {
      max-width: 100% !important;
    }
  }

 
  .showMeetLabel {
    display: none;
  }
}



input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  cursor: pointer;
  padding: 1px;
  margin: 10px 0px 0px 10px;
}

.beforeLayout .is-checkradio[type="radio"]+label::before {
  top: 0px;
}

.beforeLayout.preview-layout .is-checkradio[type="radio"]+label::after {
  top: 0px;
}

.audio_view {
  height: calc(100vh - 113px);
  background: #2e3246;
  opacity: 0.75;

  .audio_content {
    padding-top: 12%;
    text-align: center;
    color: $complete-white-color;

    .empty-head-icon {
      width: 120px;
      height: 120px;
      padding-top: 30%;
      background: var(--whiteColor);
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 36px;
      margin-right: 0;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
    }

    .is-small {
      font-size: 11px;
      margin-bottom: 12px;
    }

    .audio_btn,
    .option_btn {
      height: 36px;
      max-height: 36px;
      max-width: 36px;
      min-height: 36px;
      min-width: 36px;
      width: 36px;
      -webkit-appearance: none;
      background-color: var(--whiteColor);
      border: none;
      border-radius: 290486px;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.6);
      cursor: pointer;
      pointer-events: auto;
      display: inline-block;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 0;
      outline: 0;
      position: relative;
      vertical-align: top;

      .fa {
        padding-top: 9px;
      }

      .fa.rotate-img {
        color: #d62e2e;
        transform: rotate(134deg);
        padding: 0;
        margin-top: 10px;
      }
    }

    .conference_icon {
      padding-top: 30px;
    }

    .option_btn {
      margin-left: 12px;

      .fa {
        color: var(--primaryColor);
        padding-top: 9px;
      }

      &:hover {
        .fa {
          color: #1346ec;
        }
      }
    }
  }
}

.message {
  margin-bottom: 0px !important;
}

.audio_receiver {
  position: relative;

  .audio_rec_view {
    position: fixed;
    bottom: 70px;
    right: 60px;
    padding: 15px 40px;
    text-align: center;
    background: #2e3246;
    opacity: 0.75;
    color: $complete-white-color;

    .empty-head-icon {
      width: 36px;
      height: 36px;
      background: var(--whiteColor);
      margin-bottom: 12px;
      font-size: 22px;
      margin-right: 0;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
    }

    .is-small {
      font-size: 11px;
      margin-bottom: 12px;
    }

    .audio_btn,
    .option_btn {
      height: 36px;
      max-height: 36px;
      max-width: 36px;
      min-height: 36px;
      min-width: 36px;
      width: 36px;
      margin: 0px 5px;
      -webkit-appearance: none;
      background-color: var(--whiteColor);
      border: none;
      border-radius: 290486px;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.6);
      cursor: pointer;
      pointer-events: auto;
      display: inline-block;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 0;
      outline: 0;
      position: relative;
      vertical-align: top;

      .fa {
        color: hsl(141, 71%, 48%);
        padding-top: 9px;
      }

      .fa.rotate-img {
        color: #d62e2e;
        transform: rotate(134deg);
        padding: 0;
        margin-top: 10px;
      }
    }
  }
}

.audio_receiver.user_minimiz {
  .empty-profile-icon {
    width: 48px;
    height: 48px;
    font-size: 18px;
    padding-top: 10px;
    margin: 15px auto 15px;
  }

  .text-primary {
    font-size: 12px;
    margin-left: 10px;
  }
}

app-subscription{
  .setting-section .sub-block{
    padding-top:0rem !important;
    .sub-block-head{
      padding-bottom: 0rem;
    }
  }
  
  .card-content{
    width: 100%;
    overflow-x: auto;
    padding:0rem 1rem;
    min-height:50vh;
   }
   table{
     font-size:.9rem;
     thead{
      tr{
       background:#f1f1f1;
      }
    }
   } 
}
 

 

/* ==========================================================================
  @media tab view styles
  ========================================================================== */

@media screen and (min-width: 320px) and (max-width: 768px) {
  .activeUserMobile {
    .mobileViewBtn {
      display: block;
    }
  }

  .usergroup-level {
    .level-right {
      .mobileViewBtn {
        display: block;
      }
    }
  }

  .chats .msg-list-view .media {
    border-bottom: 1px solid #dcdcdc;
    padding: 8px 10px;
    margin: 0px;
    position: relative;
    background-color: var(--whiteColor);
  }

  .chats .msg-list-view .media figure .image {
    width: 20px;
    height: 15px;
  }

  .small-text-time {
    position: relative;
    white-space: nowrap;

  }

  #empty-head-icon {
    font-size: 1rem;
    height: 30px;
    width: 30px;
    padding: 3px;
    margin-top: 5px;
  }

  .signin-container {
    .card {
      // margin: 32px;
    }
  }

  .sign-footer {
    display: block !important;
    .columns {
      text-align: center !important;

      .has-text-right {
        text-align: center !important;
      }
    }
  }

  .signup-container {
    .login-form {
      .column {
        padding: 0.25rem;
      }
    }

    .card {
      padding: 30px !important;
      height: calc(100vh - -48px);
    }

    img {
      max-width: 50% !important;
    }
  }

  .chats .box {
    max-width: 60%;
  }

  .chats .chat-name {
    .fa-angle-double-left {
      line-height: 36px;
      padding-right: 7px;
      font-size: 24px;
    }
  }

  .flex-grid .fi-p {
    max-width:100px;

    a {
      border: none;

      span {
        display: none;
      }
    }
  }

  .flex-grid .se-p {
    //  flex: 0 1 250px;
  }

  .list-wrapper {
    ul.notification-list {
      li {
        .is-size-5 {
          font-size: 1.1rem !important;
        }
      }
    }
  }

  .notification-content-wrapper {
    header {
      h3 {
        span {
          font-size: 1.1rem !important;
        }
      }

      .desk-view {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

        .desk-icon {
          height: 20px;
        }
      }

      .has-text-right {
        .desk-view {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;

          .fullViewBtn {
            display: none;
          }

          .mobileViewBtn {
            display: block !important;
          }
        }

        .dropdown {
          display: block;

          .dropdown-trigger {
            padding-top: 5px;
          }

          .dropdown-content {
            text-align: left;

            .dropdown-item {
              .fa {
                width: 20px;
              }
            }
          }
        }

        .headerSearch {
          display: none;
        }

        .userMobileView {
          display: block !important;

          .btndeskTopView {
            display: none;
          }

          .isBtnMobileView {
            display: block;
          }

          .th-p {
            .column.is-offset-one-quarter {
              margin-left: 0px !important;
            }
          }
        }

        .user-bulk-cr {
          .bulk_two_thirds {
            width: 100% !important;
          }
        }

        // .header-elibrary{
        //   display: block !important;
        // }
      }

      .edit-text-box {}
    }

    .studyRoomList {
      .hideMobileView {
        display: none;
      }

      .activeMobileView {
        display: block;
      }
    }

    .library-dashboard-view {
      .flex-item {
        width: 100%;
      }
    }

    .topicListView {
      .table {
        font-size: 12px;
      }

      tbody {
        tr {
          td {
            padding: 0.5em 0.05em;

            .actionBtnDeskView {
              display: none;
            }

            .actionBtnMobileView {
              display: block;

              .dropdown-item {
                span {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    .elibraryTileView {
      .headerSearch {
        display: none;
      }

      .header-elibrary {
        display: contents !important;

        .actionBtnDeskView {
          display: none;

          .button {
            .is-link {
              .is-outlined {
                padding: 8px !important;
              }
            }
          }
        }

        .actionBtnMobileView {
          display: block;

          .button {
            .is-link {
              .is-outlined {
                padding: 8px !important;
              }
            }
          }
        }
      }
    }

    .topicDetailsView {
      .hideDeskViewIcon {
        display: none;
      }

      .has-text-right {
        .desk-view {
          float: right;

          .btnDeskView {
            border-style: initial;
            display: none;
          }

          .btnMobileView {
            border-style: initial;
            display: block;
          }

          .isMobiles {
            .fa {
              color: #ccc;

              :hover {
                color: var(--primaryColor);
              }
            }
          }

          .tabs {
            a {
              padding: 0.5em 0.2em;
            }
          }
        }
      }
    }

    .studyroomListView {
      font-size: 12px;
    }

    

    .library_tile_view {
      .flex-container {
        .flex-item {
          .card {
            .card-footer {
              .actBtnDeskView {
                display: none;
              }

              .actBtnMobileView {
                display: block;
                float: right;
              }
            }
          }
        }
      }
    }

    .activeGroupUser {
      .delete-user {
        padding: 0px 5px;
        display: none;
        color: #b1b1b1;
        position: absolute;
        // top: 22px;
        right: 15px;
        bottom: 15px;
        cursor: pointer !important;

        &:hover {
          color: var(--primaryColor);
        }
      }

      .groupUserList {
        &:hover {
          .delete-user {
            display: block;
          }
        }
      }
    }
  }

  .is-divider[data-content]::after,
  .is-divider-vertical[data-content]::after {
    background: var(--whiteColor);
    color: #b5b5b5;
    content: attr(data-content);
    display: inline-block;
    font-size: 0.75rem;
    padding: 0.4rem 0.8rem;
    -webkit-transform: translateY(-1.1rem);
    -ms-transform: translateY(-1.1rem);
    transform: translateY(-1.1rem);
    text-align: center;
  }

  .showAssignment {
    .card-content {
      overflow-x: hidden;
      max-height: calc(100vh - 246px);

      .interactiveScroll {
        .is-divider-vertical {
          display: block;
          flex: none;
          width: auto;
          height: auto;
          padding: 2rem;
          margin: 0;
          position: relative;
          border-top: none;
          min-height: 2rem;

          &::before {
            border-left: 0.1rem solid #dbdbdb;
            bottom: 1rem;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            top: 1rem;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
          }
        }
      }
    }

    .uploadFile {
      background-color: var(--primaryColor);
      color: var(--whiteColor);
      width: 45px;
      height: 45px;
      border-radius: 30px;
    }

    .preview-images-zone {
      width: 100%;
      border: 1px solid #ddd;
      min-height: 180px;
      /* display: flex; */
      padding: 5px 5px 0px 5px;
      position: relative;
      overflow: auto;

      .preview-image {
        height: 150px;
        width: 150px;
        position: relative;
        margin-right: 8px;
        float: left;
        margin-bottom: 8px;

        &:first-child {
          height: 150px;
          width: 150px;
          position: relative;
          margin-right: 8px;
        }

        .image-zone {
          width: 100%;
          height: 100%;

          .img {
            width: 100%;
            height: 100%;
          }
        }

        .tools-edit-image {
          position: absolute;
          z-index: 100;
          color: var(--whiteColor);
          bottom: 0;
          width: 100%;
          text-align: center;
          margin-bottom: 10px;
          display: none;

          .preview-image {
            &:hover {
              .image-cancel {
                display: block;
                cursor: pointer;
              }
            }
          }
        }

        .image-cancel {
          font-size: 18px;
          position: absolute;
          top: 0;
          right: 0;
          font-weight: bold;
          margin-right: 10px;
          cursor: pointer;
          z-index: 100;
        }

        &:hover {
          .image-zone {
            opacity: 0.5;
            cursor: move;
          }
        }
      }
    }
  }

  .textbar-desk {
    display: none;
  }

  .textbar-mobile {
    display: block;
  }
}

@media screen and (min-width: 400px) and (max-width: 1250px) {
  .chats .timing span.time-history::after {
    width: 33%;
    webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }

  .chats .timing span.time-history::before {
    width: 33%;
    webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }

  .library_tile_view {
    .flex-item {
      width: 258px;
    }
  }
}

@media screen and (min-width: 400px) and (max-width: 1250px) {
  .chats .timing span.time-history::after {
    width: 33%;
    webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }

  .chats .timing span.time-history::before {
    width: 33%;
    webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }

 
}

app-settings {
  .card {
    width:100%;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
    color: hsl(0, 0%, 29%);
    // max-width: 82%;
    position: relative;
    // min-width: 277px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  app-settings,app-subscription {
    .card {
      width:100%;
      background-color: hsl(0, 0%, 100%);
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
        0 0px 0 1px rgba(10, 10, 10, 0.02);
      color: hsl(0, 0%, 29%);
      
      position: relative;
      max-width:355px;
    }

    .card-content{
      max-width:345px;
    }

    .tabs {
      -webkit-overflow-scrolling: touch;
      align-items: stretch;
      display: flex;
      font-size: 1rem;
      justify-content: space-between;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      // max-width: 200px;
      // min-width: 326px;
    }

   
  }

  app-menu-options.flex-grid .fi-p a span {
    display: none !important;
  }

  .setting-section{
     padding:1.5rem .5rem !important;
     max-height: 95vh;
     overflow-y: auto;

     .card-body{
      padding:0px !important;
     }
     .tabs{
      display: none;
     }
     .accordion{

      .accordion-body{
        border:1px solid var(--bs-accordion-border-color);
      }
      .accordion-header{
        display: block;
        border:1px solid var(--bs-accordion-border-color);
      }
      .accordion-button:focus{
        box-shadow: none !important;
      }
      .accordion-button{
        color: #111;
        font-weight: bold;
      }
      .accordion-button:not(.collapsed) {
        color: #111;
        background-color: #f4f4f4 !important;
         font-weight: bold;
    }
    }
  }

 



}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  app-menu-options.flex-grid .fi-p a span {
    display: block !important;
  }

  app-menu-options.flex-grid .fi-p {
    max-width: 320px !important;
  }

  app-menu-options.flex-grid .fi-p.collapse-menu {
    max-width: 60px !important;
  }

  app-menu-options {
    .first-level-menu li {
      justify-content: flex-start !important;
    }

    .first-level-menu.collapse-menu li {
      justify-content: center !important;
    }
  }

  app-settings {
    .card {
      width: 100%;
      background-color: hsl(0, 0%, 100%);
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
        0 0px 0 1px rgba(10, 10, 10, 0.02);
      color: hsl(0, 0%, 29%);
      // max-width: 350px;
      position: relative;
      min-width: 500px;
    }

    .tabs {
      -webkit-overflow-scrolling: touch;
      align-items: stretch;
      display: flex;
      font-size: 1rem;
      justify-content: space-between;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      // max-width: 200px;
      // min-width: 326px;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .activeUserMobile {
    .mobileViewBtn {
      display: block;
    }
  }

  .usergroup-level {
    .level-right {
      .mobileViewBtn {
        display: block;
      }
    }
  }

  .sign-footer {
    .columns {
      text-align: center !important;
      display: flex;

      .has-text-right {
        text-align: center !important;
      }
    }
  }

  .signup-container {
    .login-form {
      .column {
        padding: 0.25rem;
      }
    }

    .card {
      padding: 30px !important;
      height: calc(100vh - -134px);
    }

    img {
      max-width: 50% !important;
    }
  }

  .notification-content-wrapper {
    .post-animate {
      width: 88%;
    }

    header {
      height: 56px;
      padding: 5px 15px 5px 5px !important;

      .columns {
        width: 100%;
      }

      h3 {
        span {
          font-weight: 600;
          font-size: 1rem !important;
        }
      }

      figure {
        max-width: 78px;

        i {
          line-height: 54px;
          padding-right: 7px;
          font-size: 14px !important;
        }

        img {
          width: 48px;
          border-radius: 100%;
        }
      }

      .has-text-right {
        &>a {
          display: none !important;
        }

        .dropdown-item {
          display: block;
        }
      }
    }

    .box {
      // margin: 10px !important;
      margin: 15.8px 0px;
    }

    .headerSearch {
      display: none;
    }

    .studyroomListView {
      font-size: 12px;
    }

    .studyRoomList {
      .hideMobileView {
        display: none;
      }

      .activeMobileView {
        display: block;
      }
    }

    .library_tile_view {
      .flex-container {
        .flex-item {
          .card {
            .card-footer {
              .has-text-right {
                .actBtnDeskView {
                  display: none;
                }

                .actBtnMobileView {
                  display: block;
                  float: right;
                }
              }
            }
          }
        }
      }
    }

    .elibraryTileView {
      width: 100% !important;

      .hideMobileView {
        display: none;
      }

      .header-elibrary {
        display: contents;

        .actionBtnDeskandMobView {
          margin-right: 5px;

          .button {
            .is-link {
              .is-outlined {
                padding: 8px !important;
              }
            }
          }
        }

        .actionBtnDeskView {
          display: none;
          margin-right: 5px;

          .button {
            .is-link {
              .is-outlined {
                padding: 8px !important;
              }
            }
          }
        }

        .actionBtnMobileView {
          display: block;
          margin-right: 5px;

          .button {
            .is-link {
              .is-outlined {
                padding: 8px !important;
              }
            }
          }
        }
      }

      h3 {
        span {
          display: inline !important;
          font-size: 13px !important;
        }
      }
    }

    .library_tile_view {
      .flex-item {
        width: 319px;

        .responsiveRadioBtn {
          margin: 20px 0;
        }

        .questionnarieMobile {
          margin-top: 10px;
        }

        .card {
          .card-content,.card-body {
            .questionnarie {
              .question-option {
                .control {
                  width: 176px;

                  .is-checkradio[type="radio"].is-danger+label::before,
                  .is-checkradio[type="radio"].is-danger+label:before {
                    border: 3px solid #f14668;
                  }
                }
              }
            }

            .interactiveScroll {
              .is-divider-vertical {
                display: block;
                flex: none;
                width: auto;
                height: auto;
                padding: 2rem;
                margin: 0;
                position: relative;
                border-top: none;
                min-height: 2rem;

                &::before {
                  border-left: 0.1rem solid #dbdbdb;
                  bottom: 1rem;
                  content: "";
                  display: block;
                  left: 50%;
                  position: absolute;
                  top: 1rem;
                  -webkit-transform: translateX(-50%);
                  -ms-transform: translateX(-50%);
                  transform: translateX(-50%);
                }
              }
            }
          }

          .card-footer {
            .lessonPublish {
              width: 100% !important;
              margin-left: 26px;

              .responsiveRadioBtn {
                margin: 20px 0;
              }

              .switch[type="checkbox"]+label {
                position: relative;
                display: initial;
                font-size: 1rem;
                line-height: initial;
                padding-left: 0;
                padding-right: 3.5rem;
                cursor: pointer;
                font-weight: bold;
              }

              .switch[type="checkbox"]+label::before {
                position: absolute;
                display: block;
                top: 0;
                left: auto;
                right: -0.375rem;
                width: 3rem;
                height: 1.5rem;
                border: 0.1rem solid transparent;
                border-radius: 4px;
                background: #b5b5b5;
                content: "";
              }

              .switch[type="checkbox"].is-success+label:after {
                display: block;
                position: absolute;
                top: 0.25rem;
                left: auto;
                right: 1.425rem;
                width: 1rem;
                height: 1rem;
                transform: translate3d(0, 0, 0);
                border-radius: 4px;
                background: var(--whiteColor);
                transition: all 0.25s ease-out;
                content: "";
              }
            }

            .lessonSubmitBtn {
              position: absolute;
              top: 5px;
              right: 0px;
            }
          }
        }
      }
    }

    .library-dashboard-view {
      .flex-item {
        width: 100%;
      }
    }

   

    .topicListView {
      .table {
        font-size: 12px;
      }

      tbody {
        tr {
          td {
            padding: 0.5em 0.05em;

            .actionBtnDeskView {
              display: none;
            }

            .actionBtnMobileView {
              display: block;

              .dropdown-item {
                span {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    .topicDetailsView {
      .hideDeskViewIcon {
        display: none;
      }

      .has-text-right {
        .desk-view {
          float: right;

          .btnDeskView {
            border-style: initial;
            display: none;
          }

          .btnMobileView {
            border-style: initial;
            display: block;
          }

          .isMobiles {
            .fa {
              color: #ccc;

              :hover {
                color: var(--primaryColor);
              }
            }
          }

          .tabs {
            a {
              padding: 0.5em 0.2em;
            }
          }
        }
      }
    }

    .mobileViewuserandgroup {
      .column {
        .is-offset-one-quarter {
          margin-left: 0px !important;
        }
      }
    }
  }

  .showAssignment {
    .card {
      .card-content {
        overflow-x: auto;
        max-height: calc(100vh - 246px);
      }
    }
  }

  .display-flex {
    display: flex !important;

    .level-left+.level-right {
      margin-top: 0;
      margin-right:30px;
    }
  }

  .list-wrapper li .is-one-quarter {
    flex: none;
    width: 25%;
  }

  .media-content {
    overflow-x: inherit;
  }
}



/* ==========================================================================
Generic Helper Class
========================================================================== */

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.fa {
  font-size: 1rem !important;
  text-align: center;
  vertical-align: top;
}

.clearfix:after {
  clear: both;
}

.text-bold {
  font-weight: bold;
}

.text-muted {
  color: $subtle-lcolor !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.required {
  color: #d62e2e;
}

.selected-li {
  background-color: #dcdcdc;

  &:hover {
    background-color: #dcdcdc !important;
  }
}

*:focus {
  outline: none !important;
}

.cursor-pointer {
  cursor: pointer;

  & *:hover {
    cursor: pointer;
  }
}

.cursor-pointer-with-color {
  cursor: pointer;
  color: $tertiary-color;

  & *:hover {
    cursor: pointer;
  }
}

.custom-status .fa-pencil {
  position: relative;
  right: -42px;
  color: #ccc;
}

.connectionMonitor {
  position: absolute;
  right: 37%;
  z-index: 30;
  top: 30px;
  width: 400px;

  .fa-exclamation-triangle {
    padding-right: 15px;
  }

  & *.message-body {
    border-radius: 0px;
  }

  p {
    font-size: 14px;
  }

  span {
    padding: 15px;
  }
}

.custom-status {
  width: 230px;

  .input {
    outline: 0;
    border: 0;
    border-radius: 0 !important;
    box-shadow: none !important;
    border-bottom: 1px solid #2f2e2e;
    background-position: 14px 12px;
    background-repeat: no-repeat;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
}

.custom-status .fa-pencil:hover {
  color: rgba(0, 0, 0, 0.6);
}

.circle-red {
  color: hsl(348, 100%, 61%);
}

.minus-circle {
  border: 1px solid #ff3860;
  background-color: #ff3860;
  width: 11px;
  height: 11px;
  border-radius: 100%;
  position: relative;
  margin: 4px 0px;
  display: inline-block;
  vertical-align: middle;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--whiteColor);
    margin: auto 2px;
    height: 2px;
  }
}

.circle-grey {
  color: hsl(0, 1%, 67%);
}

.circle-yellow {
  color: hsl(48, 100%, 67%);
}

.circle-green {
  color: hsl(141, 71%, 48%);
}

.active-bg {
  background: var(--menuSelectColor) !important;
}

//Primary Button
$btn-primary-bg-color: $secondary-color;
$btn-primary-bg-border-color: darken($secondary-color, 15%);
$btn-primary-text-color: $complete-white-color;
$btn-primary-hover-color: lighten($secondary-color, 3%);
$btn-primary-hover-text-color: darken($typography-dcolor, 15%);

.pri-cus-btn {
  display: inline-block;
  background-color: $btn-primary-bg-color;
  color: $btn-primary-text-color;
  text-align: center;
  // font-weight: bold;
  font-size: $font-size-medium;
  padding: 5px 15px;
  text-decoration: none;
  min-width: 60px;
  cursor: pointer;
  border: 0;
  border-radius: 2px; //box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  /*box-shadow: 0 1px 4px rgba(0, 0, 0, .6);*/
  //box-shadow: 0 2px 4px rgba(0,0,0,.16), 0 2px 10px rgba(0,0,0,.12);
  //text-transform: uppercase;
  outline: none;
  -webkit-font-smoothing: antialiased;

  &:hover {
    background-color: $btn-primary-hover-color;
    text-decoration: none;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6); //@include transition(all, linear, .25s);
    color: $btn-primary-text-color; //background-color: #2196F3;
    border-color: transparent;
  }

  &:active {
    color: $btn-primary-hover-text-color;
    background-color: $btn-primary-hover-color;
    text-decoration: none;
    box-shadow: inset 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
  }

  &:focus {
    outline: none;
    color: $btn-primary-text-color;
    text-decoration: none;
  }
}

.pri-cus-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  box-shadow: none;
  background: #7a7a7a !important;
}

.text-clr-grey {
  color: #c7c6c6 !important;
}

.dtf-cus-btn {
  display: inline-block;
  background-color: $subtle-lcolor;
  color: $typography-dcolor;
  text-align: center;
  font-weight: 400;
  font-size: $font-size-medium;
  padding: 5px 15px;
  text-decoration: none;
  min-width: 60px;
  border: 0;
  border-radius: 4px; //box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  /*box-shadow: 0 1px 4px rgba(0, 0, 0, .6);*/
  //box-shadow: 0 2px 4px rgba(0,0,0,.16), 0 2px 10px rgba(0,0,0,.12);
  //text-transform: uppercase;
  outline: none; //@include transition(all, ease-in-out, .25s);
  -webkit-font-smoothing: antialiased;

  &:hover {
    background-color: darken($subtle-lcolor, 5%);
    text-decoration: none;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6); //@include transition(all, linear, .25s);
    //color: $btn-primary-hover-text-color;
    //background-color: #2196F3;
    border-color: transparent;
  }

  &:active {
    //color: $btn-primary-hover-text-color;
    background-color: darken($subtle-lcolor, 5%);
    text-decoration: none;
    box-shadow: inset 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
  }
}

.pulse-button {
  position: relative;
  width: 100px;
  height: 100px;
  border: none;
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  border-radius: 50%;
  background-color: #e84c3d; //background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/173024/jonathanlarradet_copy.png);
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.pulse-button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
    width: 110px;
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}

.input.is-active,
.input.is-focused,
.input:active,
.input:focus,
.textarea.is-active,
.textarea.is-focused,
.textarea:active,
.textarea:focus {
  border-color: $input-field-border-active;
  //box-shadow: 0 0 0 0.125em rgba(1, 59, 221, 0.25);
  box-shadow: none;
}

.btn-disabled {
  box-shadow: none !important;
  background: #999999;
  border: 1px solid #999999;
  color: #fff;
  cursor: not-allowed;
  opacity: 0.75;
  border-radius: 2em;
  -webkit-font-smoothing: antialiased;

  &:hover {
    color: #fff;
    box-shadow: none !important;
  }

  &:active {
    color: #fff;
    box-shadow: none !important;
  }
}

.dz-default {
  background: #e9ecef;
  position: relative;
  border-radius: 3px; // min-height: 150px;
  // width: 120px;
  // height: 100px;
  width: 90px;
  height: 80px;
  text-align: center; // border: 2px dashed #9fa2aa; // width: 150px;
  transition: all 0.3s ease-in-out;

  &:hover {
    // border-color: #474950; // cursor: pointer;
  }
}

.pos-relative {
  position: relative !important;
}

.pos-absolute {
  position: absolute !important;
}

.is-warning {
  background: #f9ebee;
}

.size-14 {
  font-size: 14px !important;
}

.border-none {
  border: none !important;
}

.border-t-none {
  border-top: none !important;
}

.border-b-none {
  border-bottom: none !important;
}

.border-l-none {
  border-left: none !important;
}

.border-r-none {
  border-right: none !important;
}

.display-ib {
  display: inline-block;
}

.display-i {
  display: inline;
}

.display-b {
  display: block;
}

.display-n {
  display: none;
}

.z-index-2 {
  z-index: 2;
}

.z-index-1 {
  z-index: 1;
}

/*.mb0 { margin-bottom: 0; }
.mb1 { margin-bottom: 6px; }
.mb2 { margin-bottom: 12px; }
.mb3 { margin-bottom: 18px; }
.mb4 { margin-bottom: 24px; }*/

.pad-0 {
  padding: 0px;
}

.pad-5 {
  padding: 5px;
}

.pad-7 {
  padding: 7px;
}

.pad-10 {
  padding: 10px;
}

.pad-15 {
  padding: 15px;
}

.pad-25 {
  padding: 25px;
}

.pad-5-10 {
  padding: 5px 10px;
}

.pad-5-0 {
  padding: 5px 0px;
}

.pad-0-5 {
  padding: 0px 5px;
}

.pad-0-10 {
  padding: 0px 10px;
}

.pad-5-2 {
  padding: 5px 2px !important;
}

.pad-7-2 {
  padding: 7px 2px !important;
}

.pad-15-2 {
  padding: 15px 2px !important;
}

.pad-15-0 {
  padding: 15px 0px;
}

.pad-10-5 {
  padding: 10px 5px;
}

.pad-7-15 {
  padding: 7px 15px;
}

.pad-15-7 {
  padding: 15px 7px;
}

.pad-13-10 {
  padding: 13px 10px;
}

.pad-t10 {
  padding-top: 10px;
}

.pad-b-0 {
  padding-bottom: 0px !important;
}

.mar-0 {
  margin: 0px !important;
}

.mar-5 {
  margin: 5px;
}

.mar-7 {
  margin: 7px;
}

.mar-10 {
  margin: 10px;
}

.mar-20 {
  margin: 20px;
}

.mar-25 {
  margin: 25px;
}

.mar-5-10 {
  margin: 5px 10px;
}

.mar-5-0 {
  margin: 5px 0px;
}

.mar-0-5 {
  margin: 0px 5px;
}

.mar-0-10 {
  margin: 0px 10px;
}

.mar-0-15 {
  margin: 0px 15px;
}

.mar-10-0 {
  margin: 10px 0px;
}

.mar-10-5 {
  margin: 10px 5px;
}

.userandgroupDetails {
  .button {
    margin-right: 6px;

    .fa {
      margin-right: 5%;
    }
  }

  .userMobileView {
    display: none;
  }
}

.mar-7-15 {
  margin: 7px 15px;
}

.mar-15-7 {
  margin: 15px 7px;
}

.mar-top15 {
  margin-top: 15px !important;
}

.mar-t-5 {
  margin-top: 5px;
}

.mar-t-2 {
  margin-top: 2px;
}

.mar-l-2 {
  margin-left: 2px;
}

.mar-bot5 {
  margin-bottom: 5px;
}

.mar-bot25 {
  margin-bottom: 25px;
}

.mar-top20 {
  margin-top: 20px;
}

.top-8 {
  top: 8px;
}

.cus-cards {
  // Custom Panel
  background-color: rgb(255, 255, 255);
  box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.24);
}

.cus-cards-with-pad {
  // Custom Panel with Padding
  background-color: rgb(255, 255, 255);
  box-shadow: 0.5px 0.9px 4px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.top-bottom-dark-box-shadows {
  //@include box-shadow($color $hoff $voff $blur $spread $inset, $color $hoff $voff $blur $spread $inset);
  box-shadow: (rgba(255, 255, 255, 0.75) 0 1px 0 0 inset,
      rgba(0, 0, 0, 0.9) 0 1px 2px 0);
}

.top-bottom-moderate-box-shadows {
  box-shadow: (rgba(255, 255, 255, 0.6) 0 1px 0 0 inset,
      rgba(0, 0, 0, 0.7) 0 1px 2px 0);
}

.top-bottom-light-box-shadows {
  box-shadow: (rgba(255, 255, 255, 0.4) 0 1px 0 0 inset,
      rgba(0, 0, 0, 0.6) 0 1px 2px 0);
}

.top-bottom-mild-box-shadows {
  box-shadow: (rgba(255, 255, 255, 0.5) 0 1px 0 0 inset,
      rgba(51, 51, 51, 0.75) 0 1px 2px 0);
}

.top-highlight-box-shadow {
  box-shadow: (rgba(255, 255, 255, 0.75) 0 1px 0 0 inset);
}

.bottom-dark-box-shadow {
  box-shadow: (rgba(0, 0, 0, 0.9) 0 1px 3px 0);
}

.bottom-moderate-box-shadow {
  box-shadow: (rgba(0, 0, 0, 0.7) 0 1px 3px 0);
}

.bottom-moderate2-box-shadow {
  box-shadow: (rgba(0, 0, 0, 0.6) 0 1px 2px 0);
}

.bottom-light-box-shadow {
  box-shadow: (rgba(0, 0, 0, 0.3) 0 1px 2px 0);
}

.bottom-mild-box-shadow {
  box-shadow: (rgba(51, 51, 51, 0.75) 0 1px 2px 0);
}

.bottom-dark-s-box-shadow {
  box-shadow: (rgba(0, 0, 0, 0.9) 0 1px 1px 0);
}

.bottom-moderate-s-box-shadow {
  box-shadow: (rgba(0, 0, 0, 0.7) 0 1px 1px 0);
}

.bottom-moderate2-s-box-shadow {
  box-shadow: (rgba(0, 0, 0, 0.6) 0 1px 1px 0);
}

.bottom-light-s-box-shadow {
  box-shadow: (rgba(0, 0, 0, 0.3) 0 1px 1px 0);
}

.bottom-mild-s-box-shadow {
  box-shadow: (rgba(51, 51, 51, 0.75) 0 1px 1px 0);
}

.mat-bot-b-shadow {
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mat-bot-s-shadow {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.right-box-shadow {
  // -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // -moz-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.right-only-box-shadow{
 // box-shadow:5px 0 5px -2px rgba(99, 99, 99, 0.2) ;
 box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.top-box-shadow {
  // -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);
  // -moz-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);
  // box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.boxed-box-shadow {
  // -webkit-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.41);
  // -moz-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.41);
  // box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.41);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

/******** Toggle Switch *********/

/* The switch - the box around the slider */

// .switch {
//   position: relative;
//   display: inline-block;
//   width: 52px;
//   height: 26px;
// }

.switch[type="checkbox"] {
  outline: 0;
  user-select: none;
  display: inline-block;
  position: absolute;
  opacity: 0;
}

.switch[type="checkbox"].is-rtl+label {
  padding-left: 0;
  padding-right: 3.5rem;
}

.switch[type="checkbox"]:checked+label::before,
.switch[type="checkbox"]:checked+label:before {
  background: #00d1b2;
}

.switch[type="checkbox"].is-rtl+label::before,
.switch[type="checkbox"].is-rtl+label:before {
  left: auto;
  right: 0;
}

.switch[type="checkbox"]:checked.is-rtl+label::after,
.switch[type="checkbox"]:checked.is-rtl+label:after {
  left: auto;
  right: 1.625rem;
}

.switch[type="checkbox"].is-small+label {
  position: relative;
  display: initial;
  font-size: 0.75rem;
  line-height: initial;
  padding-left: 2.75rem;
  padding-top: 0.2rem;
  cursor: pointer;
}

.switch[type="checkbox"].is-small+label::before,
.switch[type="checkbox"].is-small+label:before {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 2.25rem;
  height: 1.125rem;
  border: 0.1rem solid transparent;
  border-radius: 4px;
  background: #b5b5b5;
  content: "";
}

.switch[type="checkbox"].is-small+label::after,
.switch[type="checkbox"].is-small+label:after {
  display: block;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 0.625rem;
  height: 0.625rem;
  transform: translate3d(0, 0, 0);
  border-radius: 2px;
  background: var(--whiteColor);
  transition: all 0.25s ease-out;
  content: "";
}

.switch[type="checkbox"].is-small:checked+label::before,
.switch[type="checkbox"].is-small:checked+label:before {
  background: #209cee;
}

.switch[type="checkbox"].is-small+label::before,
.switch[type="checkbox"].is-small+label:before {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 2.25rem;
  height: 1.125rem;
  border: 0.1rem solid transparent;
  border-radius: 4px;
  background: #b5b5b5;
  content: "";
}

.switch[type="checkbox"].is-small:checked+label::after {
  left: 1.25rem;
}

.switch[type="checkbox"].is-small+label::after,
.switch[type="checkbox"].is-small+label:after {
  display: block;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 0.625rem;
  height: 0.625rem;
  transform: translate3d(0, 0, 0);
  border-radius: 4px;
  background: var(--whiteColor);
  transition: all 0.25s ease-out;
  content: "";
}

/* layout radio button */

.is-checkradio[type="radio"],
.is-checkradio[type="checkbox"] {
  outline: 0;
  user-select: none;
  display: inline-block;
  position: absolute;
  opacity: 0;
}

.is-checkradio[type="radio"].is-danger+label::before,
.is-checkradio[type="radio"].is-danger+label:before {
  border: 3px solid #f14668;
}

.is-checkradio[type="radio"]+label:first-of-type,
.is-checkradio[type="checkbox"]+label:first-of-type {
  margin-left: 0;
}

.is-checkradio[type="radio"]+label {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 2rem;
}

.is-checkradio[type="radio"]+label,
.is-checkradio[type="checkbox"]+label {
  position: relative;
  display: initial;
  cursor: pointer;
  vertical-align: middle;
  margin: 0.5em;
  padding: 0.2rem 0.5rem 0.2rem 2.5rem;
  border-radius: 4px;
}

.is-checkradio[type="checkbox"]+label {
  font-size: 1rem;
  padding-left: 2rem;
}

.is-checkradio[type="checkbox"]+label::before,
.is-checkradio[type="checkbox"]+label:before {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 4px;
}

.is-checkradio[type="radio"]:checked+label::before,
.is-checkradio[type="radio"]:checked+label:before,
.is-checkradio[type="checkbox"]:checked+label::before,
.is-checkradio[type="checkbox"]:checked+label:before {
  animation-name: none;
}

.is-checkradio[type="radio"]:checked+label::before,
.is-checkradio[type="radio"]:checked+label:before,
.is-checkradio[type="checkbox"]:checked+label::before,
.is-checkradio[type="checkbox"]:checked+label:before {
  border: 0.1rem solid #dbdbdb;
}

.is-checkradio[type="radio"]+label::before,
.is-checkradio[type="radio"]+label:before,
.is-checkradio[type="radio"]+label::after,
.is-checkradio[type="radio"]+label:after {
  width: 1.5rem;
  height: 1.5rem;
}

.is-checkradio[type="radio"]+label::before,
.is-checkradio[type="radio"]+label:before {
  border-radius: 50%;
}

.is-checkradio[type="radio"]+label::before,
.is-checkradio[type="radio"]+label:before,
.is-checkradio[type="checkbox"]+label::before,
.is-checkradio[type="checkbox"]+label:before {
  position: absolute;
  left: 0;
  top: 0rem;
  content: "";
  border: 0.1rem solid #dbdbdb;
}

.is-checkradio[type="radio"].is-info:checked+label::after,
.is-checkradio[type="radio"].is-info:checked+label:after {
  border-color: #209cee !important;
  background-color: #209cee !important;
}

.is-checkradio[type="radio"]:checked+label::after,
.is-checkradio[type="radio"]:checked+label:after,
.is-checkradio[type="checkbox"]:checked+label::after,
.is-checkradio[type="checkbox"]:checked+label:after {
  display: inline-block;
}

.is-checkradio[type="radio"]+label::before,
.is-checkradio[type="radio"]+label:before,
.is-checkradio[type="radio"]+label::after,
.is-checkradio[type="radio"]+label:after {
  width: 1.5rem;
  height: 1.5rem;
}

.is-checkradio[type="radio"]+label::after,
.is-checkradio[type="radio"]+label:after {
  border-radius: 50%;
  background: #209cee;
  left: 0;
  transform: scale(0.5);
}

.is-checkradio[type="radio"]+label::after,
.is-checkradio[type="radio"]+label:after,
.is-checkradio[type="checkbox"]+label::after,
.is-checkradio[type="checkbox"]+label:after {
  position: absolute;
  display: none;
  content: "";
  top: 0rem;
}

.is-checkradio[type="checkbox"].is-info:checked+label::after,
.is-checkradio[type="checkbox"].is-info:checked+label:after {
  border-color: #209cee !important;
}

.is-checkradio[type="checkbox"]+label::after,
.is-checkradio[type="checkbox"]+label:after {
  width: 0.375rem;
  height: 0.6rem;
  top: 0.205rem !important;
  left: 0.6rem;
}

.is-checkradio[type="checkbox"]+label::after,
.is-checkradio[type="checkbox"]+label:after {
  box-sizing: border-box;
  transform: translateY(0rem) rotate(45deg);
  border-width: 0.1rem;
  border-style: solid;
  border-color: #00d1b2;
  border-top: 0;
  border-left: 0;
}

/* Hide default HTML checkbox */

.switch input {
  display: none;
}

/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

//  Scroll bar style
.scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scroll-bar::-webkit-scrollbar {
  width:0px;
  background-color: #f5f5f5;
}

.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px; // background-color: #555;
  background-color: rgb(167, 166, 166);
}

.jaspero__dialog-actions button.primary {
  color: var(--whiteColor) !important;
  background-color: #ff3860 !important;

  &:hover {
    color: var(--whiteColor) !important;
  }
}

.emoji {
  display: inline-block;
}

.cs-media-player {
  .icon {
    height: auto !important;
  }
}

/**Metrial Timepicker style css customize**/

.timepicker {
  header {
    height: 68px !important;
    background-color: var(--primaryColor) !important;
  }

  .timepicker__main-content {
    .timepicker__body {
      .clock-face__number {
        .active {
          background-color: var(--primaryColor) !important;
        }
      }

      .clock-face__clock-hand {
        background-color: var(--primaryColor) !important;
      }
    }
  }

  .timepicker__actions {
    .timepicker-button {
      color: var(--primaryColor) !important;
    }
  }
}

// @import '../node_modules/bulma-extensions/extensions.sass';
/* ==========================================================================

/*  Site Variables Defined   ================================================ */

//First column - Menu Panel
$menu-panel-bg: var(--primaryColor);
$menu-border-color-primary: var(--primaryColor);
$menu-panel-text-color: var(--primaryColor);

//Primary Button
$btn-primary-bg-color: $secondary-color;
$btn-primary-bg-border-color: darken($secondary-color, 15%);
$btn-primary-text-color: $typography-dcolor;
$btn-primary-hover-color: lighten($secondary-color, 10%);
$btn-primary-hover-text-color: darken($typography-dcolor, 15%);


.phone_align {
  .iti {
    width: 100%;
  }
}




/* ==========================================================================
   Notification Container page - styles
========================================================================== */

/*width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    background: #ccc;
    border-radius: 100%;*/

.notification-content-wrapper {
  header {

    padding: 5px 10px 5px 5px;
    z-index: 19;

    .columns {
      width: 100%;
      margin-bottom: 0px !important;
    }

    h3 {
      span {
        font-weight: 600;
      }
    }

    figure {
      max-width: 78px;

      i {
        line-height: 54px;
        padding-left: 4px;
        padding-right: 10px;
        font-size: 14px !important;
        cursor: pointer;
      }

      img {
        width: 48px;
        border-radius: 100%;
      }
    }

    .has-text-right {
      a.desk-icon {
        @extend .display-ib;
        @extend .pad-5-10;
        @extend .pos-relative;

        i {
          vertical-align: middle;
        }

        .is-info {
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
          z-index: 1;
        }
      }

      .dropdown {
        display: none;
      }
    }
  }

  .contents {
    .box {
      // margin: 5px 30px;
      // border-radius: 0px;
      // box-shadow: 0 0px 9px 1px rgba(10, 10, 10, .2), 0 0 0 1px rgba(10, 10, 10, .1);
      // padding: .5rem 1.25rem;
      // margin: 15.8px 0px;
      margin: 20px;
      border-radius: 0px;
      box-shadow: 0 0px 9px 1px rgba(62, 85, 161, 0.31),
        0 0 0 1px rgba(62, 85, 161, 0);
      padding: 1.4rem 1.25rem;

      .column.text-bar {
        padding-left: 0;
      }

      .image img {
        max-width: 40px; // max-width: 48px;
        height: auto;
        margin-top: 6px;
        border-radius: 100%;
      }
    }

    .box:last-child {
      margin-bottom: 40px;
    }

    .studyroomListView {
      font-size: 14px;

      .button {
        border-radius: 30px;
        width: 31px;
        height: 31px;
        border: none;

        .fa {
          font-size: 15px !important;

          &:hover {
            color: var(--whiteColor);
          }
        }

        &:hover {
          background-color: var(--primaryColor);
          color: #fff !important;
        }
      }
    }

    .activeGroupUser {
      .delete-user {
        padding: 0px 5px;
        display: none;
        color: #b1b1b1;
        position: absolute;
        // top: 22px;
        right: 15px;
        bottom: 15px;
        cursor: pointer !important;

        &:hover {
          color: var(--primaryColor);
        }
      }

      .groupUserList {
        &:hover {
          .delete-user {
            display: block;
          }
        }
      }
    }
  }

  .reply {
    i.fa {
      font-size: 12px !important;
      vertical-align: middle;
      color: var(--primaryColor);
    }
  }

  .notifications {
    .media {
      .media-content {
        .content {
          .post-detail {
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.15px;

            .creater-name {
              color: var(--primaryColor);
              margin-right: 5px;
            }

            .sub-post-content {
              color: #363636;
            }
          }
        }
      }
    }

    .media .media {
      padding-top: 5px;
      padding-bottom: 7px;

      .cmt-u-name {
        font-size: 12px;
      }

      .timeago {
        font-size: 12px;
      }

      .media-content {
        .content {
          .cmt-text {
            line-height: 18px;

            .sub-cmt-link {
              padding-left: 12px;
              font-size: 12px;
            }
          }
        }
      }
    }

    .media+.media {
      margin-top: 0;
      padding-top: 5px;
    }
  }

  .post-attachment {
    .post-img {
      position: relative;
      max-width: 300px;
      height: auto;
      margin: 5px 0px;
      overflow: hidden;

      // box-shadow: 0 8px 10px 1px rgba(143, 158, 181, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(143, 158, 181, 0.2);
      img {
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
          0 0 0 1px rgba(10, 10, 10, 0.1);
      }

      i.fa-play-circle {
        @extend .pos-absolute;
        top: 0%;
        background: #000;
        width: 100%;
        opacity: 0.5;
        padding: 30%;
        color: var(--whiteColor);
        cursor: pointer;
        font-size: 24px !important;
      }

      .fa-times {
        position: absolute;
        right: 0px;
        top: 0;
        background: #000;
        color: var(--whiteColor);
        padding: 10px 15px;
        opacity: 0.5;
        cursor: pointer;
        display: none;
      }
    }

    .post-img.notify-play {
      max-width: 350px;

      &:hover {
        .fa-times {
          display: block;
        }
      }
    }
  }

  // .elibraryTileView{
  //   .header-elibrary{
  //     .actionBtnMobileView{
  //       display: none;
  //     }
  //   }
  // }
}

.post-attach-file {
  .profile-img {
    width: 250px;
    height: 150px;
    margin: 0;
    padding-left: 10px;

    img {
      border-radius: 0;
      min-width: 250px;
      max-width: 250px;
      max-height: 160px;
    }
  }
}

.textbar-mobile {
  display: none;
}

.chat-user-status {
  position: relative;

  .icon {
    position: absolute;
    top: 30px;
    right: -2px;

    i.fa {
      font-size: 12px !important;
    }
  }
}

.notification-content-wrapper+.fo-p {
  max-width: 340px;
  position: relative;

  >div {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 10;
    box-sizing: border-box;
    border-left: 1px solid rgba(0, 0, 0, 0.03);
    transition: background 0.3s ease-out 0.1s;
    width: 100%;
    height: 100%;
    position: absolute;
    background: $info-panel-bg;
  }
}

.help-page {
  .help-head-name {
    img {
      margin-right: 5px;
      max-width: 36px;
      border-radius: 100%;
    }

    h3 {
      margin-top: 5px !important;
      font-size: 20px;
    }
  }

  .help-content {
    .box {
      padding: 15px 10px;
      border-radius: 3px;

      .help-list {
        border-right: 1px solid #e8e8e8;

        ul {
          min-width: 180px;

          li {
            a {
              padding: 5px;
              font-size: 15px;
              display: block;
            }

            a.active {
              color: var(--primaryColor);
              font-weight: 600;
            }
          }
        }
      }

      .help-details {
        h3 {
          border-bottom: 1px solid #e4e4e4;
          padding-bottom: 10px;
        }
      }
    }
  }
}

//Privacy new page css modified
.privacy-page {
  .privacy-head-name {
    img {
      margin-right: 5px;
      max-width: 36px;
      border-radius: 100%;
    }

    h3 {
      margin-top: 5px !important;
      font-size: 20px;
    }
  }

  .privacy-content {
    .box {
      padding: 15px 10px;
      border-radius: 3px;

      .privacy-list {
        border-right: 1px solid #e8e8e8;

        ul {
          min-width: 180px;

          li {
            a {
              padding: 5px;
              font-size: 15px;
              display: block;
            }

            a.active {
              color: var(--primaryColor);
              font-weight: 600;
            }
          }
        }
      }

      .privacy-details {
        h3 {
          border-bottom: 1px solid #e4e4e4;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.fo-p {
  .list-wrapper.list-overflow {
    height: calc(100vh - 150px);
  }

  .user-info-list {
    height: calc(100vh - 310px);
    overflow-y: auto;
  }

  li {
    label {
      line-height: 20px;
    }

    .is-one-quarter {
      img {
        max-width: 48px;
      }

      .icon {
        bottom: 5px;
        right: 20px;

        .fa {
          // font-size: 14px !important;
          font-size: 12px !important;
        }
      }
    }

    .columns.is-gapless {
      margin-bottom: 0 !important;
    }
  }

  .group-members {
    li {
      .is-pulled-left {
        .time-ago {
          font-size: 0.6rem;
          cursor: default;
        }
      }

      .chat-close {
        .fa {
          top: 32px;
        }
      }
    }
  }

  .chat-check {
    .fa {
      padding: 0px 5px;
      color: #565c78;
      position: absolute;
      top: 25px;
      right: 7px;
      cursor: pointer !important;
    }
  }

  .fourth-panel-tab {
    margin-bottom: 0;
    padding-top: 10px;

    ul {
      li {
        a {
          padding: 0.7em 1em;

          &:hover {
            color: var(--primaryColor) !important;
            border-bottom-color: var(--primaryColor) !important;
          }
        }
      }

      li.is-active {
        a {
          color: var(--primaryColor) !important;
          border-bottom-color: var(--primaryColor) !important;
        }
      }
    }
  }

  .file-list-sec {
    ul {
      li {

        // padding: 10px;
        .file-list {

          // display: flex;
          // line-height: 14px;
          .file-type {
            i.fa {
              font-size: 14px !important;
              padding-right: 10px;
              padding-top: 5px;
            }
          }

          .file-name-list {
            font-size: 14px;
            max-width: 240px;
            width: 220px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
          }
        }

        .file-download {
          i.fa {
            font-size: 14px !important;
            position: absolute;
            right: 0;
            top: 6px;
            padding: 5px 10px;
            color: #d3d3d3;
            cursor: pointer;
          }
        }

        .file-date {
          margin-top: 5px;
          display: inline-block;
          padding-left: 30px;
        }

        .text-clr-red {
          float: right;
          color: brown;
          text-transform: capitalize;
        }

        .file-size {
          font-size: 12px !important;
          position: absolute;
          right: 0;
          bottom: 0px;
          padding: 5px 10px;
          display: none;
        }
      }

      li {
        &:hover {

          // background: transparent;
          .file-download {
            i.fa {
              color: #2f2e2e;
            }
          }

          .file-size {
            display: block;
          }
        }
      }
    }
  }
}

.notific-user {
  li {
    padding: 10px;

    .notfic-resend-btn {
      margin-left: 10px;
    }
  }
}

.group-details app-new-group {
  // height: calc(100vh - 117px);
  height: calc(100vh - 122px);
}

.loader-progress {
  width: 110px;
  position: absolute;
  bottom: 5%;
  left: 1%;
  text-align: center;

  .percent-value {
    font-weight: bold;
    color: var(--whiteColor);
  }

  .progress.is-info::-webkit-progress-value {
    background-color: var(--primaryColor) !important;
  }

  .progress {
    height: 8px;
  }
}

.custom-search {
  position: relative !important;

  .control.has-icons-left .input {
    padding-left: 5em;
    padding-right: 4em;
  }

  .mic-icon {
    position: absolute;
    left: 36px;
    top: 1px;
    padding: 8px 10px 8px;
    background: var(--whiteColor);
    border-right: 1px solid #e6e3e3;
    border-radius: 1px;
  }
}

.app-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #303030;
  color: var(--whiteColor);
  font-size: 12px;
  padding: 2px 10px;
  text-align: right;
  z-index: 10;
}

.file-links {
  .linkified {
    color: var(--primaryColor) !important;
  }
}

.create-upload {
  .file-label {
    font-size: 14px;
  }

  .file-icon .fa {
    font-size: 14px !important;
  }

  .file .file-cta {
    background-color: var(--primaryColor) !important;
    color: $complete-white-color;
  }

  .dz-default {
    display: inline-block;
  }

  .pos-relative {
    .field {
      display: inline-block;

      .file {
        bottom: 6px;
        position: absolute;
      }
    }
  }
}

.badge-count {
  border-radius: 100px;
  background-color: $listing-panel-badge-bg;
  color: $listing-panel-badge-text-color;
  height: auto !important;
  padding: 1px 8px;
  font-size: 10px;
  position: absolute;
  bottom: 5px;
  right: 0;
}

.time-ago {
  position: absolute;
  right: 0;
  top: 0;
}

.opacity-bg {
  background: #8da0b3 !important;
}

.opacity-3 {
  opacity: 0.3;
}

.create-icon {
  .fa-trash {
    // position: absolute;
    // right: 0;
    // display: none;
    // padding: 5px 7px;
    // cursor: pointer;
    position: absolute;
    right: -27px;
    display: none;
    font-size: 18px !important;
    bottom: -6px;
    padding: 5px 7px;
    cursor: pointer;
    color: #c1c1c1;
  }

  img {
    width: 90px;
    height: 80px;
  }
}

.create-icon:hover .fa-trash {
  display: block;
}

.file-upload {
  position: absolute; // position: fixed;
  bottom: 60px;
  // bottom: 75px;
  right: 87px;
  width: 350px;
  background: var(--whiteColor); // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  // padding: 6px 10px;
  z-index: 10000;
  font-size: 14px;

  .accordion {
    .accordion-header {
      background: #6b6b6b;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .accordion-content {
      background: var(--whiteColor);
    }
  }

  .accordion:hover .accordion-header {
    opacity: 1;
  }

  .pos-relative {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dcdcdc;
  }

  .pos-relative:last-of-type {
    margin-bottom: 0px;
    padding-bottom: 0;
    border: none;
  }

  .file-detail {
    margin-right: 30px;
    position: absolute;
    top: 0px;
    left: 55px;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 200px;
      overflow: hidden;
    }

    .file-size {
      font-size: 12px;
    }

    .content-align {
      position: relative;

      .progress {
        height: 0.25rem;
        position: absolute;
        bottom: 10px;
        right: 5px;
        width: 68%;
      }
    }
  }

  .fa-times {
    position: absolute;
    right: -10px;
    top: 5%;
    padding: 15px;
    cursor: pointer;
  }
}

.profile-img {
  position: relative;
  width: 120px;
  margin: 5px auto;

  img {
    max-width: 100px;
    min-width: 100px; // max-width: 120px;
    border-radius: 100%; // border: 3px solid #dcdcdc;
    // padding: 3px;
    box-shadow: 0 8px 10px 1px rgba(143, 158, 181, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12),
      0 5px 5px -3px rgba(143, 158, 181, 0.2);
    cursor: pointer;
  }

  .img-options {
    position: absolute;
    bottom: 10px;
    right: 10px; // padding: 5px 10px;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 3px;
    border-radius: 100%;
    background:var(--primaryColor);
    cursor: pointer;

    .fa {
      color: var(--whiteColor) !important;
      padding: 6px;
      font-size: 12px !important;
    }

    .file-label {
      font-size: 14px;
      // padding-left: 7px;
      white-space: nowrap;
    }

    // .dropdown .dropdown-menu{
    //     width: 320px;
    // }
    // .dropdown .dropdown-menu .dropdown-content {
    //   -webkit-transition: 3s ease-out;
    //   -moz-transition: 3s ease-out;
    //   -o-transition: 3s ease-out;
    //   -ms-transition: 3s ease-out;
    //   transition: 3s ease-out; //width: 50px;
    // }
  }

  // .img-options .dropdown-menu .dropdown-content.drop-width{
  //     width: 300px;
  // }
}

.se-p .user-status {
  position: relative;

  .fa {
    position: absolute;
    right: 10px;
    bottom: 40px;
    // bottom: 5px;
    font-size: 8px !important;
  }

  .timeAgo {
    position: absolute;
    right: 10px;
    bottom: 5px;
  }
}

.group-img-edit {
  .img-options {
    position: absolute;
    bottom: -15px;
    right: 5px;
    width: 16px;
    height: 16px;
    text-align: center;
    padding-top: 0px;
    border-radius: 100%;
    background: var(--primaryColor);
    cursor: pointer;
    .fa-solid{
      color: var(--whiteColor) !important;
    }
    .fa {
      color: var(--whiteColor) !important;
      padding: 4px;
      font-size: 8px !important;
    }

    .file-label {
      font-size: 14px;
      padding-left: 7px;
      white-space: nowrap;
    }

    .dropdown-menu {
      top: 70%;
    }
  }
}

.showAssignment {
  .modal {
    .modal-background {
      background-color: #0a0a0a85 !important;
    }

    .modal-card {
      .modal-card-body {
        .content {
          .isSelectHightlight {
            background-color: #c2dbff;
            color: #131313;
          }

          .selQtHighlight {
            background-color: #c2dbff;
          }
        }
      }
    }
  }

  .watermark_title {
    position: absolute;
    z-index: 9999;
    // background:white;
    display: block;
    min-height: 50%;
    min-width: 50%;

    // color:yellow;
    .bg-text {
      color: #bec8ce;
      font-size: 120px;
      // font-weight: bold;
      transform: rotate(300deg);
      -webkit-transform: rotate(300deg);
    }
  }

  .is-modal-active {
    visibility: visible;
    display: flex;
  }

  .is-topic-modal {
    display: flex;
    visibility: visible;
  }

  .card-header {
    background-color: var(--primaryColor);

    .card-header-title {
      color: $complete-white-color;
    }

    .delete {
      position: relative;
      top: 8px;
    }
  }

  .card {
    .assQuestTest {
      .scroll-bar::-webkit-scrollbar {
        width: 10px !important;
      }
    }
  }

  .card-content {
    overflow-y: auto;
    max-height: calc(100vh - 168px);

    .content {
      .ngx-datepicker-calendar-container {
        z-index: 9 !important;
      }

      .ngx-datepicker-input {
        padding: 10px 10px !important;
        width: 271px !important;
        border-radius: 4px;
      }

      // .newTopicAdd{
      //   [data-tooltip]:not(.is-disabled)::before,
      //   [data-tooltip]:not(.is-loading)::before,
      //   [data-tooltip]:not([disabled])::before{
      //     white-space: pre-wrap !important;
      //     left: 19% !important;
      //     width: 495px !important;
      //   }
      // }
      .question {
        ul {
          margin-left: 1rem;

          .form-section {
            // padding: 0px 0px 0px 0px;
            list-style: none !important;
            list-style-position: outside;
            margin: 0px;
            padding: 0px;
            position: relative;
            zoom: 1;
            clear: both;
          }
        }

        .form-line[data-type="control_radio"],
        .form-line[data-type="control_checkbox"] {
          display: flex;
          flex-wrap: wrap;
        }

        .form-line[data-type="control_radio"] .form-input-wide,
        .form-line[data-type="control_checkbox"] .form-input-wide {
          width: 100%;
          line-height: 2;

          .form-single-column {
            .form-radio-item {
              .field {
                label {
                  color: #ccc;

                  &:hover {
                    color: var(--primaryColor);
                  }
                }

                .is-checkradio[type="radio"]:checked {
                  label {
                    color: var(--primaryColor);
                  }
                }
              }
            }
          }
        }

        .form-line {
          // padding: 12px 36px;
          margin-top: 12px;
          margin-bottom: 12px;

          .form-label {
            .form-label-auto {
              display: block;
              float: none;
              word-break: break-word;
              text-align: left;
            }
          }
        }
      }

      .progress-wrapper {
        position: relative;
        margin: 20px auto;
        font-size: 40px;

        .currentResult {
          position: absolute;
          color: #bbb;
          font-weight: 100;
          line-height: 1;
        }
      }
    }

    .surveyquiz {
      line-height: 2.5;
    }
  }

  .hidePreviousBtn {
    display: none;
  }

  .card-footer {

    // position: relative;
    // right: -60%;
    .footer-elibrary {
      padding: 13px;
      position: absolute;
      right: 9px;
    }

    .taskSubmit {
      margin-right: 10px;
    }

    .pagination {

      // position: absolute;
      .is-outlined {
        background-color: transparent;
        border-color: var(--primaryColor);
        color: var(--primaryColor);

        &:hover {
          background-color: var(--primaryColor);
          border-color: var(--primaryColor);
          color: var(--whiteColor);
        }
      }

      .pagination-next {
        // float: right;
        position: absolute;
        right: 9px;
        width: 100px;
      }

      .pagination-previous {
        margin-left: 15px;
        width: 100px;
        // position: relative;
        // right: 119%;
      }

      .btnQuizisRequired {
        margin-left: 15px;
        width: 200px;

        .fa {
          color: #d62e2e;
        }
      }

      .hidePreviousBtn {
        display: none;
      }

      .isHiddenBtn {
        display: none;
      }

      .isShowedBtn {
        display: block !important;
      }

      .answerSubmit {
        position: absolute;
        right: 4px;
        top: 12px;
        color: $complete-white-color;
      }
    }
  }
}

//staff room style css
.staffRoomRecords {
  .tabs.is-toggle a {
    border-color: none !important;
    border-style: none !important;
    border-width: 0 !important;
  }

  .topTileview {
    .tile {
      .title {
        font-size: 18px;
      }

      .subtitle {
        font-size: 12px;
        color: #b1b1b1;
      }
    }
  }
}

.homework_wrapper {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInRight;

  .columns {
    margin-bottom: 0px;
  }

  .field-body {
    flex-grow: 3;
  }

  .setting-notific {
    .table {
      margin-bottom: 10px;

      thead th,
      tbody td {
        font-size: 14px;
        border: 1px solid #e6e6e6;
      }
    }

    .reminder {
      margin-bottom: 10px;

      strong {
        font-size: 14px;
        text-transform: uppercase;
      }

      .remain-text {
        span {
          font-size: 12px;

          i.fa-info-circle {
            margin: 4px 10px;
          }
        }
      }
    }

    .desktop-notific {
      strong {
        font-size: 14px;
        text-transform: uppercase;
      }

      .notific-text {
        font-size: 12px;
      }

      .columns {
        margin-top: 0px;
      }
    }
  }

  .homework_sort {
    text-align: center;
    color: $subtle-dcolor;
    margin-top: 10px;
    border: 1px solid #ccc;
    background: $complete-white-color;
    height: 42px;
    border-radius: 6px;
    margin-right: 25px;

    &:hover {
      color: var(--primaryColor);
      cursor: pointer;
    }
  }

  .homework_filter {
    text-align: center;
    color: $subtle-dcolor;
    margin-top: 10px;
    border: 1px solid #ccc;
    background: $complete-white-color;
    height: 42px;
    border-radius: 6px;
    margin-right: 12px;

    &:hover {
      color: var(--primaryColor);
      cursor: pointer;
    }
  }

  .homework-column {
    display: none;

    .customFilter {
      width: 452px;
    }
  }

  .activeFilter {
    color: $complete-white-color !important;
    background: var(--primaryColor) !important;
  }

  .active-column {
    display: block !important;
  }

  .accordions {
    margin-top: 20px;

    .timing {
      span {
        padding: 5px 10px;
        border-radius: 5px;
        color: #909090;
        font-size: 12px;
        font-weight: 600;
        display: inline-block;
        background: #f7f7f7;
      }
    }

    .accordion {

      // margin-bottom: 1.25rem !important;
      .accordion-header {
        background: $complete-white-color !important;
        color: var(--primaryColor) !important;
        font-weight: 600;
      }
    }
  }
}

.user-settings {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInRight;

  .columns {
    margin-bottom: 0px;
  }

  .field-body {
    flex-grow: 3;
  }

  .setting-notific {
    .table {
      margin-bottom: 10px;

      thead th,
      tbody td {
        font-size: 14px;
        border: 1px solid #e6e6e6;
      }
    }

    .reminder {
      margin-bottom: 10px;

      strong {
        font-size: 14px;
        text-transform: uppercase;
      }

      .remain-text {
        span {
          font-size: 12px;

          i.fa-info-circle {
            margin: 4px 10px;
          }
        }
      }
    }

    .desktop-notific {
      strong {
        font-size: 14px;
        text-transform: uppercase;
      }

      .notific-text {
        font-size: 12px;
      }

      .columns {
        margin-top: 0px;
      }
    }
  }
}

.content-height-calc {
  height: calc(100vh - 172px);
  overflow-y: auto;
  overflow-x: hidden;
}

.content-height-calc.columns:not(:last-child) {
  margin-bottom: calc(1.75rem - 1.25rem);
}

.up-btn-fix {

  // box-shadow: 1px -2px 4px 0px rgba(0, 0, 0, 0.1);
  .column {
    background: var(--whiteColor);
    padding: 0.75rem 2.75rem;
  }
}

.up-btn-fix.columns {
  margin-left: 0.02rem;
}

.preview-layout {
  margin-bottom: 10px;
  .field{
    display: flex;
    justify-content: center;
    .is-checkradio[type="radio"]+label::before {
      top: 0px;
    }
  
    .is-checkradio[type="radio"]+label::after {
      top:0px;
    }
  }
  label {
    margin-right: 25px !important;
  }

 
}

.modal {

  .modal-card-head,
  .modal-card-foot {
    padding: 10px 20px;
  }

  .modal-card-foot .column {
    padding: 0;
  }
}

.icon-align {
  right: 12px;
  bottom: 5px;
  position: absolute; // color: #00d1b2;
  color: var(--primaryColor);
}

.control.has-icons-right .icon.is-right {
  pointer-events: auto;
}

.form-style {
  h3 {
    padding-bottom: 10px;
    border-bottom: 1px solid #dbdbdb;
  }
}

input[type="file"] {
  display: none;
}

.hide-listwrapper {
  // transition: all ease-in-out .4s;
  z-index: 10000;
  display: none;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
}

.box-overflow {
  // height: calc(100vh - 64px);
  height: calc(100vh - 69px);
  overflow-y: auto;
}

.quickview {
  top: 52px !important;
}

.create-sub-post {
  // background: #3c3b3b;
  background: var(--whiteColor); // box-shadow: none;
  position: absolute;
  top: 57px;
  z-index: 100; // width: 60%;
  width: 0%;
  border-radius: 0;
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  margin-left: 15%;
  overflow: hidden;
  display: none; // height: 0px;
  padding: 0;
  transition: width 1s ease-in;
  -webkit-transition: width 1s ease-in;

  .textarea {
    padding: 0.4em;
  }

  // .media {
  //   width: 75% !important;
  //   max-width: 75%;
  //   margin: 0 auto;
  // }
}

.post-animate {
  display: block; // height: 150px;
  padding: 1.25rem;
  width: 60%; // animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  // transition: height 1s ease-out;
  // -webkit-transition: height .5s ease-out;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;
}

.edit-text-box {
  .input {
    height: 1.95em !important;
    width: auto;
    font-size: 14px !important;
  }

  .text-input {
    height: 2.05em !important;
    width: 70% !important;
  }

  .options {
    display: inline-block;
    vertical-align: text-bottom;

    i.fa {
      // padding: 10px 5px;
      font-size: 14px !important;
    }
  }

  .textarea {
    overflow: hidden; // height: 36px !important;
    min-width: 60%;
    padding: 5px 5px;
    width: 70% !important;
    display: inline-block;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
  }
}

.modal {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;
}

.empty-img {
  width:35px;
  height:35px;
  border-radius: 100%;
  background: #f4f4f4;
  vertical-align: middle;
  text-align: center; // font-size: 28px;
  // padding-top: 2px;
  font-size:18px;
  color: #010101;

}

.empty-head-icon {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background:#f4f4f4 !important;
  color:#010101 !important;
  vertical-align: middle;
  text-align: center;
  font-size:18px;
  margin-right: 5px;
  padding-top: 1px;
}

.empty-profile-icon {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: var(--primarylightColor);
  vertical-align: middle;
  text-align: center;
  font-size: 35px;
  padding-top: 23%;
  cursor: default;
}

.fa.fa-font-size {
  font-size: 14px !important; // padding: 7px 2px;
}

.disable-fa-edit {
  cursor: not-allowed;

  i.fa {
    pointer-events: none !important;
  }
}



 

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  cursor: pointer;
  padding: 1px;
  margin: 10px 0px 0px 10px;
}

.beforeLayout .is-checkradio[type="radio"]+label::before {
  top: 0px;
}

.beforeLayout.preview-layout .is-checkradio[type="radio"]+label::after {
  top: 0px;
}

.audio_view {
  height: calc(100vh - 113px);
  background: #2e3246;
  opacity: 0.75;

  .audio_content {
    padding-top: 12%;
    text-align: center;
    color: $complete-white-color;

    .empty-head-icon {
      width: 120px;
      height: 120px;
      padding-top: 30%;
      background: var(--whiteColor);
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 36px;
      margin-right: 0;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
    }

    .is-small {
      font-size: 11px;
      margin-bottom: 12px;
    }

    .audio_btn,
    .option_btn {
      height: 36px;
      max-height: 36px;
      max-width: 36px;
      min-height: 36px;
      min-width: 36px;
      width: 36px;
      -webkit-appearance: none;
      background-color: var(--whiteColor);
      border: none;
      border-radius: 290486px;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.6);
      cursor: pointer;
      pointer-events: auto;
      display: inline-block;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 0;
      outline: 0;
      position: relative;
      vertical-align: top;

      .fa {
        padding-top: 9px;
      }

      .fa.rotate-img {
        color: #d62e2e;
        transform: rotate(134deg);
        padding: 0;
        margin-top: 10px;
      }
    }

    .conference_icon {
      padding-top: 30px;
    }

    .option_btn {
      margin-left: 12px;

      .fa {
        color: var(--primaryColor);
        padding-top: 9px;
      }

      &:hover {
        .fa {
          color: #1346ec;
        }
      }
    }
  }
}

.message {
  margin-bottom: 0px !important;
}

.audio_receiver {
  position: relative;

  .audio_rec_view {
    position: fixed;
    bottom: 70px;
    right: 60px;
    padding: 15px 40px;
    text-align: center;
    background: #2e3246;
    opacity: 0.75;
    color: $complete-white-color;

    .empty-head-icon {
      width: 36px;
      height: 36px;
      background: var(--whiteColor);
      margin-bottom: 12px;
      font-size: 22px;
      margin-right: 0;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
    }

    .is-small {
      font-size: 11px;
      margin-bottom: 12px;
    }

    .audio_btn,
    .option_btn {
      height: 36px;
      max-height: 36px;
      max-width: 36px;
      min-height: 36px;
      min-width: 36px;
      width: 36px;
      margin: 0px 5px;
      -webkit-appearance: none;
      background-color: var(--whiteColor);
      border: none;
      border-radius: 290486px;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.6);
      cursor: pointer;
      pointer-events: auto;
      display: inline-block;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 0;
      outline: 0;
      position: relative;
      vertical-align: top;

      .fa {
        color: hsl(141, 71%, 48%);
        padding-top: 9px;
      }

      .fa.rotate-img {
        color: #d62e2e;
        transform: rotate(134deg);
        padding: 0;
        margin-top: 10px;
      }
    }
  }
}

.audio_receiver.user_minimiz {
  .empty-profile-icon {
    width: 48px;
    height: 48px;
    font-size: 18px;
    padding-top: 10px;
    margin: 15px auto 15px;
  }

  .text-primary {
    font-size: 12px;
    margin-left: 10px;
  }
}

/* ==========================================================================
  @media tab view styles
  ========================================================================== */

@media screen and (min-width: 400px) and (max-width: 1007px) {
  .chats .box {
    max-width: 83%;
  }

  .chats .chat-name {
    .fa-angle-double-left {
      line-height: 36px;
      padding-right: 7px;
      font-size: 24px;
    }
  }

  .chats {
    .chat-file-type {
      .audio-file {
        span.file-name-msg {
          max-width: 163px;
        }
      }
    }
  }

  .flex-grid .fi-p {
    max-width: 59px;

    a {
      border: none;

      span {
        display: none;
      }
    }
  }

  .flex-grid .se-p {
    //flex: 0 1 250px;
    // min-width: 350px !important;
  }

  .list-wrapper {
    ul.notification-list {
      li {
        .is-size-5 {
          font-size: 1.1rem !important;
        }

        .room-delete {
          i.fa {
            padding: 0px 5px;
            display: none;
            color: #b1b1b1;
            position: absolute;
            top: 22px;
            right: 7px;
            cursor: pointer !important;

            &:hover {
              color: $listing-panel-pri-text-color;
            }
          }
        }
      }
    }
  }

  .notification-content-wrapper {
    header {
      h3 {
        span {
          font-size: 1.1rem !important;
        }
      }

      .has-text-right {
        .desk-view {

          // display: none;
          .fullViewBtn {
            display: none;
          }

          .mobileViewBtn {
            display: block !important;
          }
        }

        .dropdown {
          display: block;

          .dropdown-trigger {
            padding-top: 5px;
          }

          .dropdown-content {
            text-align: left;

            .dropdown-item {
              .fa {
                width: 20px;
              }
            }
          }
        }
      }
    }
  }

  .textbar-desk {
    display: none;
  }

  .textbar-mobile {
    display: block;
  }

  .connectionMonitor {
    position: absolute !important;
    right: 0px !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 1250px) {
  .chats .timing span.time-history::after {
    width: 33%;
    webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }

  .chats .timing span.time-history::before {
    width: 33%;
    webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .chats {
    .chat-file-type {
      .audio-file {
        span.file-name-msg {
          max-width: 163px;
        }
      }
    }
  }



  .notification-content-wrapper {
    header {
      height: 75px;
      padding: 5px 15px 5px 5px !important;

      .columns {
        width: 100%;
      }

      h3 {
        span {
          font-weight: 600;
          font-size: 1rem !important;
        }
      }

      figure {
        max-width: 78px;

        i {
          line-height: 54px;
          padding-right: 7px;
          font-size: 14px !important;
        }

        img {
          width: 48px;
          border-radius: 100%;
        }
      }

      .has-text-right {
        &>a {
          display: none !important;
        }

        .dropdown-item {
          display: block;
        }

        .desk-view {

          // display: none;
          .fullViewBtn {
            display: none;
          }

          .mobileViewBtn {
            display: block !important;
          }
        }
      }
    }

    .topicListView {
      overflow-x: auto;
    }

    .box {
      margin: 10px !important;
      //  margin: 15.8px -5px !important;
    }

    .studyroomListView {
      font-size: 12px !important;
    }

    .groupMobileView {
      .desk-view {
        display: block !important;
      }
    }

    .elibPlaying-Media {
      .columns {
        .column {
          .card {
            .card-content {
              .media {
                .selectFileRemove {
                  position: absolute;
                  right: 30px;
                  top: 10px;
                }

                .cs-mediaplayer {
                  .mediaDocFile {
                    .thumb_play {
                      position: absolute;
                      top: 48%;
                      left: 30%;
                      display: block;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }

          .card-footer {
            .has-text-left {
              position: absolute;
            }
          }
        }
      }
    }

    .library-dashboard-view {
      .columns {
        margin-right: 0rem !important;
        margin-left: -2px;

        .box {
          margin: 2.8px -5px !important;
          position: relative;
          top: 15px;
        }
      }
    }

    .mobileViewuserandgroup {
      .column {
        .is-offset-one-quarter {
          margin-left: 0px !important;
        }
      }

      .columns {
        .is-mobile {
          .column.is-three-quarters {
            width: 100%;
          }

          .column.is-two-thirds {
            width: 100%;
          }
        }
      }
    }
  }

  .modalMobileView {
    .has-text-right {
      position: absolute;
      right: 10px;
    }
  }

  .userandgroupDetails {
    width: 170px;
    padding: 10px 0px;
    text-align: right;

    .userDesktopView {
      display: none;
    }

    .userMobileView {
      display: contents;
    }

    .button {
      .fa {
        margin-right: 15%;
      }
    }
  }

  .display-flex {
    display: flex !important;

    .level-left+.level-right {
      margin-top: 0;
    }
  }

  .list-wrapper li .is-one-quarter {
    flex: none;
    width: 25%;
  }
}




@media only screen and (max-width: 1350px) {
  .responsiveRadioBtn {
    display: block !important;

    label {
      display: block !important;

      &::before {
        display: block !important;
      }
    }
  }
}


/* Hide default HTML checkbox */


/* The slider */



/* Rounded sliders */



//  Scroll bar style
.scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scroll-bar::-webkit-scrollbar {
  width:0px;
  background-color: #f5f5f5;
}

.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px; // background-color: #555;
  background-color: rgb(167, 166, 166);
}




.chatBotstyle {
  #close-btn {
    position: absolute;
    width: 24px;
    height: 24px;
    right: -399px;
    top: 14px;
    cursor: pointer;
    z-index: 99999;
  }

  iframe {
    position: absolute;
    height: 100%;
    top: 56px;
    z-index: 99999;
    width: 411px;
    left: 89px;
    height: 92%;
  }
}

ngx-guided-tour .tour-step .tour-buttons .next-button {
  padding: 6px 15px;
  background: var(--primaryColor) !important;
}

ngx-guided-tour .tour-step .tour-content {
  color: #666;
}

ngx-guided-tour .tour-step .tour-buttons button.link-button {
  padding: 3px 15px;
}

.grid-container {
  display: grid;
  grid-template-areas: "header-container" "menu-container";
}

.header-container {
  grid-area: header-container;
}

.header-container{
  .nav-item{
    background: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-top:4px;
    margin-left:4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.menu-container {
  grid-area: menu-container;
}

body.scroll-stop {
//  overflow-y: hidden !important;
}

.disabled {
  opacity: 0.5;
}

#drag-button-tools {
  float: right;

  button {
    margin-right: 10px;
    border: 1px solid hsl(217, 71%, 53%);

    .fa {
      color: hsl(217, 71%, 53%);
    }
  }

  button:hover {
    background: hsl(217, 71%, 53%);

    i {
      color: var(--whiteColor);
    }
  }
}

app-e-library-tile {
  .flex-container {
    // margin-top:40px !important;
  }

  .tools {
    display: inline-block;
    margin-top: 8px;
    margin-right: 5px;

    a {
      margin: 5px;
    }

    .fa {
      font-size: 15px !important;
    }
  }
}

.second-level-menu {
  // min-width: 320px;
  // max-width:340px;
  width:250px;
}



.chat-content {
  display: flex;
  flex-direction: column;
  flex: 2 2 250px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  form {
    .label {
      text-align: left;
    }
  }

  .app-signin,
  .signup-container {
    height: calc(100vh - 30px) !important;
    overflow-y: auto;

    .banner-img {
      display: none;
    }
  }

  .forgot-password {
    .column {
      text-align: center;
    }

    .banner-img {
      display: none;
    }

    .banner-column {
      display: none;
    }
  }

  .signup-main-container {
    .banner-column {
      display: none;
    }

    .banner-img {
      display: none;
    }

    .abs-logo {
      max-width: 30%;
    }

    .login-form {
      // height: 500px;
      // overflow-y: auto;
    }

    .otp-verification-container {
      .banner-img {
        display: none;
      }
    }
  }

  .second-level-menu {
    width: 100%;
  }

  .second-level-menu.hide {
    display: none;
  }

  app-single-chat.flex-grid.hide {
    display: none;
  }

  .chat-message-container {

    .control-edit-icon {
      padding: 17px;
      display: fix;
      position: fixed;
      background: white;
      width: 100%;
      display: grid;
      z-index: 1;
      left: 0;
      margin: 10px
    }

    .input {
      margin: 7px 0px;
    }

  }

  app-video-quiz-details.flex-grid.hide {
    display: none;
  }

  app-ai-assist,
  app-video-quiz-details {
    .mobileViewBtn {
      display: block;
    }
  }

  app-single-chat {
    .hide-chat {
      display: none !important;
    }
  }

  .hideMobileView {
    display: none;
  }

  #chat-message-enter-box {
    width: 100% !important;
    margin-left: 0px !important;
  }

  // #chat-list-content {
  //   width: 90% !important;
  //   margin-left: 5% !important;
  // }



  .edit-text-box {
    .input {
      width: 100%;
      height: 2rem;
    }

    .options {
      margin-top: 10px;
    }
  }


  app-group-userlist {
    width: 100%;

    .list-wrapper {
      .columns {
        display: flex;
      }
    }
  }

  .comment-area-box {
    width: auto !important;
  }

  .chat-mesg-container {
    .control-edit-icon {
      padding: 17px;
      display: fix;
      position: fixed;
      background: white;
      width: 100%;
      display: grid;
      z-index: 1;
      left: 0;
      margin: 10px
    }

    .input {
      margin: 7px 0px;
    }
  }

  app-chat-list,
  app-e-library,
  app-user,
  app-group,
  app-video-quiz {
    .second-level-menu {
      .list-wrapper li .is-one-quarter {
        width: 20%;
      }
    }
  }


}

@media screen and (min-width:480px) and (max-width:767px) {
  form {
    .label {
      text-align: left;
    }
  }

  .app-signin,
  .signup-container {
    height: calc(100vh - 30px) !important;
    overflow-y: auto;

    .banner-img {
      display: none;
    }
  }

  .forgot-password {
    .column {
      text-align: center;
    }

    .banner-img {
      display: none !important;
    }

    .banner-column {
      display: none;
    }
  }

  .signup-main-container {
    .banner-column {
      display: none;
    }

    .banner-img {
      display: none;
    }

    .abs-logo {
      max-width: 30%;
    }

    .login-form {
      // height: 500px;
      // overflow-y: auto;
    }

    .otp-verification-container {
      .banner-img {
        display: none;
      }
    }
  }

  .chat-message-container {

    .control-edit-icon {
      padding: 17px;
      display: fix;
      position: fixed;
      background: white;
      width: 100%;
      display: grid;
      z-index: 1;
      left: 0;
      margin: 10px
    }

    .input {
      margin: 7px 0px;
    }

  }

  footer {
    .columns {
      display: flex !important;
    }
  }

  .signin-container,
  .signup-container {
    padding: 0px !important;

    .columns:first-child {
      // display: none;
    }
  }

  .edit-text-box .input {
    width: 50%;
  }

  .comment-area-box {
    width: auto !important;
  }

  .chat-mesg-container {
    .control-edit-icon {
      padding: 17px;
      display: fix;
      position: fixed;
      background: white;
      width: 100%;
      display: grid;
      z-index: 1;
      left: 0;
      margin: 10px
    }

    .input {
      margin: 7px 0px;
    }
  }

  
}

@media screen and (min-width:768px) and (max-width:1024px) {

  #chat-content-wrapper.left-tab-show {
    width: 50%;
  }

  app-single-chat,
  app-notification-details {
    header {
      .edit-text-box {
        position: absolute;
        background: #fff;
        z-index: 1;
        width: 100%;
        height:100px;
        left: 0px;
        top: 0px;
        padding: 20px;
        border-bottom: 1px solid #666;
        .input {
          height: 2.2rem !important;
        }

        .options{
          margin-top:10px;
        }
      }

      
    }

  }

}

@media screen and (min-width:768px) {

  .chats .msg-list-view .chat-file-type .chat-msg-image {
    max-width: 350px;
  }

  .mobileViewBtn {
    display: none !important;
  }

  .second-level-menu {
    .list-wrapper li .is-one-quarter {
      width: 20%;
    }
  }

  .edit-text-box .input{
    margin-right:10px;
  }

  
}

@media screen and (max-width: 768px) {
  .chat-message-container {

    .control-edit-icon {
      padding: 17px;
      display: fix;
      position: fixed;
      background: white;
      width: 100%;
      display: grid;
      z-index: 1;
      left: 0;
      margin: 10px
    }

    .input {
      margin: 7px 0px;
    }

  }

  .app-signin,
  .signup-container {
    height: calc(100vh - 30px) !important;
    overflow-y: auto;

    .banner-img {
      width: 450px;
    }

    .column:first-child {
      text-align: center;
    }

    form {
      .label {
        text-align: left;
      }
    }
  }

  .forgot-password {
    .column {
      text-align: center;
    }

    .banner-img {
      width: 450px;
    }
  }

  .signup-main-container {
    .banner-img {
      display: none;
    }

    .abs-logo {
      max-width: 40%;
    }
  }

  .card{
    max-width:760px;
  }

}

.signin-container {
  max-height: calc(100vh - 30px);
  overflow-y: auto;
}

.app-signin {
  background-color: var(--whiteColor) !important;
  height: 100vh;
  // position: absolute;
  width: 100%;
  // top: 0px;
  display: inline-flex;
  overflow-y: auto;

  .card {
    box-shadow: 0 0 50px 0 rgba(232.5,232.5,232.5,.5) !important;
   // border-left: 1px solid #ddd;
    padding: 30px !important;
    // display: flex;
    // align-items: center;
  }

  .login-form {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;

    form {
      width: 100%;
    }
  }

  input {
    box-shadow: none !important;
    border: 1px solid #c2c2c2 !important;
  }

  .brand {
    position: absolute;
    text-align: left !important;
  }
}

// app-settings,app-topic-details,app-user-detail,app-new-group{
//   input,textarea,select{
//     box-shadow: none !important;
//     border: 1px solid #363636 !important;
//   }
// }

// input,textarea,select{
//   box-shadow: none !important;
//   border: 1px solid #959595 !important;
// }

app-menu-options {
  .flex-grid.fi-p {
    flex: 0 0 200px !important;
    max-width: 200px !important;
  }
}
.collapse-menu .menu{
  padding-right:2px;
}



app-user {

  table a {
    color: var(--primaryColor) !important;
  }

  .flex-container {
    justify-content: space-around;
  }

  .flex-item {
    width: 255px;
    margin: 15px;
  }

  .card-image {
    text-align: center;
    padding-top: 10px;
    display: flex;
    justify-content: center;

    img {
      width: 48px;
      height: 48px;
    }
  }

  header {
    .is-pulled-left {
      margin-right: 10px;
    }
  }


}

.empty-state {
  .fa-users {
    font-size: 6em !important;
    color: var(--primaryColor) !important;
  }

  .fa-book {
    font-size: 10em !important;
    color: var(--primaryColor) !important;
  }
}

a.panel-block:hover,
label.panel-block:hover {
  background-color: var(--secondaryHoverbg);
}

.card-footer {
  .button.is-link {
    background-color: var(--primaryColor) !important;
  }
}




@media screen and (max-width:480px) {
  

  app-single-chat,
  app-notification-details {
    header {
      .edit-text-box {
        position: absolute;
        background: #fff;
        z-index: 1;
        width: 100%;
        left: 0px;
        top: 0px;
        padding: 20px;

        .input {
          height: 2.2rem !important;
        }
      }

      .edit-text-box .options {
        margin-top: 10px;
      }
    }

  }

  app-sub-post {
    .edit-text-box {
      .input {
        height: 2.2rem !important;

      }
    }

    .edit-text-box .text-input {
      width: 90% !important;
    }

    .edit-text-box .options {
      margin-top: 10px;
    }
  }

  app-single-chat {
    .chats .msg-list-view {
      margin: 0px 10px;

      .chats .timing small {
        font-size: .8rem !important;
      }
    }
  }
  .app-signin .brand{
    z-index:99;
  }
}

$color1: #f4f4f4;
$color2: #3197EE;
.signin-container{
.radio {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: $color1;
        border-radius: 100%;
        border: 1px solid darken($color1, 25%);
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: 1em; 
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

}

:root {
  --primaryColor: #1571b9;
  --primarylightColor: #d2daf3;
  --menuSelectColor: #eceffe;
  --bodyBgColor: #f1f1f1;
  --whiteColor: #ffffff;
  --secondaryHoverbg: #f5f5f5;
  --popupwindowheadbg:#2e77ae;
  --textColor:#010101;
  --table-hover-bg:#f4f4f4;
  --bs-emphasis-color:#f4f4f4;
  --bs-border-width: 0px;
}

$color-0: #0f60a7;
$color-1: #2ecc71;
$color-2: #3498db;
$color-3: #f1c40f;
$color-4: #e74c3c;
$color-5: #000000;

.custom-radios {

  div {
    display: inline-block;
  }

  input[type="radio"] {
    display: none;

    +label {
      color: #333;
      font-family: Arial, sans-serif;
      font-size: 14px;

      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: -1px 4px 0 0;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid var(--whiteColor);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        line-height: 44px;

        img {
          opacity: 0;
          transition: all .3s ease;
        }
      }
    }

    &#color-0+label span {
      background-color: $color-0;
    }

    &#color-1+label span {
      background-color: $color-1;
    }

    &#color-2+label span {
      background-color: $color-2;
    }

    &#color-3+label span {
      background-color: $color-3;
    }

    &#color-4+label span {
      background-color: $color-4;
    }

    &#color-5+label span {
      background-color: $color-5;
    }

    &:checked+label span img {
      opacity: 1;
    }
  }
}



.button.is-link:not(.is-outlined) {
  background-color: var(--primaryColor) !important;
  .fa,.fa-solid{
    color:#fff !important;
  }
}

app-forgot {
  #forgot-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 15%;
  }
}

.navbar {
  .navbar-burger {
    display: none;
  }
}

app-group-userlist{
  .list-wrapper .field{
    display: flex;
    width: 100%;
  justify-content: space-between;
  }
}

@media screen and (max-width:640px) {
  .navbar {
    .navbar-brand{
      align-items: center;
      width:60px;
    }
    .navbar-burger {
      color: #fff !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      display: block;
    }
  }

  

  .media-content {
    .dropdown-menu {
      right: 0px !important;
      left: auto !important;
    }
  }


  .notification-content-wrapper .contents .box {
    padding: .9rem;
  }

  app-emojimart {
    .dropdown-menu {
      right: -20px !important;
    }
  }
 
 .list-wrapper li .is-one-quarter {
       width:20%;
  }
  

  .preview-layout {
    
    .field{
      display: flex;
      justify-content: center;
      .is-checkradio[type="radio"]+label::before {
        top:0px;
      }
    
      .is-checkradio[type="radio"]+label::after {
        top:0px;
      }
    }
  }

  

}

@media screen and (max-width:767px) {

  .app-signin .card {
    margin-top: 50px !important;
  }

  .footer-mobile {
    display: block;
  }

  .sign-footer {
    background: #00d1b2 !important;
    box-shadow: none !important;
    border-radius: 0 0 15px 15px !important;
    padding: 0px !important;
  }

  .footer-wrap {
    margin-top: 20px;
    width: 100%;
  }

  .brand {
    background: #eaf1ef;
  }

  app-new-notification {
    .dropdown-menu {
      right: 0px !important;
      left: auto !important;
    }
  }

  footer.chats .is-grouped{
    margin:10px 24px 8px 24px;
    .control.has-icons-left .icon.first-icon{
      width:15px;
    }
    .input.multiple{
      padding:5px 40px;
    }
  }

}

.footer-mobile {
  display: none;
}

.videoQuizTileView {

  .item-title {
    .button {
      height: 20px !important;
      font-weight: normal !important;
    }
  }

  .history-tl-container {
    label.checkbox {
      font-weight: normal !important;
      color: #4a4a4a !important;
    }
  }


}